<template>
  <div
    class="modal fade"
    :id="$props.id"
    :data-bs-backdrop="canCancelable ? 'true' : 'static'"
    :data-bs-keyboard="canCancelable"
    tabindex="-1"
    ref="modalElemtent"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- #header -->
        <div class="modal-header py-2" v-if="$props.title">
          <h1 class="modal-title fs-5">{{ $props.title }}</h1>
        </div><!-- /.modal-header -->

        <!-- #body -->
        <div class="modal-body">
          <slot>
            <p class="m-0">{{ $props.content }}</p>
          </slot>
        </div><!-- /.modal-body -->

        <!-- #footer-->
        <div class="modal-footer py-1">
          <button
            v-if="buttonOptions.showPositive"
            type="button"
            class="btn btn-sm"
            v-bind:class="[isDangerousOperation ? 'btn-danger' : 'btn-primary']"
            @click="onPositiveButtonClicked"
          >
            {{ buttonOptions.positiveString }}
          </button>
          <button
            v-if="buttonOptions.showNegative"
            type="button"
            class="btn btn-sm btn-secondary"
            @click="onNegativeButtonClicked"
          >
            {{ buttonOptions.negativeString }}
          </button>
          <button
            v-if="buttonOptions.showCancel"
            type="button"
            class="btn btn-sm btn-simple"
            @click="onCancelButtonClicked"
          >
            {{ buttonOptions.cancelString }}
          </button>
        </div><!-- /.modal-footer -->

      </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
  </div><!-- /.modal -->
</template>

<style scoped>
.modal-body p {
  white-space: pre-line;
}
</style>

<script>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";

export default {
  props: {
    /* ID */
    id : {
      type: String,
      default: "MultiDialog"
    },
    /* タイトル */
    title: {
      type: String,
      default: "",
    },
    /* 本文（オプション） */
    content: {
      type: String,
      default: "",
      required: false,
    },
    /* 表示するボタンの種類 */
    buttons: {
      type: String,
      default: "OK",
    },
    /* 実行する操作が危険か否か（削除等） */
    isDangerousOperation: {
      type: Boolean,
      default: false,
    },
    /* 肯定的なボタンに表示する文章 */
    positiveString: {
      type: String,
      default: "はい",
    },
    /* 否定的なボタンに表示する文章 */
    negativeString: {
      type: String,
      default: "いいえ",
    },
    /* キャンセルボタンに表示する文章 */
    cancelString: {
      type: String,
      default: "キャンセル",
    },
    /* ダイアログ外クリックで閉じられるダイアログか否か */
    canCancelable: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "created",  // ダイアログ生成時（ダイアログオブジェクトインスタンスを返す）
    "showed",   // ダイアログが表示されたとき（shown.bs.modalイベントを返す）
    "yes",      // 肯定的なボタンが押下されたとき（クリックイベントを返す）
    "no",       // 否定的なボタンが押下されたとき（クリックイベントを返す）
    "cancel",   // キャンセルボタンまたはダイアログ外が押下されたとき（クリックイベントを返す）
    "closed"    // ダイアログが閉じられたとき（hidden.bs.modalイベントを返す）
  ],
  setup(props, context) {
    /** モーダル要素 */
    const modalElemtent = ref();

    /** モーダルオブジェクトインスタンス */
    const modalObject = ref();

    /** Promiseオブジェクトインスタンス */
    let resolver = null; // 再代入を許可するためlet

    /** ボタンオプション */
    const buttonOptions = computed(() => {

      const options = {
        showPositive: false,
        showNegative: false,
        showCancel: false,
        positiveString: props.positiveString,
        negativeString: props.negativeString,
        cancelString: props.cancelString,
      }

      // 表示するボタンを制御
      switch (props.buttons) {
        case "YesNo":
          options.showPositive = true;
          options.showNegative = true;
          break;
        case "YesNoCancel":
          options.showPositive = true;
          options.showNegative = true;
          options.showCancel = true;
          break;
        case "OKCancel":
          options.showPositive = true;
          options.showCancel = true;
          options.positiveString = options.positiveString || "OK";
          break;
        default:
          // OK only
          options.showPositive = true;
          options.positiveString = options.positiveString || "OK";
          break;
      }

      return options;
    });

    /** 肯定的ボタンをクリックした際の処理 */
    const onPositiveButtonClicked = (event) => {
      if (resolver != null) {
        // 肯定的ボタンが選択されたことをPromiseオブジェクトに通知する
        resolver(1);
      }

      // 結果を親コンポーネントに送る
      context.emit("yes", event);

      // ダイアログを閉じる
      close();
    }

    /** 否定的ボタンをクリックした際の処理 */
    const onNegativeButtonClicked = (event) => {
      if (resolver != null) {
        // 否定的ボタンが選択されたことをPromiseオブジェクトに通知する
        resolver(0);
      }

      // 結果を親コンポーネントに送る
      context.emit("no", event);

      // ダイアログを閉じる
      close();
    }

    /** キャンセルボタンをクリックした際の処理 */
    const onCancelButtonClicked = (event) => {
      if (resolver != null) {
        // キャンセルボタンが選択されたことをPromiseオブジェクトに通知する
        resolver(-1);
      }

      // 結果を親コンポーネントに送る
      context.emit("cancel", event);

      // ダイアログを閉じる
      close();
    }

    /** ボタン選択結果を取得する処理 */
    const getResult = async (withShowingDialog = false) => {
      if (withShowingDialog) {
        show();
      }

      const promise = new Promise(resolve => {
        resolver = resolve;
      });

      return await promise;
    }

    /** ダイアログを表示する処理 */
    const show = () => {
      modalObject.value.show();
    }

    /** ダイアログを閉じる処理 */
    const close = () => {
      // Promiseを破棄する
      resolver = null;

      // ダイアログを閉じる
      modalObject.value.hide();
    }

    /** ダイアログが表示された際に実行する処理 */
    const shownEventAction = (event) => {
      context.emit("showed", event);
    };

    /** ダイアログが閉じられた際に実行する処理 */
    const hiddenEventAction = (event) => {
      context.emit("closed", event);

      // ダイアログが閉じられた時点でPromiseが待機中の場合
      if (resolver != null) {
        // Promiseオブジェクトにキャンセルを通知する
        resolver(-1);

        // Promiseを破棄する
        resolver = null;
      }
    }

    /** マウント時 */
    onMounted(() => {
      // モーダルオブジェクトインスタンを生成する
      modalObject.value = new Modal(modalElemtent.value);

      // モーダルオブジェクトが生成されたことを親コンポーネントに通知する
      context.emit("created", modalObject.value);

      // 表示・非表示イベントをセットする
      modalElemtent.value.addEventListener("shown.bs.modal", shownEventAction);
      modalElemtent.value.addEventListener("hidden.bs.modal", hiddenEventAction);
    });

    /** アンマウント時 */
    onBeforeUnmount(() => {
      // 表示・非表示イベントを解除する
      modalElemtent.value.removeEventListener("shown.bs.modal", shownEventAction);
      modalElemtent.value.removeEventListener("hidden.bs.modal", hiddenEventAction);
    });

    return {
      modalElemtent,
      buttonOptions,
      onPositiveButtonClicked,
      onNegativeButtonClicked,
      onCancelButtonClicked,
      getResult,
      show,
      close,
    };
  },
};
</script>