<template>
  <!-- デバッグ情報 -->
  <div class="environment" :class="environment" v-if="environment !== 'production'" @click="showDebugInfo = !showDebugInfo">
    {{ environment.toUpperCase() }}
  </div>
  <textarea v-if="showDebugInfo" class="debuginfo" v-model="debugInfo" rows="10"></textarea>

  <!-- ヘッダー -->
  <GlobalHeader :environment="environment" />

  <!-- お知らせ -->
  <Notification />

  <div class="d-flex flex-row justify-content-center">
    <!-- 保管機能案内バナー（受領請求書一覧画面かつ保管機能未契約時のみ表示） -->
    <div v-if="$route.name === 'InvoiceMain' && $store.getters.getDefaultTenant && !$store.getters.getDefaultTenant?.isStorable" class="notice-container">
      <div v-if="$store.getters.getDefaultTenant" class="notice-banners">
        <p>PR</p>
        <a id="campaign-banner" :href="campaignSiteUrl" target="_blank">
          <img src="https://www.mbcnet.co.jp/images/banner_1.png" alt="hayabusaBanD帳票保管機能のお知らせ">
          <img src="https://www.mbcnet.co.jp/images/banner_2.png" alt="hayabusaBanD帳票保管機能のお知らせ">
          <div class="notice-hovered border rounded shadow">
            <p>帳票保管利用例</p>
            <img src="https://www.mbcnet.co.jp/band/hybs_sample.png" alt="hayabusaBanD帳票保管機能の利用例">
            <small class="text-body">初期導入費用及びオプション費用は別途ご案内させて頂きます。</small>
          </div>
        </a>
      </div>
    </div>

    <!-- メインコンテンツ -->
    <router-view :isShowBanner="$route.name === 'InvoiceMain' && $store.getters.getDefaultTenant && !$store.getters.getDefaultTenant?.isStorable" />
  </div>

  <!-- Cookie同意確認 -->
  <div id="cookieConsent" v-if="(environment === 'production' || environment === 'demo') && cookieConsentVisibility">
    <p>
      このウェブサイトでは、Cookie（クッキー）を使用しています。
      Cookieにはウェブサイトの動作に必要不可欠なものと、ユーザーの利便性向上を目的とした任意のものがあります。
      任意のCookieの使用にご同意いただける場合は「同意する」ボタンを押下してください。
      Cookieに関する詳細は、<router-link to="/tos">利用規約</router-link>をご覧ください。
    </p>
    <button type="button" class="btn btn-primary" @click="cookieConsent(true)">同意する</button>
    <button type="button" class="btn btn-link" @click="cookieConsent(false)">同意しない</button>
  </div>

  <!-- フッター -->
  <GlobalFooter :environment="environment" />
</template>

<style>
html, body {
  height: 100%;
}
#app {
  width: 100%;
  padding-bottom: 86px;
  position: relative;
  min-height: 100%;
  text-spacing-trim: space-all;
}

/** 環境判別 */
div.environment {
  width: 300px;
  padding: 6px 14px;
  color: var(--text-light);
  font-size: 16px;
  text-align: center;
  left: 50%;
  top: 0;
  border-radius: 0 0 4px 4px;
  display: none;
  position: fixed;
  z-index: var(--zindex-ultra-high);
  transform: translate(-50%, 0);
}
  div.environment.staging {
    background-color: orange;
    display: block;
  }
  div.environment.development {
    background-color: var(--color-green);
    display: block;
  }

textarea.debuginfo {
  width: 40vw;
  top: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  z-index: var(--zindex-ultra-high);
}

/** 保管機能案内バナー */
@media (max-width: 1100px) {
  .notice-container {
    display: none;
  }
}
.notice-container {
  margin-left: 10px;
  width: 100%;
  max-width: 250px;
  position: relative;

  & .notice-banners > p {
    margin: 0;
    padding: 0 4px;
    font-size: .75rem;
    color: var(--text-light); 
    top: min(calc(20vh - 1.1rem), calc(230px - 1.15rem));
    position: absolute;
    border-radius: .25rem .25rem 0 0;
    background-color: var(--theme-primary);
    user-select: none;
  }

  & .notice-banners > a > img {
    width: 100%;
    top: min(20vh, 230px);
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    opacity: 0;
    animation: dissolve 20s infinite;
  }
  & .notice-banners > a > img:nth-of-type(1) {
    animation-delay: 0s;
  }
  & .notice-banners > a > img:nth-of-type(2) {
    animation-delay: 10s;
  }

  & .notice-banners .notice-hovered {
    padding: 6px 14px;
    width: 1000px;
    max-width: calc(90vw - 200px);
    position: absolute;
    top: min(20vh, 230px);
    left: 100%;
    pointer-events: none;
    background-color: var(--background-secondary);
    opacity: 0;
    transition: opacity var(--anim-speed) 0s;
    z-index: var(--zindex-ultra-high);

    & > p {
      margin: 0;
      text-align: center;
      color: var(--text-secondary);
      font-size: 14pt;
      font-weight: bold;
      letter-spacing: .45rem;
    }

    & > img {
      width: 100%;
    }
  }

  & .notice-banners:hover .notice-hovered:not(:hover) {
    pointer-events: initial;
    opacity: 1;
    transition: opacity var(--anim-speed) 0.8s;
  }
}
@keyframes dissolve {
  0%{ opacity: 0;}
  5%{ opacity: 1;}
  50%{ opacity: 1;}
  55%{ opacity: 0;}
  95%{ opacity: 0;}
  100%{ opacity: 0;}
}

/** Cookie Consent */
#cookieConsent {
  padding: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: #000000cc;
  display: flex;
  position: fixed;
  z-index: var(--zindex-ultra-high);
  transition: var(--anim-speed);
}
  #cookieConsent:hover {
    background-color: #000000;
    transition: var(--anim-speed);
  }
#cookieConsent p {
  margin: auto 10px;
}
#cookieConsent a,
#cookieConsent a:hover,
#cookieConsent a:active,
#cookieConsent a:visited {
  color: var(--text-light);
}
#cookieConsent button {
  margin: auto 4px;
  height: fit-content;
  color: var(--text-light);
  white-space: nowrap;
}

/** reCAPPTCHA */
.grecaptcha-badge {
  bottom: 86px !important;
}

footer {
  width: 100%;
  height: 86px;
  position: absolute;
  bottom: 0;
}
</style>

<script>
import { computed, defineComponent, onMounted, onUpdated, ref } from "vue";
import { useGtm } from "@gtm-support/vue-gtm";
import { useStore } from "vuex";
import { ScrollSpy } from 'bootstrap/dist/js/bootstrap.min.js'
import GlobalHeader from "@/components/GlobalHeader.vue";
import GlobalFooter from "@/components/GlobalFooter.vue";
import Notification from "@/components/Notification.vue";

export default defineComponent({
  components: {
    GlobalHeader,
    GlobalFooter,
    Notification,
  },
  setup() {

    /** vuex::store */
    const store = useStore();

    /** 環境判定 */
    const environment = process.env.VUE_APP_ENV_NAME;

    /** デバッグ情報表示フラグ */
    const showDebugInfo = ref(false);

    /** デバッグ情報内容 */
    const debugInfo = computed(() => {
      return `[getOwnInfo]\n${JSON.stringify(store.getters.getOwnInfo, null, 2)}\n\n` +
             `[getOwnPermissions]\n${JSON.stringify(store.getters.getOwnPermissions, null, 2)}\n\n` +
             `[getAssignedCustomers]\n${JSON.stringify(store.getters.getAssignedCustomers, null, 2)}\n\n` +
             `[getIsHQ]\n${JSON.stringify(store.getters.getIsHQ, null, 2)}\n\n` +
             `[getSystemDate]\n${JSON.stringify(store.getters.getSystemDate, null, 2)}`;
    });

    /** キャンペーンサイトURL */
    const campaignSiteUrl = computed(() => {
      return `https://www.mbcnet.co.jp/band/hayabusa.html` +
             `?utm_source=${window.location.hostname}` +
             `&utm_medium=banner` +
             `&utm_campaign=evidence_storage_promo` +
             `&utm_content=promo_banner`;
    });

    /** Google Tag Manager */
    const gtm = useGtm();

    /** Cookie同意状況 */
    const cookieConsentState = ref(
      window.localStorage.getItem("cookieConsentState") === 'true'
    );

    // 同意済みの場合はGTM有効可
    if (cookieConsentState.value) {
      gtm.enable(true);
    }

    /** Cookie同意確認表示有無 */
    const cookieConsentVisibility = ref(
      window.localStorage.getItem("cookieConsentState") === null
    );

    /** Cookie同意確認結果処理 */
    const cookieConsent = bool => {
      // 同意結果を反映
      cookieConsentState.value = bool;
      window.localStorage.setItem("cookieConsentState", bool);

      // 次回以降、同意確認を表示しない
      cookieConsentVisibility.value = false;

      // 本番環境かつ同意した場合のみGTM有効化
      if ((environment === "production" || environment === 'demo') && bool) {
        gtm.enable(true);
      }
    }

    /** BootstrapのScrollSpyを初期化する処理 */
    const initializeScrollSpy = () => {
      new ScrollSpy(document.body, {
        target: '#navbar'
      });
    }

    /** DOMが描画された後に実行する処理 */
    onMounted(() => {
      // BootstrapのScrollSpyを初期化する
      initializeScrollSpy();
    })

    /** DOMが更新（再描画）された際に実行する処理 */
    onUpdated(() => {
      // BootstrapのScrollSpyを初期化する
      initializeScrollSpy();
    })

    return {
      environment,              // 環境判定
      showDebugInfo,            // デバッグ情報表示フラグ
      debugInfo,                // デバッグ情報内容
      campaignSiteUrl,          // キャンペーンサイトURL
      cookieConsentState,       // Cookie同意状況
      cookieConsentVisibility,  // Cookie同意確認表示有無
      cookieConsent,            // Cookie同意確認結果処理
    }
  },
});
</script>

