import { computed, reactive, watch } from 'vue';
import { useReCaptcha } from 'vue-recaptcha-v3';
import backend from '@/services/backendApi';

/**
 *ユーザーマスター関連
 */
const useUser = () => {
    // reCAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const user = reactive({
        data: [],               // ユーザーデータ配列(生データ)
        list: [],               // 検索処理用ユーザーデータ配列
        maxUsers: 0,            // 契約ユーザー数の上限
        length: computed(() => user.list.length),
        status: {
            get: {                          // 取得状況
                loaded: false,              // 取得済か否か
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            add: {                          // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            update: {                       // 更新状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            delete: {                       // 削除状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            lock: {                         // ロックアウト状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            reset: {                        // パスワードリセット状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            endService: {                   // サービスの利用終了状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            }
        }
    });

    /** 新規追加用オブジェクト */
    const userObject = reactive({
        accountId: "",              // メールアドレス
        name: "",                   // 氏名（漢字）
        nameKana: "",               // 氏名（カナ）
        displayName: "",            // 表示名
        mailAddress: "",            // 連絡先メールアドレス
        tenantCode: "",             // テナントコード
        notification: 0,            // 通知オプション
        startupPage: 0,             // スタートアップページ
        lockedOut: 0,               // アカウントロックフラグ
        passwordReset: 0,           // パスワード再設定フラグ
        fileSizeThreshold: 0,       // アップロードファイルサイズ閾値
        lastAccess: "",             // 最終サインイン日時
        startDateTime: "",          // 公開開始時刻 10：00をセット
        endDateTime: "",            // 公開終了時刻 10：00をセット
    });

    /** 検索条件 */
    const searchCondition = reactive({
        accountId: "",              // メールアドレス
        name: "",                   // 氏名（漢字）
        nameKana: "",               // 氏名（カナ）
        mailAddress: "",            // 連絡先メールアドレス
        tenantCode: "",             // テナントコード
        tenantName: "",             // テナント名
        notification: "",           // 通知オプション
        lockedOut: "",              // アカウントロックフラグ
        passwordReset: "",          // パスワード再設定フラグ
        lastAccess: "",             // 最終サインイン日時
        startDateTime: "",          // 公開開始時刻 10：00をセット
        endDateTime: "",            // 公開終了時刻 10：00をセット
    });

    // 検索条件の変更を監視し、検索を実行させる
    watch(searchCondition, () => searchUserData());


    /**ユーザーデータをテナントコードの昇順にソートする処理 */
    const sortByTennantCode = (a, b) => {
        if(a.tenantCode > b.tenantCode) return 1;
        if(a.tenantCode < b.tenantCode) return -1;
        return 0;
    }

    /**
     * ユーザーIDを条件にしてユーザーを取得します。
     * @param {String} accountId ユーザーID
     * @param {Boolean} excludesDeleted 削除レコード除外有無（初期値：True）
     * @returns
     */
    const getUserById = async (accountId = "", excludesDeleted = true) => {
        return getUser(accountId, null, null, excludesDeleted);
    }

    /**
     * テナントコードと得意先コードを条件にしてユーザーを取得します。
     * @param {String} tenantCode テナントコード
     * @param {String} customerCode 得意先コード
     * @param {Boolean} excludesDeleted 削除レコード除外有無（初期値：True）
     * @returns
     */
    const getUserByCode = async (tenantCode = "", customerCode = "", excludesDeleted = true) => {
        if (tenantCode != "" && customerCode == "" || tenantCode == "" && customerCode != "") {
            throw TypeError("テナントコードまたはお客様番号を指定した場合は、いずれかを無指定にすることはできません。");
        }

        return getUser(null, tenantCode, customerCode, excludesDeleted);
    }

    /**
     * （直接参照禁止）ユーザーマスター取得
     * @private  getUserByIdまたはgetUserByCodeから参照してください。
     * @param {String} accountId ユーザーID
     * @param {String} tenantCode テナントコード
     * @param {String} customerCode 得意先コード
     * @param {Boolean} excludesDeleted 削除レコード除外有無（初期値：True）
     * @returns
     */
    const getUser = async (accountId, tenantCode, customerCode, excludesDeleted = true) => {
        // メッセージ初期化、処理中フラグON
        user.status.get.message = "";
        user.status.get.inprogress = true;

        // データクリア
        clearUserList();

        return await backend.getUser(accountId, tenantCode, customerCode, excludesDeleted)
            .then(response => {
                //ユーザーデータがある場合はユーザーデータ配列にセット
                if (response.status !== 204) {
                    const result = response.data;
                    // テナントコードの昇順にソート
                    user.data = result.contents.slice().sort(sortByTennantCode);
                    // 契約ユーザー数をセット
                    user.maxUsers = result.maxUsers;

                    // 取得成功
                    user.status.get.success = true;
                }
            }).catch(error => {
                console.error(error);
                // 取得失敗
                user.status.get.success = false;
                user.status.get.message = error.message;
            }).finally(() => {
                // 読み込み済みに変更
                user.status.get.loaded = true;
                // 処理中フラグOFF
                user.status.get.inprogress = false;

                // 検索処理実施
                searchUserData();
            });
    }

    /**
     * 新規ユーザーマスター追加処理
     * @param {Object} userObjectユーザーマスターオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const addUser = async (userObject) => {
        // メッセージ初期化・処理フラグON
        user.status.add.message = "";
        user.status.add.inprogress = true;
        // 固定値データセット（サインアップ時と同様にする）
        userObject.notification = 1;                    // 通知OPTメール固定
        userObject.mailAddress = userObject.accountId;  // 連絡先メール＝アカウントID

        return await backend.addUser(userObject)
            .then(response => {
                // 処理成功
                user.status.add.success = true;
                user.status.add.message = response.data.message;
                // 新しいパスワードを返却
                return Promise.resolve(response.data.content);
            }).catch(error => {
                console.error(error);
                // 処理失敗
                user.status.add.success = false;
                user.status.add.message = error.message;
            }).finally(() => {
                // 処理終了
                user.status.add.inprogress = false;
            });
    }

    /**
     * ユーザーマスター更新処理
     * @param {Object} userObject ユーザーマスターオブジェクト
     * @param {Boolean} maintenanceMode マスター保守の場合True,それ以外はFalse
     * @returns {Promise} Promiseオブジェクト
     */
    const updateUser = async (userObject, maintenanceMode = false) => {
        // メッセージ初期化・処理フラグON
        user.status.update.message = "";
        user.status.update.inprogress = true;

        return await backend.updateUser(userObject, maintenanceMode)
            .then(response => {
                // 処理成功
                user.status.update.success = true;
                user.status.update.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                user.status.update.success = false;
                user.status.update.message = error.message;
            }).finally(() => {
                // 処理終了
                user.status.update.inprogress = false;
            });
    }

    /**
     * ユーザーマスター削除処理
     * @param {String} accountId アカウントID
     * @param {Boolean} isErase 削除モード（true：物理削除、false（デフォルト）：論理削除）
     * @returns {Promise} Promiseオブジェクト
     */
    const deleteUser = async (accountId, isErase = false) => {
        // メッセージ初期化・処理フラグON
        user.status.delete.message = "";
        user.status.delete.inprogress = true;

        return await backend.deleteUser(accountId, isErase)
            .then(response => {
                // 処理成功
                user.status.delete.success = true;
                user.status.delete.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                user.status.delete.success = false;
                user.status.delete.message = error.message;
            }).finally(() => {
                // 処理終了
                user.status.delete.inprogress = false;
            });
    };

    /**
     * ユーザーアカウントロック処理
     * @param {String} accountId アカウントID
     * @param {Number} lockedOut ロックアウトフラグ（0:非ロック、9:強制ロック）
     * @returns {Promise} Promiseオブジェクト
     */
    const lockedOutUser = async (accountId, lockedOut) => {
        // メッセージ初期化・処理フラグON
        user.status.lock.message = "";
        user.status.lock.inprogress = true;

        return await backend.lockedOutUser(accountId, lockedOut)
            .then(response => {
                // 処理成功
                user.status.lock.success = true;
                user.status.lock.message = response.data.message;
            }).catch(error => {
                console.error(error);
                // 処理失敗
                user.status.lock.success = false;
                user.status.lock.message = error.message;
            }).finally(() => {
                // 処理終了
                user.status.lock.inprogress = false;
            });
    };

    /**
     * ユーザーパスワードリセット処理
     * @param {String} accountId アカウントID
     * @returns {Promise} Promiseオブジェクト
     */
    const resetPassword = async (accountId) => {
        // メッセージ初期化・処理フラグON
        user.status.reset.message = "";
        user.status.reset.inprogress = true;

        return await backend.resetPassword(accountId)
            .then(response => {
                // 処理成功
                user.status.reset.success = true;
                user.status.reset.message = response.data.message;
                // 新しいパスワードを返却
                return Promise.resolve(response.data.content);
            }).catch(error => {
                console.error(error);
                // 処理失敗
                user.status.reset.success = false;
                user.status.reset.message = error.message;
            }).finally(() => {
                // 処理終了
                user.status.reset.inprogress = false;
            });
    };

    /**
     * 連絡先メールアドレス変更要求処理
     * @param {String} mailAddress メールアドレス
     * @returns {Promise} Promise型オブジェクト
     */
    const changeMailAddressRequest = async (mailAddress) => {
        return backend.changeMailAddressRequest(mailAddress)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /**
     * 連絡先メールアドレス変更処理
     * @param {String} accountId アカウントID
     * @param {String} hash ハッシュ値
     * @returns {Promise} Promise型オブジェクト
     */
    const changeMailAddress = async (accountId, hash) => {
        // reCAPTCHAトークン取得
        await recaptchaLoaded();
        const recaptchaToken = await executeRecaptcha('verify_email');

        return backend.changeMailAddress(accountId, hash, recaptchaToken)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            })
    }

    /**
     * ユーザー権限更新処理
     * @param {String} tenantCode テナントコード
     * @param {String} customerCode 得意先コード
     * @param {String} accountId アカウントID
     * @param {Array<Number>} roles 設定する権限IDの配列
     * @param {Boolean} grantsTenantAdmin テナント管理者（デフォルト：false）
     * @returns {Promise} Promise型オブジェクト
     */
    const updateUserRole = async (tenantCode, customerCode, accountId, roles, grantsTenantAdmin) => {
        return backend.updateUserRole(tenantCode, customerCode, accountId, roles, grantsTenantAdmin)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /**
     * テナント所属ユーザー一覧取得処理
     * @param {String} tenantCode テナントコード
     * @param {String} otpSource OTP発行元ページID
     * @param {String} otpCode OTPコード(セキュリティコード)
     * @returns {Promise} Promise型オブジェクト
     */
    const listMyUsers = async (tenantCode, otpSource, otpCode) => {
        return backend.listMyUsers(tenantCode, otpSource, otpCode)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /**
     * テナント管理者設定状況取得処理
     * @param {String} accountId アカウントID
     * @returns {Promise} Promise型オブジェクト
     */
    const getMyAdminTenant = async (accountId) => {
        return backend.getMyAdminTenant(accountId)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /***
     * 更新ボタンモード更新処理
     * @param {Number} value 設定値（0:更新して閉じる, 1:更新のみ）
     * @returns {Promise} Promise型オブジェクト
     */
    const updateUpdateButtonMode = async (value) => {
        return backend.updateUpdateButtonMode(value)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /***
     * デフォルトアップロードモード更新処理
     * @param {Number} value 設定値（0:自動判別, 1:電子取引, 2:スキャナ保存）
     * @returns {Promise} Promise型オブジェクト
     */
    const updateDefaultUploadMode = async (value) => {
        return backend.updateDefaultUploadMode(value)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /**
     * 閲覧先一覧取得処理
     * @returns {Promise} Promise型オブジェクト
     */
    const listAccessibleTenant = async () => {
        return backend.listAccessibleTenant()
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /**
     *ユーザーデータ配列クリア処理
     */
    const clearUserList = async () => {
        user.data = [];
        user.list = [];
        user.maxUsers = 0;
        user.status.get.loaded = false;
        user.status.get.success = false;
    }

    /**
     *ユーザーデータ検索処理
     */
    const searchUserData = () => {
        // 検索対象データを生データに戻す
        user.list = user.data.slice();

        // アカウントID
        if (searchCondition.accountId !== ""){
            user.list = user.list.filter(item =>
                item.accountId.toUpperCase().indexOf(searchCondition.accountId.toUpperCase()) !== -1
            );
        }

        // 氏名
        if (searchCondition.name !== ""){
            user.list = user.list.filter(item =>
                // カナを結合
                ("" + item.name + item.nameKana).indexOf(searchCondition.name) !== -1
            );
        }

        // 連絡先メールアドレス
        if (searchCondition.mailAddress !== ""){
            user.list = user.list.filter(item =>
                item.mailAddress.toUpperCase().indexOf(searchCondition.mailAddress.toUpperCase()) !== -1
            );
        }

        // テナントコード
        if (searchCondition.tenantCode !== ""){
            user.list = user.list.filter(item =>
                item.tenantCode?.indexOf(searchCondition.tenantCode) !== -1 ||
                item.tenantName?.indexOf(searchCondition.tenantCode) !== -1
            );
        }

        // 通知オプション
        if (searchCondition.notification !== "") {
            user.list = user.list.filter(item =>
                item.notification === parseInt(searchCondition.notification)
            );
        }

        // アカウントロックフラグ
        if (searchCondition.lockedOut !== "") {
            user.list = user.list.filter(item =>
                item.lockedOut === parseInt(searchCondition.lockedOut)
            );
        }

        // パスワードリセット
        if (searchCondition.passwordReset !== "") {
            user.list = user.list.filter(item =>
                item.passwordReset === Boolean(parseInt(searchCondition.passwordReset))
            );
        }
    }

    return {
        user,
        userObject,                 // ユーザーオブジェクト
        searchCondition,            // 検索条件
        getUserById,                // ユーザー取得（アカウントID指定）
        getUserByCode,              // ユーザー取得（テナントコード・得意先コード指定）
        addUser,                    // ユーザー追加処理
        updateUser,                 // ユーザー更新処理
        deleteUser,                 // ユーザー削除処理
        lockedOutUser,              // ユーザーアカウントロック処理
        resetPassword,              // ユーザーパスワードリセット処理
        changeMailAddressRequest,   // 連絡先メールアドレス変更要求処理
        changeMailAddress,          // 連絡先メールアドレス変更処理
        clearUserList,              // ユーザーデータ配列クリア処理
        updateUserRole,             // ユーザー権限更新処理
        listMyUsers,                // テナント所属ユーザー一覧取得処理
        getMyAdminTenant,           // テナント管理者設定状況取得処理
        updateUpdateButtonMode,     // 更新ボタンモード更新処理
        updateDefaultUploadMode,    // デフォルトアップロードモード更新処理
        listAccessibleTenant,       // 閲覧先一覧取得処理
    };
};

export default useUser;