import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import Signin from '@/views/Signin.vue';                            // サインイン画面
import Dashboard from '@/views/Dashboard.vue';                      // ダッシュボード画面
import Settings from '@/views/Settings.vue';                        // ユーザー設定画面

const DEFAULT_TITLE = 'hayabusa BanD - 帳票保管・閲覧クラウド';

const CHUNK_LOAD_ERROR_MSG = /Loading.*chunk.*failed/;

/*********************************************************************************
 ** webpackChunkName について
 ** ファイルを塊（チャンク）に分けるための名称です。
 ** 1つのファイルが巨大にならないよう、ある程度のカテゴリごとに分けて命名してください。
 *********************************************************************************/

const routes = [
  { // サインイン画面
    path: '/signin',
    name: 'Signin',
    component: Signin,
    meta: {
      title: 'サインイン',
      isPublic: true,
      noHeader: true,
    },
  },
  { // 新規ユーザー登録画面
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/Signup.vue'),
    meta: {
      title: '新規ご利用登録',
      isPublic: true,
    },
  },
  { // 閲覧先追加説明画面
    path: '/already-have-account',
    alias: '/add-other-tenants',
    name: 'AddOtherTenant',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/AddOtherTenants.vue'),
    meta: {
      title: '既にhayabusaBanDをご利用の場合',
      isPublic: true,
    },
  },
  { // パスワード変更要求画面
    path: '/forgot_password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/ForgotPassword.vue'),
    meta: {
      title: 'パスワードをお忘れの場合',
      isPublic: true,
    },
  },
  { // （新規登録時）パスワード再設定画面（本登録処理）
    path: '/regist/:accountId/:serviceType/:hash',
    name: 'ChangePasswordForRegist',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/ChangePassword.vue'), // パスワード忘却時と同一コンポーネント
    meta: {
      title: 'パスワード再設定',
      isPublic: true,
      reason: 'regist',
    },
  },
  { // （パスワード忘却時）パスワード再設定画面
    path: '/reset/:accountId/:hash',
    name: 'ChangePasswordForForgot',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/ChangePassword.vue'),
    meta: {
      title: 'パスワード再設定',
      isPublic: true,
      reason: 'forgot',
    },
  },
  { // （パスワード再設定要求時）パスワード再設定画面
    path: '/reset-required/:accountId',
    name: 'ChangePasswordForRequired',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/ChangePassword.vue'), // パスワード忘却時と同一コンポーネント
    meta: {
      title: 'パスワード再設定',
      isPublic: true,
      reason: 'required',
    },
  },
  { // （ユーザー認証方式サインアップ時）メールアドレス認証実施画面
    path: '/verify/:accountId/:hash',
    name: 'VerifyEmailAddress',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/VerifyEmailAddress.vue'),
    meta: {
      title: 'メールアドレス認証',
      isPublic: true,
    },
  },
  { // ダッシュボード画面
    path: '/dashboard',
    name: 'Dashboard',
    alias: '/',
    component: Dashboard,
    meta: { title: 'ダッシュボード' },
    children: [
      { // 受領請求書一覧
        path: '/invoice',
        name: 'InvoiceMain',
        component: () => import(/* webpackChunkName: "invoice" */ '@/views/invoice/InvoiceMain.vue'),
      },
      { // 保管帳票一覧
        path: '/evidence',
        name: 'EvidenceMain',
        component: () => import(/* webpackChunkName: "evidence" */ '@/views/evidence/EvidenceMain.vue'),
      },
      { // （代行入力ユーザー）保管帳票一覧
        path: '/agent',
        name: 'AgentMain',
        component: () => import(/* webpackChunkName: "agent" */ '@/views/agent/AgentMain.vue'),
      },
      { // 発行請求書一覧
        path: '/delivery',
        name: 'DeliveryMain',
        component: () => import(/* webpackChunkName: "delivery" */ '@/views/delivery/DeliveryMain.vue'),
      }
    ]
  },
  { // ユーザー設定画面
    path: '/settings',
    name: 'UserSettings',
    component: Settings,
    meta: { title: 'ユーザー設定' },
  },
  { // （サポート画面）サポートメニュー
    path: '/support/index',
    alias: '/support',
    name: 'SpMenu',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/Menu.vue'),
    meta: {
      title: 'サポートメニュー',
      isPublic: true,
    },
  },
  { // （サポート画面）Q&A
    path: '/support/qa',
    name: 'SpQandA',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/QandA.vue'),
    meta: {title: 'Q&A｜サポート'},
  },
  { // （サポート画面）よくあるご質問
    path: '/support/faq',
    name: 'SpFAQ',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/FAQ.vue'),
    meta: {
      title: 'よくあるご質問｜サポート',
      isPublic: true,
    },
  },
  { // （サポート画面）困ったときは
    path: '/support/help',
    name: 'SpHelp',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/Help.vue'),
    meta: {
      title: '困ったときは｜サポート',
      isPublic: true,
    },
  },
  { // （サポート画面）ご利用者向けマニュアル
    path: '/support/manual',
    name: 'SpManual',
    component: () => import(/* webpackChunkName: "manual" */ '@/views/support/Manual.vue'),
    meta: {
      title: 'ご利用者向けマニュアル｜サポート',
      isPublic: true,
    },
  },
  { // （サポート画面）動画マニュアル
    path: '/support/movie',
    name: 'SpMovie',
    component: () => import(/* webpackChunkName: "manual" */ '@/views/support/Movie.vue'),
    meta: {
      title: '動画マニュアル｜サポート',
      isPublic: true,
    },
  },
  { // （サポート画面）お問い合わせ
    path: '/support/contact',
    name: 'SpContact',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/Contact.vue'),
    meta: {
      title: 'お問い合わせ｜サポート',
      isPublic: true,
    },
  },
  { // （サポート画面）サポートリクエストフォーム
    path: '/support/supportrequest',
    name: 'SpSupportRequest',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/SupportRequest.vue'),
    meta: {
      title: 'サポートリクエストフォーム',
      isPublic: true,
      sendCredentials: true,
    },
  },
  { // （サポート画面）リリースノート
    path: '/support/releasenotes',
    name: 'SpReleaseNotes',
    component: () => import(/* webpackChunkName: "support" */ '@/views/support/ReleaseNotes.vue'),
    meta: {
      title: 'リリースノート',
      isPublic: true,
      sendCredentials: true,
    },
  },
  // ***
  // * ChunkName: ファイルを切り分け、1つのサイズを小さくし、読み込み負荷を軽減します。
  // * - maintenance0 : MBCのみが利用する画面
  // * - maintenance1 : ルート、インデックス、全ユーザーが使用する画面
  // * - maintenance2 : 帳票発行テナントが利用する画面
  // * - maintenance3 : 帳票保管テナントが利用する画面
  // ***
  { // （管理者）管理者メニュー画面
    path: '/maintenance',
    name: 'Maintenance',
    redirect: '/maintenance/index',
    component: () => import(/* webpackChunkName: "maintenance1" */ '@/views/maintenance/Index.vue'),
    meta: { title: '管理者メニュー' },
    children: [
      { // 管理者メニュートップ
        path: 'index',
        name: 'Menu',
        component: () => import(/* webpackChunkName: "maintenance1" */ '@/views/maintenance/Menu.vue'),
      },
      { // （管理者）ユーザーマスター保守画面
        path: 'user',
        name: 'UserMaintenance',
        component: () => import(/* webpackChunkName: "maintenance1" */ '@/views/maintenance/User.vue'),
        meta: { title: 'ユーザーマスター保守' },
      },
      { // （管理者）ユーザー権限保守画面
        path: 'role',
        name: 'RoleAccessControlMaintenance',
        component: () => import(/* webpackChunkName: "maintenance1" */ '@/views/maintenance/RoleAccessControl.vue'),
        meta: { title: 'ユーザー権限保守' },
      },
      { // （管理者）テナントマスター保守画面
        path: 'customer',
        name: 'CustomerMaintenance',
        component: () => import(/* webpackChunkName: "maintenance2" */ '@/views/maintenance/Customer.vue'),
        meta: { title: 'テナントマスター保守' },
      },
      { // （管理者）請求書発行管理画面
        path: 'issue',
        name: 'Issue',
        component: () => import(/* webpackChunkName: "maintenance2" */ '@/views/maintenance/Issue.vue'),
        meta: { title: '請求書発行管理' },
      },
      { // （管理者）サービス連携設定マスター保守画面
        path: 'external-service',
        name: 'ExternalService',
        component: () => import(/* webpackChunkName: "maintenance2" */ '@/views/maintenance/ExternalService.vue'),
        meta: { title: 'サービス連携設定マスター保守' },
      },
      { // （管理者）サインアップ承認データ保守画面
        path: 'signup-approval-data',
        name: 'SignupApprovalData',
        component: () => import(/* webpackChunkName: "maintenance2" */ '@/views/maintenance/SignupApprovalData.vue'),
        meta: { title: 'サインアップ承認データ保守' },
      },
      { // （管理者）帳票保管取引先マスター保守画面
        path: 'partner',
        name: 'PartnerMaintenance',
        component: () => import(/* webpackChunkName: "maintenance3" */ '@/views/maintenance/Partner.vue'),
        meta: { title: '取引先マスター保守' },
      },
      { // （管理者）帳票保管帳票タグマスター保守画面
        path: 'evidence-tags',
        name: 'EvidenceTagMaintenance',
        component: () => import(/* webpackChunkName: "maintenance3" */ '@/views/maintenance/EvidenceTag.vue'),
        meta: { title: '帳票タグマスター保守' },
      },
      { // （管理者）帳票保管帳票定義マスター保守画面
        path: 'evidence-forms',
        name: 'EvidenceSettingsMaintenance',
        component: () => import(/* webpackChunkName: "maintenance3" */ '@/views/maintenance/EvidenceSettings.vue'),
        meta: { title: '帳票定義マスター保守' },
      },
      { // （管理者）帳票保管帳票承認設定保守画面
        path: 'evidence-options',
        name: 'EvidenceApprovalOption',
        component: () => import(/* webpackChunkName: "maintenance3" */ '@/views/maintenance/EvidenceApprovalOption.vue'),
        meta: { title: '帳票承認設定保守' },
      },
      { // （管理者）グループマスター保守画面
        path: 'group',
        name: 'Group',
        component: () => import(/* webpackChunkName: "maintenance3" */ '@/views/maintenance/Group.vue'),
        meta: { title: 'グループマスター保守' },
      },
      { // （管理者）帳票種類変換マスター保守画面
        path: 'formtype-conversions',
        name: 'FormTypeConversions',
        component: () => import(/* webpackChunkName: "maintenance3" */ '@/views/maintenance/FormTypeConversions.vue'),
        meta: { title: '帳票種類変換マスター保守' },
      },
      { // （管理者）お知らせ配信データ保守画面
        path: 'notice',
        name: 'NoticeMaintenance',
        component: () => import(/* webpackChunkName: "maintenance0" */ '@/views/maintenance/Notice.vue'),
        meta: { title: 'お知らせ配信データ保守' },
      },
      { // （管理者）QandAマスター保守画面
        path: 'qanda',
        name: 'QandAMaintenance',
        component: () => import(/* webpackChunkName: "maintenance0" */ '@/views/maintenance/QandA.vue'),
        meta: { title: 'QandAマスター保守' },
      },
      { // （管理者）課金情報画面
        path: 'billing-info',
        name: 'BillingInfo',
        component: () => import(/* webpackChunkName: "maintenance0" */ '@/views/maintenance/BillingInfo.vue'),
        meta: { title: '課金情報' },
      },
      { // （管理者）システム設定保守画面
        path: 'system-config',
        name: 'SystemConfigMaintenance',
        component: () => import(/* webpackChunkName: "maintenance0" */ '@/views/maintenance/SystemConfig.vue'),
        meta: { title: 'システム設定保守' },
      },
      { // （代行入力者）代行入力サポート情報マスター保守画面
        path: 'entry-agent-support',
        name: 'EntryAgentSupportMaintenance',
        component: () => import(/* webpackChunkName: "maintenance0" */ '@/views/maintenance/EntryAgentSupport.vue'),
        meta: { title: '代行入力サポート情報マスター保守' },
      },
    ]
  },
  { // 利用規約画面
    path: '/tos',
    name: 'Tos',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Tos.vue'),
    meta: {
      title: '利用規約',
      isPublic: true,
    },
  },
  { // 商標について
    path: '/trademark',
    name: 'Trademark',
    component: () => import(/* webpackChunkName: "about" */ '@/views/Trademark.vue'),
    meta: {
      title: '商標について',
      isPublic: true,
    },
  },
  { // 存在しないパスへのアクセスはルートにリダイレクト
    path: '/:catchAll(.*)',
    redirect: '/',
  },
]

/** ルーターオブジェクト */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const element = to.hash ? document.querySelector(to.hash) : null;

    return savedPosition || element ? window.scrollTo({
                                        top: element?.offsetTop ?? 0,
                                        behavior: 'smooth'
                                      })
                                    : { top: 0 }
  },
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
});

/** ページ遷移前に実行 */
router.beforeEach((to, from, next) => {
  // 要サインイン画面に未サインイン状態でアクセスした場合
  if (!to.meta.isPublic && !store.getters.getApiKey) {
    // サインイン画面にリダイレクト
    router.push({
      name: "Signin",
    });
  }

  // 要サインイン画面にサインインユーザー情報未取得状態でアクセスした場合
  if (!to.meta.isPublic && !store.state.fetched){
    // サインインユーザー情報を取得しストアに保存
    store.dispatch("fetchOwnInfo");
  }

  next();
});

/** ページ遷移後に実行 */
router.afterEach((to) => {
  // ページタイトル変更（「画面名｜サービス名」）
  document.title = to.meta.title ? `${to.meta.title}｜${DEFAULT_TITLE}` : DEFAULT_TITLE;
});

/** エラー発生時 */
router.onError((error, to) => {
  // 利用中にデプロイされてルーティングの整合性がなくなった場合
  if (CHUNK_LOAD_ERROR_MSG.test(error.message)) {
    // リロードを行う
    console.warn(`${error.message}, force reload.`);
    window.location.assign(to.fullPath);
  }
});

export default router
