<template>
  <div class="container-xl mx-0" :class="{'with-banner': $props.isShowBanner}">
    <!-- お知らせ -->
    <div class="d-none d-sm-block">
      <NoticeList :displayArea="1" />
    </div>

    <!-- ナビゲーションメニュー -->
    <div class="mx-3">
      <NavMenu />
    </div>

    <!-- ルーティングエラーメッセージ-->
    <div v-if="showError" class="alert alert-danger" role="alert">
      <h5 class="mx-2">ダッシュボードを表示する適切な権限がありません</h5>
      <hr class="my-1">
      <p class="m-2">スタートアップページに設定されたダッシュボードを表示する権限がありません。
        「<router-link to="/settings">ユーザー設定</router-link>」画面より、
        スタートアップページを変更してください。
      </p>
    </div>

    <!-- ルータービュー（URLにより、各子コンポーネントを表示）-->
    <router-view />
  </div>
</template>

<style scoped>

@media (min-width: 1100px) {
  /** バナーが表示されいる場合のメインコンテンツ幅制限  */
  .with-banner {
    padding-left: 0;
    max-width: 80%;
  }
}
  
/* 575サイズ以下の場合 */
@media (max-width: 575px) {
  .container-xl {
  padding: 0; 
  margin: 0;
  }
}
</style>

<script>
import { ref, watchEffect } from 'vue';
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import NavMenu from '@/components/NavMenu.vue';
import NoticeList from '@/components/NoticeList.vue';

export default {
  name: 'Dashboard',
  components: {
    NavMenu,
    NoticeList,
  },
  props: {
    isShowBanner: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    /** vuex::store */
    const store = useStore();
    /** vue-router::router */
    const router = useRouter();
    /** vue-route::route */
    const route = useRoute();

    /** エラー表示フラグ */
    const showError = ref(false);

    /** 初期化処理 */
    const initialize = async () => {
      // ユーザーが設定しているスタートページにリダイレクトする
      switch (store.getters.getOwnInfo.startupPage) {
        case 1: // 帳票保管
          if (route.query?.redirectFrom === "evidence") {
            // 帳票保管ダッシュボード表示権限なし
            showError.value = true;
          } else {
            // 帳票保管ダッシュボードへリダイレクトする
            router.replace({ name: "EvidenceMain" });
          }
          break;
        case 2: // 請求書閲覧
          if (route.query?.redirectFrom === "invoice") {
            // 請求書ダッシュボード表示権限なし
            showError.value = true;
          } else {
            // 請求書ダッシュボードへリダイレクトする
            router.replace({ name: "InvoiceMain" });
          }
          break;
        case 3: // 請求書発行
          if (route.query?.redirectFrom === "delivery") {
            // 請求書ダッシュボード表示権限なし
            showError.value = true;
          } else {
            // 請求書ダッシュボードへリダイレクトする
            router.replace({ name: "DeliveryMain" });
          }
          break;
        case 4: // 代行入力
        if (route.query?.redirectFrom === "agent") {
            // 代行入力ダッシュボード表示権限なし
            showError.value = true;
          } else {
            // 代行入力ダッシュボードへリダイレクトする
            router.replace({ name: "AgentMain" });
          }
          break;
        default:
          // 請求書ダッシュボードへリダイレクトする（フォールバック）
          router.replace({ name: "InvoiceMain" });
          break;
      }
    }

    // 遷移時のURLパスを監視する
    watchEffect(() => {
      // エラーフラグ初期化
      showError.value = false;

      // パスによって処理分岐
      switch (route.path) {
        case "/invoice":
        case "/evidence":
        case "/delivery":
        case "/agent":
          // nothing（当該ページに遷移させる）
          break;
        default :
          // 初期化処理内で、スタートページにリダイレクトする
          initialize();
          break;
      }
    });

    return {
      showError
    }
  },
}
</script>
