import { reactive } from 'vue';
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useReCaptcha } from 'vue-recaptcha-v3';

const useSignin = () => {
    // vuex::store
    const store = useStore();
    // vue-router::route
    const route = useRoute();
    // vue-router::router
    const router = useRouter();

    // reCAPTCHA
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    // サインイン処理用オブジェクト
    const signin = reactive({
        data: {                    // ユーザデータ
            accountId: '',         // ID
            password:'',           // パスワード
            recaptchaToken: '',    // reCAPTCHAトークン
        },
        loadingStatus:false,       // 処理の状況
        isConfirmed: false,        // 入力内容確認の状態
    });

    // 状態管理用オブジェクト
    const status = reactive({
        inprogress: false,          // 処理状態
        success: false,             // 処理結果
        message: '',                // メッセージ
    });

    // アクセス理由チェック処理
    const checkAccessReason = () => {
      // URLパラメータをチェックしメッセージ表示
      switch (route.query.reason) {
        case "timeout":
          store.dispatch("addNotification", {
            id: "signin",
            title: "セッションタイムアウト",
            message: "認証の有効期限が切れました。再度サインインしてから、操作をやり直してください。",
            type: "danger"
          });
          break;
        case "revocation":
          store.dispatch("addNotification", {
            id: "signin",
            title: "強制サインアウト",
            message: "設定変更が行われたため、サインアウトしました。再度サインインしてから、操作をやり直してください。",
            type: "danger"
          });
          break;
        case "signout":
          store.dispatch("addNotification", {
            id: "signin",
            message: "サインアウトしました",
            type: "success",
            autoClose: true,
            closeTime: 10000
          });
          break;
      }
    };

    /** サインイン処理 */
    const doSignin = async () => {
        
        // 処理中状態ON
        status.inprogress = true;

        // 通知クリア
        store.dispatch("removeNotification", "signin");

        // 念のため保存済みデータ削除
        // ※正しくサインアウトを行わず別アカウントでサインインする際に不具合が発生するため
        store.dispatch("deleteStoredData");

        // reCAPTCHAトークン取得
        await recaptchaLoaded();
        signin.data.recaptchaToken = await executeRecaptcha('login');

        // 認証情報をセット
        const payload = {
            accountId: signin.data.accountId,
            password: signin.data.password,
            recaptchaToken: signin.data.recaptchaToken
        };

        // 認証実行
        await store.dispatch("doSignin", payload).then(async () => {
            // サインインしたユーザの情報を取得し保存
            await store.dispatch("fetchOwnInfo").then(() => {
                // 成功
                // リダイレクト先指定がある場合
                if (router.currentRoute.value.query.redirect) {
                    // クエリパラメータからタイムアウト理由、リダイレクト先パラメータを取り除く
                    const { reason, redirect, ...queries } = route.query; // eslint-disable-line no-unused-vars
                    
                    // 指定のリダイレクト先に遷移する
                    router.push({ path: router.currentRoute.value.query.redirect, query: queries });
                } else {
                    // 指定がない場合は、ダッシュボードに遷移する
                    router.push({ name: "Dashboard" });
                }
            }).catch(error => {
                // 失敗
                // エラーメッセージ表示
                store.dispatch("addNotification", {
                    id: "signin",
                    title: "エラー",
                    message: error.message,
                    type: "danger"
                });
            });
        }).catch(error => {
            // StatusCode=403かつReason=1･2の場合パスワード再設定を行わせる
            if (error.statusCode === 403 && [1, 2].includes(error.reason)) {
                router.push({ path: `/reset-required/${signin.data.accountId}` });
            } else {
                // エラーメッセージ表示
                store.dispatch("addNotification", {
                    id: "signin",
                    title: "エラー",
                    message: error.message,
                    type: "danger"
                });
            }
        });

        // 処理中状態OFF
        status.inprogress = false;
    }

    return {
        signin,
        status,
        checkAccessReason,
        doSignin,
    };
};

export default useSignin;