<template>
  <Loading v-model:active="isLoading" :can-cancel="false" color="var(--color-blue)" />
  <div class="container-xl mx-auto">
    <!-- ローディングスピナー -->
    <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />

    <!-- フォーム -->
    <form @submit.prevent="validate" v-if="!status.success" class="mb-3" ref="formContainer">
      <div v-if="!supportRequestContent.isConfirmed"><!-- 入力Div -->
        <h2>サポートリクエストフォーム</h2>
        <p class="my-3">
          下記の項目を入力し、個人情報の取り扱いにご同意いただいたうえで送信を行ってください。<br>
          ご入力いただいたメールアドレス宛に追ってご連絡申し上げます。
        </p>
        <p class="mb-1 small">※<span class="mx-1 badge bg-danger">必須</span>は必須項目です。必ず入力してください。</p>

        <!-- エラーメッセージ -->
        <div v-if="status.message" class="alert alert-danger" role="alert" v-html="status.message">
        </div>

        <table class="mb-3">
          <tr>
            <th>ご連絡先メールアドレス <span class="mx-1 badge bg-danger">必須</span><br>※ご契約済のお客様はアカウントIDを入力してください。</th>
            <td v-if="supportRequestContent.data.isAnonymous">
              <input type="email" class="wide" v-model="supportRequestContent.data.accountId" maxlength="100" autocomplete="username" :readonly="!supportRequestContent.data.isAnonymous" required>
              <div class="form-text">例：sample@xxxxx.jp（半角英数字でご入力ください）</div>
            </td>
            <td v-else>
              <p class="m-2">
                {{ $store.getters.getOwnInfo?.accountId }}<br>
                <span class="form-text">※サインイン中のアカウントIDを自動入力しています。</span>
              </p>
            </td>
          </tr>
          <tr>
            <th>氏名<span class="mx-1 badge bg-danger">必須</span></th>
            <td v-if="supportRequestContent.data.isAnonymous">
              <input type="text" v-model="supportRequestContent.data.name" autocomplete="name" required>
              <div class="form-text">例：山田&nbsp;太郎</div>
            </td>
            <td v-else>
              <p class="m-2">
                {{ $store.getters.getOwnInfo?.name }} &nbsp; 様
              </p>
            </td>
          </tr>
          <tr>
            <th>お客様番号</th>
            <td v-if="supportRequestContent.data.isAnonymous">
              <input type="tel" class="wide" title="半角数字でご入力ください" maxlength="13" pattern="^[0-9]{13}$" v-model="supportRequestContent.data.customerCode">
              <div class="form-text">半角数字でご入力ください</div>
            </td>
            <td v-else>
              <p class="m-2">
                {{ $store.getters.getDefaultTenant?.customerCode }}
              </p>
            </td>
          </tr>
          <tr>
            <th>お問い合わせ区分<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <div class="form-check form-check-inline">
                <input type="radio" id="typeRadio1" name="typeRadio" class="form-check-input no-style" value="機能・技術サポートについて" v-model="supportRequestContent.data.type" required>
                <label for="typeRadio1" class="form-check-label">
                  機能・技術サポートについて
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="typeRadio2" name="typeRadio" class="form-check-input no-style" value="契約について" v-model="supportRequestContent.data.type">
                <label for="typeRadio2" class="form-check-label">
                  契約について
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="typeRadio3" name="typeRadio" class="form-check-input no-style" value="価格について" v-model="supportRequestContent.data.type">
                <label for="typeRadio3" class="form-check-label">
                  価格について
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="typeRadio4" name="typeRadio" class="form-check-input no-style" value="利用可能ユーザー数の引き上げ" v-model="supportRequestContent.data.type">
                <label for="typeRadio4" class="form-check-label">
                  利用可能ユーザー数の引き上げ
                </label>
              </div>
              <div v-if="!supportRequestContent.data.isAnonymous" class="form-check form-check-inline">
                <input type="radio" id="typeRadio5" name="typeRadio" class="form-check-input no-style" value="OCRモデル構築用データの送付" v-model="supportRequestContent.data.type">
                <label for="typeRadio5" class="form-check-label">
                  OCRモデル構築用データの送付
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="typeRadio6" name="typeRadio" class="form-check-input no-style" value="その他" v-model="supportRequestContent.data.type">
                <label for="typeRadio6" class="form-check-label">
                  その他
                </label>
              </div>
              <div class="form-text">あてはまるものを1つ選択してください。</div>
            </td>
          </tr>
          <tr>
            <th>機能区分<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <div class="form-check form-check-inline">
                <input type="radio" id="featureRadio1" name="featureRadio" class="form-check-input no-style" value="帳票保管機能" v-model="supportRequestContent.data.feature" required>
                <label for="featureRadio1" class="form-check-label">
                  帳票保管機能
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="featureRadio2" name="featureRadio" class="form-check-input no-style" value="帳票閲覧機能" v-model="supportRequestContent.data.feature">
                <label for="featureRadio2" class="form-check-label">
                  帳票閲覧機能
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="featureRadio3" name="featureRadio" class="form-check-input no-style" value="サインアップ・サインイン等" v-model="supportRequestContent.data.feature">
                <label for="featureRadio3" class="form-check-label">
                  サインアップ・サインイン等
                </label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="featureRadio4" name="featureRadio" class="form-check-input no-style" value="その他" v-model="supportRequestContent.data.feature">
                <label for="featureRadio4" class="form-check-label">
                  その他
                </label>
              </div>
              <div class="form-text">あてはまるものを1つ選択してください。</div>
            </td>
          </tr>
          <tr>
            <th>件名<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <input type="text" maxlength="50" style="width:90%;" v-model="supportRequestContent.data.subject" required>
              <div class="form-text">50文字まででご入力ください</div>
            </td>
          </tr>
          <tr>
            <th>お問い合わせ内容<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <div class="form-group">
                <div class="col-12">
                  <div class="form-text mb-1">※パスワードは記載しないでください。</div>
                  <textarea style="width:90%;" rows="12" maxlength="2000" v-model="supportRequestContent.data.message" required>
                  </textarea>
                  <div class="form-text">最大2000文字程度でご入力ください</div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>添付ファイル</th>
            <td>
              <!-- ファイル選択要素・CSSで画面上は非表示 -->
              <input
                ref="selectedFiles"
                type="file"
                class="visually-hidden"
                accept="*"
                multiple
                @input="changeFiles"
              />
              <div class="form-text mb-1">
                ※1ファイルあたり{{ supportRequestContent.data.isAnonymous ? '10MB' : '100MB' }}まで、
                最大{{ supportRequestContent.data.isAnonymous ? '5件' : '100件' }}まで添付できます。
              </div>
              <button type="button" class="m-0 btn btn-outline-primary" @click="openFileSelect">
                ファイルを選択
              </button>
              <p class="m-0 mx-2 d-inline-block">
                {{ uploadFiles?.length }}件を選択中
                <span v-if="uploadFiles?.filter(i => i.failed).length > 0" class="text-danger">
                  ({{ uploadFiles?.filter(i => i.failed).length }}件のエラーがあります)
                </span>
              </p>
              <ol v-if="uploadFiles?.length" class="attachmentList">
                <li v-for="(item, index) in uploadFiles" :key="item">
                  <p class="m-0">
                    <span>{{ index + 1 }}.</span>
                    <span :title="item.file.name">{{ item.file.name }}</span>
                    <span class="message" v-if="item.message" :class="{'text-danger': item.failed}"><br>{{ item.message }}</span>
                  </p>

                  <span class="ms-auto">&nbsp;</span>

                  <button v-if="!item.uploaded" class="btn btn-sm btn-outline-danger" title="このファイルをアップロード対象から除外する" @click="deselect(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                    </svg>
                  </button>
                </li>
              </ol>
            </td>
          </tr>
        </table>
        <p class="mb-1"><strong>【個人情報の取り扱いについて】</strong></p>
        <ul>
          <li>帳票保管・閲覧クラウドhayabusaBanDをご利用される方は<a href="https://www.mbcnet.co.jp/pms/privacy.html" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>および<a href="https://www.mbcnet.co.jp/isms/index.html" target="_blank" rel="noopener noreferrer">情報セキュリティ方針</a>をご確認のうえ、ご同意をお願いいたします。</li>
          <li>本フォームへのご入力は任意ですが、ご入力が無い場合は当サービスをご利用いただけません。</li>
          <li>本フォームにご入力いただきましたメールアドレス・氏名は、お問い合わせ対応のために利用いたします。</li>
          <li>本フォームにより取得した個人情報は、個人情報保護方針および情報セキュリティ方針に沿って厳重に管理し、利用目的達成のための範囲を超えた利用は一切いたしません。</li>
          <li>ご本人様の自己の情報についての利用目的の通知・開示・追加・訂正・削除・利用の停止・消去に関するお問い合わせ先は、個人情報保護方針をご参照ください。</li>
        </ul>
        <!-- <p class="mb-1"><strong>【利用規約について】</strong></p>
        <ul>
          <li>帳票保管・閲覧クラウドhayabusaBanDをご利用される方は<a href="/tos" target="_blank" rel="noopener noreferrer">利用規約</a>をご確認のうえ、ご同意をお願いいたします。</li>
        </ul> -->
        <div class="text-center">
          <input type="checkbox" class="me-2" v-model="supportRequestContent.isAgreement" id="agreement">
          <label for="agreement">個人情報の取り扱いに同意する</label>
        </div>
        <button type="submit" class="button-navy" :disabled="!supportRequestContent.isAgreement">入力内容を確認する</button>
        <router-link to="/support/index" class="button">戻る</router-link>
      </div>
      <div v-else><!-- 確認Div -->
        <h2>サポートリクエスト確認</h2>
        <p class="my-3">入力項目をご確認ください。<br>
          正しければ「サポートリクエストを送信する」ボタンを押下してください。
        </p>
        <table class="mb-3">
          <tr>
            <th>アカウントID（メールアドレス）</th>
            <td>{{ supportRequestContent.data.accountId }}</td>
          </tr>
          <tr>
            <th>氏名</th>
            <td>{{ supportRequestContent.data.name }}</td>
          </tr>
          <tr>
            <th>お客様番号</th>
            <td>{{supportRequestContent.data.customerCode }}</td>
          </tr>
          <tr>
            <th>お問い合わせ区分</th>
            <td>{{ supportRequestContent.data.type }}</td>
          </tr>
          <tr>
            <th>機能区分</th>
            <td>{{ supportRequestContent.data.feature }}</td>
          </tr>
          <tr>
            <th>件名</th>
            <td>{{ supportRequestContent.data.subject }}</td>
          </tr>
          <tr>
            <th>お問い合わせ内容</th>
            <td class="break-line">{{ supportRequestContent.data.message }}</td>
          </tr>
          <tr>
            <th>添付ファイル</th>
            <td>
              <p class="m-0 d-inline-block">
                {{ uploadFiles?.length ? uploadFiles.length + "件を選択中" : "なし" }}
                <span v-if="uploadFiles?.filter(i => i.failed).length > 0" class="text-danger">
                  ({{ uploadFiles?.filter(i => i.failed).length }}件のエラーがあります)
                </span>
              </p>
              <ol v-if="uploadFiles?.length" class="attachmentList">
                <li v-for="(item, index) in uploadFiles" :key="item">
                  <p class="m-0">
                    <span>{{ index + 1 }}.</span>
                    <span :title="item.file.name">{{ item.file.name }}</span>
                    <span class="message" v-if="item.message" :class="{'text-danger': item.failed}"><br>{{ item.message }}</span>
                  </p>

                  <span class="ms-auto">&nbsp;</span>

                  <!-- アップロード中 -->
                  <div
                    v-if="item.inprogress"
                    class="mx-2 spinner-border spinner-border-sm text-primary"
                    title="アップロード中..."
                    role="status"
                  >
                  </div>
                  <!-- アップロード完了 -->
                  <div
                    v-else-if="item.uploaded"
                    class="mx-2 badge rounded-pill text-bg-success"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" margin="0" width="16" height="16" fill="currentColor" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                      <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg>
                  </div>
                  <!-- アップロード失敗 -->
                  <div
                    v-else-if="item.failed"
                    class="mx-2 badge rounded-pill text-bg-danger"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg>
                  </div>

                  <button v-if="!item.uploaded" class="btn btn-sm btn-outline-danger" title="このファイルをアップロード対象から除外する" @click="deselect(index)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
                    </svg>
                  </button>
                </li>
              </ol>
            </td>
          </tr>
          <tr>
            <th>個人情報の取り扱いの同意</th>
            <td>{{ supportRequestContent.isAgreement ? "同意する" : "同意しない" }}</td>
          </tr>
        </table>

        <button type="button" class="button-navy" @click.prevent="doSendSupportRequest" :disabled="!supportRequestContent.isConfirmed || status.inprogress">サポートリクエストを送信する</button>
        <button type="button" class="button" @click="supportRequestContent.isConfirmed = false; util.scrollToTop()">修正する</button>
      </div>
    </form>
    <div v-else><!-- 完了画面 -->
      <h2>サポートリクエスト送信完了</h2>
      <p>
        お問い合わせいただき、ありがとうございます。<br>
        数日中に担当者より、ご連絡差し上げますので今しばらくお待ちください。<br>
        しばらくたっても当社からの連絡がない場合は、お手数ではございますが、<br>
        再度お問い合わせのほどよろしくお願い申し上げます。
      </p>
      <router-link to="/support/index" class="button-navy">サポートページへ戻る</router-link>
      <router-link v-if="!supportRequestContent.data.isAnonymous" to="/dashboard" class="button">ダッシュボードへ戻る</router-link>
    </div>
  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

/* 入力フォーム */
form input:not([type="radio"], [type="checkbox"], [type="date"]),
form textarea {
  padding: 0.25rem;
  background-color: #ffe6e6;
}

/** 注釈の文字色を濃いめに変更（Bootstrap設定を上書き） */
.form-text {
  color: var(--text-tertiary);
}

/** 幅広の入力欄 */
input.wide {
  width: 90%;
}

/** 改行あり */
.break-line {
  white-space: pre-line;
  word-break: break-word;
}

/* テーブル */
table {
  width: 100%;
  border: 1px solid #ccc;
}
table th {
  padding: 0.75rem 1.25rem;
  width: 30%;
  font-weight: normal;
  border-bottom: 1px solid #ccc;
  background-color: #eee;
}
table td {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #ccc;
}

/* 添付ファイルリスト */
ol.attachmentList {
  margin: 10px 0;
  padding: 4px 6px;
  width:  60vw;
  min-height: 50px;
  max-height: 160px;
  border: 1px solid var(--border-gray);
  overflow-x: hidden;
  overflow-y: auto;
  list-style-position: inside;
}
ol.attachmentList li {
  padding: 6px 10px;
  border-bottom: 1px solid var(--border-gray);
  white-space: nowrap;
  display: flex;
  justify-content: start;
  align-items: center;
}
ol.attachmentList li:last-child {
  border-bottom: none;
}
ol.attachmentList li:hover {
  background-color: var(--hover);
}

ol.attachmentList li button:not(:last-child) {
  margin-right: 4px;
}

ol.attachmentList li p {
  overflow: hidden;
  text-overflow: ellipsis;
}

ol.attachmentList li p span.message {
  text-wrap: pretty;
}

/* 個人情報の取り扱いについて */
ul {
  padding: 0.75rem 1.75rem;
  border: 1px solid #ccc;
}

/* ボタン */
button:not(.btn),
.button:not(.btn) {
  margin: 10px auto;
  padding: 0.4rem 0.6rem;
  width: 50%;
  color: var(--text-primary);
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-gray);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
button:not(.btn):hover,
.button:not(.btn):hover {
  background-color: var(--color-gray-lighten);
}
button:not(.btn):active,
.button:not(.btn):active {
  background-color: var(--color-gray-darken);
}
button:not(.btn):disabled,
.button:not(.btn):disabled {
  color: var(--text-secondary) !important;
  background-color: var(--color-gray) !important;
  cursor: not-allowed;
}

button:not(.btn).button-navy,
.button:not(.btn).button-navy {
  color: var(--color-white);
  background-color: var(--color-navy);
}
button:not(.btn).button-navy:hover,
.button:not(.btn).button-navy:hover {
  background-color: var(--color-navy-lighten);
}
button:not(.btn).button-navy:active,
.button:not(.btn).button-navy:active {
  background-color: var(--color-navy-darken);
}
</style>

<script>
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import useSupportRequest from "@/composable/supportRequest";
import utilities from "@/services/utilities.js"
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: "SupportRequest",
  components: {
    Loading
  },
  setup() {
    /** 共通ユーティリティ読み込み */
    const util = utilities;

    /** vuex::store */
    const store = useStore();

    /** sendSupportRequest.jsの関数を読み込み */
    const { supportRequestContent, status,
            sendSupportRequest, uploadAttachmentFile } = useSupportRequest();

    /** サポートリクエスト送信日時（添付ファイルID） */
    const requestDateTime = ref();

    /** ファイル選択要素 */
    const selectedFiles = ref(null);

    /** アップロード対象ファイル */
    const uploadFiles = ref([]);

    /** 認証済みユーザー最大ファイルサイズ（byteで指定） */
    const authenticatedMaxFileSize = 104857600; // 100MB

    /** 匿名ユーザー最大ファイルサイズ（byteで指定） */
    const anonymousMaxFileSize = 10485760;  // 10MB

    /** 認証済みユーザー最大アップロード件数 */
    const authenticatedMaxFileCount = 100;

    /** 匿名ユーザー最大アップロード件数 */
    const anonymousMaxFileCount = 5;

    /** アップロード処理管理配列 */
    const workers = [];

    /** 同時並行アップロード処理数 */
    const concurrent = 5;

    /** 読み込み中フラグ */
    const isLoading = ref(false);

    /** アップロードリトライフラグ */
    const isRetryUpload = ref(false);

    /** ファイル選択ダイアログ表示処理 */
    const openFileSelect = () => {
      selectedFiles.value.click();
    }

    /** 選択済みファイルクリア */
    const clearUploadFiles = () => {
      uploadFiles.value.splice(0, uploadFiles.value.length);
      selectedFiles.value.value = null;
    }

    /** ファイル選択解除処理 */
    const deselect = (index) => {
      uploadFiles.value.splice(index, 1);

      if (uploadFiles.value.length === 0) {
        clearUploadFiles();
      }
    }

    /** ファイル選択後処理 */
    const changeFiles = async () => {
      // 既存の通知を削除
      store.dispatch("removeNotification", "fileSelectionLimitError");

      // ファイル数チェック
      if (selectedFiles.value.files.length === 0) {
        // nothing to do
        return;
      } else if ((supportRequestContent.data.isAnonymous && selectedFiles.value.files.length > anonymousMaxFileCount) ||
                 (!supportRequestContent.data.isAnonymous && selectedFiles.value.files.length > authenticatedMaxFileCount)) {
        // エラーを表示し終了
        store.dispatch("addNotification", {
          id: "fileSelectionLimitError",
          title: "アップロードファイル数上限エラー",
          message: `一度にアップロード可能なファイル数は${supportRequestContent.data.isAnonymous ? anonymousMaxFileCount : authenticatedMaxFileCount}件までです。ファイルを再度選択してください。`,
          type: "danger"
        });
        return;
      }

      // 選択されたファイルをアップロード対象ファイル配列にうつす
      for (const file of selectedFiles.value.files) {
        let hasError = false;
        let isFailed = false;
        let message = "";

        // ファイルサイズチェック
        if ((supportRequestContent.data.isAnonymous && file.size > anonymousMaxFileSize) ||
            (!supportRequestContent.data.isAnonymous && file.size > authenticatedMaxFileSize)) {
          hasError = true;
          isFailed = true;
          message = "アップロード可能な最大ファイルサイズを超えています。アップロード対象から除外してください。";
        }

        // ファイルをアップロード対象リストに追加
        uploadFiles.value.push({
          file: file,
          canUpload: hasError ? false : true,
          uploaded: false,
          failed: isFailed,
          inprogress: false,
          message: message
        });
      }

      // アップロードリトライフラグをOFFにする（アップロード対象が変更されたため）
      isRetryUpload.value = false;

      // ファイル選択要素をリセット
      selectedFiles.value.value = null;
    }

    /** アップロードワーカー初期化処理 */
    const initializeUploadWorker = async (isRetry = false) => {
      // 既存の通知を削除
      store.dispatch("removeNotification", "uploadAttachments");

      // アップロードリトライフラグをONにする（2度目以降はリトライ扱いのため）
      isRetryUpload.value = true;

      // ワーカーを初期化（「null=空き」とする）
      if (workers.length === 0) {
        for(let i = 0; i < concurrent; i++) {
          workers[i] = null;
        }
      }

      // 空きワーカーにアップロード処理を行わせる
      for (const workerIndex in workers.filter(i => i == null)) {
        // アップロード対象のファイルを探す
        // （isRetry: False=未アップのファイル, True=前回失敗したファイル）
        const targetFile = uploadFiles.value.find(i => i.canUpload &&
                                                       !i.inprogress &&
                                                       !i.uploaded &&
                                                       i.failed === isRetry);

        // 対象ファイルがない場合は終了する
        if (!targetFile) {
          break;
        }

        // 対象ファイルの処理中フラグをTrueにする
        targetFile.inprogress = true;

        // ワーカーをロックする（nullじゃなくして確保する）
        workers[workerIndex] = 1;

        // アップロードを実行する
        doUpload(workerIndex, targetFile);
      }
    }

    /** アップロード実行処理 */
    const doUpload = (workerIndex, targetFile) => {
      // アップロード処理を実行する
      workers[workerIndex] = uploadAttachmentFile(
        requestDateTime.value,      // サポートリクエスト送信日時
        targetFile.file,            // ファイルデータ
      ).then(() => {
          targetFile.uploaded = true;
          targetFile.failed = false;
          targetFile.message = "";
      }).catch((error) => {
          targetFile.failed = true;
          targetFile.message = error;
      }).finally(() => {
        // 自ワーカーをnullにする
        workers[workerIndex] = null;

        // ファイルの処理中フラグをFalseにする
        targetFile.inprogress = false;

        // 未アップロードファイルがある場合はアップロード処理を実行する
        if (uploadFiles.value.findIndex(i => !i.uploaded && !i.failed) >= 0) {
          // アップロードワーカー初期化処理を呼び出す（isRetry=false）
          initializeUploadWorker(false);
        } else {
          // 完了チェック
          checkUploadStatus();
        }
      });
    }

    /** アップロード完了チェック処理 */
    const checkUploadStatus = () => {
      if (uploadFiles.value.every((item) => item.uploaded === true)) {
        // アップロード正常終了、サポートリクエストを送信する
        sendSupportRequest(true)
          .then(() => {
            // nothing to do
          }).catch(() => {
            // 入力画面に戻すため、確認済みをFalseに変更
            supportRequestContent.isConfirmed = false;

            // ページ先頭にスクロールする
            util.scrollToTop();
          });
      } else {
        // 失敗あり、エラー通知を発行
        store.dispatch("addNotification", {
          id: "uploadAttachments",
          title: "添付ファイルアップロード失敗",
          message: "アップロードに失敗したファイルのエラー内容を確認し、再送信またはアップロード対象から除外してください。",
          type: "danger",
        });
      }
    }

    /** 入力内容検証処理 */
    const validate = () => {
      // ブラウザによる入力有無チェックのみ実施
      supportRequestContent.isConfirmed = true;

      // メッセージ初期化
      status.message = '';

      // 画面をページの先頭にスクロールする
      util.scrollToTop();
    }

    /** サポートリクエスト送信処理 */
    const doSendSupportRequest = () => {
      // 送信日時をセットする
      if (requestDateTime.value == null) {
        requestDateTime.value = util.getToday("YYYYMMDDhhmmss");
      }

      // 添付ファイルがあり、1つでも未アップロードがある場合は、アップロード処理を行う
      if (uploadFiles.value.length > 0 && uploadFiles.value.some(i => i.uploaded === false)) {
        // アップロードを開始する
        // （サポートリクエストの送信はアップロード完了チェック処理内部で行う）
        initializeUploadWorker(isRetryUpload.value);
      } else {
        // サポートリクエストの送信のみ実行する
        sendSupportRequest(false)
          .then(() => {
            // nothing to do
          }).catch(() => {
            // 入力画面に戻すため、確認済みをFalseに変更
            supportRequestContent.isConfirmed = false;

            // ページ先頭にスクロールする
            util.scrollToTop();
          });
      }
    }

    /** 初期化処理 */
    const initialize = () => {
      // アカウントIDがNull/Undefinedの場合は、サインイン前の問い合わせと判定しフラグをセットする
      supportRequestContent.data.isAnonymous = store.getters.getOwnInfo?.accountId == null;

      // サインイン済の場合は、ユーザー情報をセットする
      if (!supportRequestContent.data.isAnonymous) {
        supportRequestContent.data.accountId = store.getters.getOwnInfo?.accountId;
        supportRequestContent.data.name = store.getters.getOwnInfo?.name;
        supportRequestContent.data.customerCode = store.getters.getDefaultTenant?.customerCode;
      }
    }

    // ユーザー情報取得状況をチェック
    if (store.state.fetched) {
      // ユーザー情報取得済の場合は初期化処理実行
      initialize();
    } else {
      // ユーザー情報未取得の場合は取得後に初期化を行う
      isLoading.value = true;

      store.dispatch("fetchOwnInfo").finally(() => {
        isLoading.value = false;
        initialize();
      });
    }

    return {
      util,                     // ユーティリティ
      selectedFiles,            // ファイル選択要素
      uploadFiles,              // アップロード対象ファイル配列
      supportRequestContent,    // 新規登録データ
      status,                   // ステータス
      isLoading,                // 読み込みフラグ
      openFileSelect,           // ファイル選択ダイアログ表示処理
      changeFiles,              // ファイル選択変更イベント処理
      deselect,                 // ファイル選択解除処理
      doSendSupportRequest,     // サポートリクエスト送信処理
      validate,                 // 入力チェック処理
    }
  },
  mounted() {
    // reCAPTCHAバッジ表示
    setTimeout(() => {
      this.$recaptchaInstance.value.showBadge();
    }, 1000);
  },
  unmounted() {
    // reCAPTCHAバッジ非表示
    this.$recaptchaInstance.value.hideBadge();
  },
});
</script>
