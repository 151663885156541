<template>
  <footer :class="[$props.environment != 'production' ? $props.environment : '']">
    <div class="d-none d-sm-block">
      <div class="support" v-if="$route.path.split('/')?.at(1)?.toLowerCase().trim() !== 'support'">
        <router-link to="/support/index" title="サポートページに遷移します">
          <p>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
              <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
            </svg>
            サポート
          </p>
        </router-link>
      </div>

      <ul class="menu">
        <li><router-link to="/tos">利用規約</router-link></li>
        <li><a href="https://www.mbcnet.co.jp/pms/privacy.html" target="_blank" rel="noopener noreferrer">個人情報保護方針</a></li>
        <li><a href="https://www.mbcnet.co.jp/isms/index.html" target="_blank" rel="noopener noreferrer">情報セキュリティ方針</a></li>
        <li><a href="https://www.mbcnet.co.jp/company/index.html" target="_blank" rel="noopener noreferrer">会社情報</a></li>
        <li><router-link to="/trademark">商標について</router-link></li>
      </ul>

      <div class="logo">
        <a href="https://www.mbcnet.co.jp/" target="_blank" rel="noopener noreferrer">
          <img src="/img/mbc_logo.svg" alt="株式会社本島ビジネスセンター" />
        </a>
      </div>
      <div class="copyright">&copy; 2022- Motojima Business Center co.,ltd.</div>
    </div>

    <div class="d-sm-none">
      <div class="mobile">
        <ul class="menu-sm">
          <li><router-link to="/tos" class="px-2">利用規約</router-link></li>
          <li><a href="https://www.mbcnet.co.jp/pms/privacy.html" target="_blank" rel="noopener noreferrer" class="px-2">個人情報保護方針</a></li>
          <li><a href="https://www.mbcnet.co.jp/isms/index.html" target="_blank" rel="noopener noreferrer" class="px-2">情報セキュリティ方針</a></li>
          <li><a href="https://www.mbcnet.co.jp/company/index.html" target="_blank" rel="noopener noreferrer" class="px-2">会社情報</a></li>
          <li><router-link to="/trademark" class="px-2">商標について</router-link></li>
        </ul>
      </div>

      <div class="logo-mobile">
        <a href="https://www.mbcnet.co.jp/" target="_blank" rel="noopener noreferrer">
          <img src="/img/mbc_logo.svg" alt="株式会社本島ビジネスセンター" />
        </a>
      </div>

      <div class="copyright">&copy; 2022- Motojima Business Center co.,ltd.</div>
      
      <!-- グローバルフッターメニュー -->
      <div v-if="$route.name !== 'Signin'" class="card-footer d-flex justify-content-between d-sm-none">
        <div class="footer-menu">
          <!-- ハンバーガーメニュー -->
          <button class="py-2 navbar-toggler fixed-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"/>
            </svg>
            <div class="fixed-font" >
              メニュー
            </div>
          </button>
          <!-- ナビゲーションバー -->
          <nav class="navbar">
            <!-- ナビゲーションメニュー -->
            <div class="offcanvas offcanvas-start" id="navbarNav">
              <div class="offcanvas-header">
                <h5 id="offcanvasRightLabel">メニュー</h5>
                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <ul class="navbar-nav">
                <li v-if="isStorable && $store.getters.getOwnPermissions.canAccessEvidences" class="nav-item">
                  <a class="nav-link evidence" href="/evidence">【保管】保管帳票一覧</a>
                </li>
                <li v-if="isBrowsable && $store.getters.getOwnPermissions.canAccessReceiveInvoices" class="nav-item">
                  <a class="nav-link invoice" href="/invoice">【閲覧】受領請求書一覧</a>
                </li>
                <li v-if="isDeliverable && $store.getters.getOwnPermissions.canAccessDeliveryInvoices" class="nav-item">
                  <a class="nav-link invoice" href="/delivery">【発行】発行請求書一覧</a>
                </li>
                <li v-if="$store.getters.getOwnPermissions.canAccessAdminMenu" class="nav-item">
                  <a class="nav-link" href="/maintenance">管理者メニュー</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="/support/index">サポートページ</a>
                </li>
                <li class="nav-item">
                  <a
                    id="SignoutButton"
                    class="nav-link"
                    @click="signoutConfirm"
                  >
                    サインアウト
                  </a>
                </li>
              </ul>
            </div>
          </nav>

          <button v-if="$route.name === 'EvidenceMain' && $store.getters.getOwnPermissions.canUploadEvidence" class="py-2 fixed-btn" @click="openMobileUploadDialog">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-cloud-upload" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z" />
              <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z" />
            </svg>
            <div class="fixed-font">
              新規アップロード
            </div>
          </button>
         
          <!-- 帳票一覧画面での絞り込み検索 -->
          <button
            v-if="$route.name === 'EvidenceMain'"
            type="button"
            class="py-2 fixed-btn"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasContainer"
            aria-expanded="false"
            aria-controls="offcanvasContainer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
            <div class="fixed-font">
              絞り込み検索
            </div>
          </button>

          <!-- 請求書一覧画面での絞り込み検索 -->
          <button
            v-if="$route.name === 'InvoiceMain' || $route.name === 'DeliveryMain'"
            type="button"
            class="py-2 fixed-btn"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasContainer"
            aria-expanded="false"
            aria-controls="offcanvasContainer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
            <div class="fixed-font">
              絞り込み検索
            </div>
          </button>

          <button v-if="$route.name !== 'Menu' && $route.name !== 'SpMenu'" class="py-2 fixed-btn" @click="scrollToTop">
            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="currentColor" class="bi bi-chevron-double-up" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M7.646 2.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 3.707 2.354 9.354a.5.5 0 1 1-.708-.708z"/>
              <path fill-rule="evenodd" d="M7.646 6.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 7.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
            </svg>
            <div class="fixed-font">
              トップへ
            </div>
          </button>
        </div>
      </div><!-- .card-footer -->
    </div>
  </footer>
  <ConfirmDialog
    id="SignoutConfirmDialog"
    content="サインアウトしますか？"
    buttons="YesNo"
    :positiveAction="signout"
    :canCancelable="true"
  />
</template>

<style scoped>
footer {
  height: 86px;
  border-top: 1px solid #ccc;
  font-size: 12px;
  background-color: #fff;
}

/** サポート */
footer div.support {
  color: var(--text-primary);
  font-size: 16px;
  right: 0;
  top: 100px;
  position: fixed;
  border: 1px solid var(--border-gray);
  border-radius: 4px 0 0 4px;
  background-color: #fefefe;
  transition: var(--anim-speed);
}
footer div.support:hover {
  color: #fff;
  background-color: var(--theme-primary);
  box-shadow: 0 2px 4px var(--shadow);
  transition: var(--anim-speed);
}
footer div.support a {
  color: var(--text-primary);
  text-decoration: none;
}
footer div.support a:hover {
  color: #fff;
}
footer div.support p {
  margin: 0;
  padding: 10px 2px 10px;
  writing-mode: vertical-lr;
  display: inline-block;
  transition: var(--anim-speed);
}
footer div.support p:hover {
  padding: 14px 6px 14px;
  transition: var(--anim-speed);
}
footer div.support svg {
  vertical-align: baseline;
}

/** メニュー */
footer ul.menu li {
  padding-left: 5px;
  margin-left: 5px;
  border-left: 1px solid #ccc;
  float: left;
}
footer ul.menu li:first-child {
  border-left: 0px solid #ccc;
}
footer ul.menu {
  color: #666;
  list-style-type: none;
  float: left;
  margin-top: 25px;
}
footer ul.menu li a,
footer ul.menu li a hover,
footer ul.menu li a visited {
  color: #666;
}

footer .logo {
  float: right;
  padding: 10px 5px;
}
footer .logo img {
  image-rendering: -webkit-optimize-contrast;
  width:228px;
}

footer .copyright {
  clear: both;
  color: #fff;
  text-align: center;
  background: var(--theme-primary);
  padding: 3px 0;
}
footer.staging .copyright {
  background-color: orange;
}
footer.development .copyright {
  background-color: var(--color-green);
}

/** スマホ版 メニュー */
.mobile {
  text-align: center;
  padding-bottom: 10px;
}
.mobile .menu-sm {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.mobile ul.menu-sm li {
  padding-left: 10px;
  padding-bottom: 10px;
  margin-left: 5px;
}
.mobile ul.menu-sm li:first-child {
  border-left: 0px solid #ccc;
}
.mobile ul.menu-sm {
  color: #666;
  list-style-type: none;
  margin: 5% 6% 8% 4% ;
}
.mobile ul.menu-sm li a,
.mobile ul.menu-sm li a hover,
.mobile ul.menu-sm li a visited {
  color: #666;
}

/** スマホ版 画像 */
footer .logo-mobile {
  float: right;
  padding-bottom: 15px;
  padding-right: 10px;
}
footer .logo-mobile img {
  image-rendering: -webkit-optimize-contrast;
  width:150px;
}

.mobile .logo-mobile {
  z-index: 5;
}

/* 画面サイズ576px以下の時 */
@media (max-width: 576px) {
  /* ナビメニュー*/
  .navbar-light navbar-brand {
    border-color: rgba(0,0,0,0);
    display: inline-flex;
    padding: 0;
  }
  .navbar-toggler{
    border-color: rgba(0,0,0,0);
  }
  .nav-link{
    margin: 3%;
    font-size: 16px;
    font-weight: normal;
  }
    
  .detail-menu {
    border-bottom: 1px solid #ccc;
  }
  /* アコーディオンメニュー 位置合わせ */
  .accordion-button {
    color: var(--bs-nav-link-color);
    padding-left: 3%;
  }

  /* スマホでのフッターメニュー */
  .footer-menu {
    display: flex;
    bottom: 0;
    right: 0;
    width: 100%;
    border-top-style: solid;
    border-top-width: thin;
    border-color: #0c3e5f;
    position: fixed;
    z-index: 2;
  }
  
  .fixed-btn{
    color: #0c3e5f;
    background-color: #e4f0faec;
    border: 0;
    width: 100%;
  }
  .fixed-btn > a {
    text-decoration: none;
    display: block;
    text-align: center;
    color: #0c3e5f;
  }
  .offcanvas.show{
    width: 80%;
  }
  .navbar-toggler {
    border: 0;
  }
  .fixed-font{
    color: #0c3e5f;
    font-size: x-small;
    padding: 3%;
  }

  .preview-panel {
    position: fixed;
    right: 0;
    top: 0;
    width: 50%; /* 必要に応じて調整 */
    height: 100%;
    overflow: auto;
    background: #fff;
  }
  .close-btn {
    display: none;
  }

  /** 検索条件 日付 */
  input[type=date] {
    width: 100% !important;
    display: inline-block;
  }

  #popup {
    width:70%;
    line-height:30px;
    background:#f0f0f0;
    border:2px dotted #464646;
    padding: 3px;
    box-sizing:border-box;
    display:none;
    position:fixed;
    top:12%;
    left:50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .popuplabel {
    display:block;
  }
  .popuplabel span {
    display:block;
    background-color:#fff;
    color:#000;
    width:80px;
    border-radius:4px;
    text-align:center;
  }
  .popuplabel span:hover {
    cursor:pointer;
  }
  .popupckbox {
    display:none;
  }

  .popupckbox:checked + #popup {
    display:block;
    transition:.2s;
  }

  .btn-preview {
    border-radius: 30px;
    color: #000000;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    margin-right: auto;
  }

  .input-group>.form-control, .input-group>.form-floating, .input-group>.form-select{
    width: 50%;
  }

  .dialog-form {
    position: fixed;
    margin: 20px auto !important;
  }
  .dialog-form .card-body {
    margin: 0 0.5px;
    padding: 0 10px;
    height: calc(100vh - 150px);
    box-sizing: border-box;
    overflow-y: scroll;
    overscroll-behavior: contain;
  }

  /** 帳票詳細-重複警告アコーディオン */
  details.duplicate {
    margin: 6px;
    padding: 4px;
  }
}
</style>

<script>
import { computed, defineComponent, reactive } from "vue";
import utilities from "@/services/utilities.js"
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import backend from "@/services/backendApi";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default defineComponent({
  name: "GlobalFooter",
  components: {
    ConfirmDialog,
  },
  props: {
    /* environment */
    environment : {
      type: String,
      default: "production",
    },
  },
  setup() {
    /** vuex::store */
    const store = useStore();
    /** vue-router::route */
    const route = useRoute();
    /** vue-router::router */
    const router = useRouter();

    /** 共通ユーティリティ読み込み */
    const util = utilities;
    
    /** 帳票詳細ダイアログの状態 */
    const detailDialogState = reactive({
      isOpen: false,              // 表示中か否か
      canClose: true,             // 閉じられるか否か
      isEscDisabled: false,       // 閉じるかどうか確認中か否か
      isReloadRequired: false,    // 閉じた際に一覧の更新が必要か否か
      hasLocked: false,           // 帳票のロックを取得したか否か
      proccessCount: 0,           // 処理中の処理の数
      isDuplicateDisplay: false,  // 重複帳票データ一覧から過去のデータを選択表示した場合true
    });

    /** ページトップへスクロールする処理 */
    const scrollToTop = () => {
      switch (route.name) {
        case "InvoiceMain":
        case "EvidenceMain":
        case "DeliveryMain":
          // 請求書一覧・保管帳票一覧画面の場合は、テーブルを先頭にする
          document.querySelector('#ScrollTargetMobile').scroll({
            top: 0,
            behavior: "smooth",
          });
          break;
      
        default:
          window.scroll({
            top: 0,
            behavior: "smooth",
          });
          break;
      }
    }

    /** サインアウト確認ダイアログ表示 */
    const signoutConfirm = () => {
      new Modal(document.getElementById('SignoutConfirmDialog')).show();
    };

    /** サインアウト処理 */
    const signout = async () => {
      // サインアウトボタンを処理中に変更
      const signoutButton = document.getElementById("SignoutButton");
      signoutButton.disabled = true;
      
      const buttonString = signoutButton.textContent;
      signoutButton.textContent = "サインアウト中...";

      // Cookie消去API呼び出し(成功の有無は問わない)
      await backend.doSignout()
        .finally(() => {
          // 保存済みデータ削除
          store.dispatch("deleteStoredData");

          // サインインページにリダイレクト
          router.push({
            name: "Signin",
            query: { reason: "signout" }
          });

          // サインアウトボタンをもとに戻す
          signoutButton.textContent = buttonString;
        });
    }

    /** スマホ版_帳票アップロードダイアログ表示処理 */
    const openMobileUploadDialog = () => {
      // アップロードダイアログを開く
      new Modal(document.getElementById("MobileEvidenceUploadDialog")).show();
    }

    /** 帳票保管契約状況(デフォルトテナントの契約状況を確認する) */
    const isBrowsable = computed(() => {
      return store.getters.getDefaultTenant?.isBrowsable ?? false;
    });

    /** 帳票保管契約状況(デフォルトテナントの契約状況を確認する) */
    const isStorable = computed(() => {
      return store.getters.getDefaultTenant?.isStorable ?? false;
    });

    /** 請求書発行契約状況(デフォルトテナントの契約状況を確認する) */
    const isDeliverable = computed(() => {
      return store.getters.getDefaultTenant?.isDeliverable ?? false;
    });

    return {
      util,                             // ユーティリティ
      signout,                          // サインアウト
      signoutConfirm,
      openMobileUploadDialog,           // スマホ版_帳票アップロードダイアログ表示処理
      scrollToTop,                      // スマホ版_トップへ
      detailDialogState,                // 帳票詳細ダイアログ開閉状態
      isBrowsable,                      // 請求書閲覧契約状況
      isStorable,                       // 帳票保管契約状況
      isDeliverable,                    // 請求書発行契約状況

    };
  },
});
</script>
