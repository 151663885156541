<template>
  <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />
  <main>
    <img id="bandLogo" src="img/band_logo_r.svg" alt="BanDシリーズ">
    <article id="logo" class="d-none d-sm-block">
      <div class="image">
        <img src="img/logo_s.svg" alt="帳票保管・閲覧クラウド hayabusa BanD">
      </div>
      <div class="info">
        <NoticeList :displayArea="0" />
      </div>
    </article>
    <article id="signin">
      <section id="signinLayer">
        <img class="img-mobile d-sm-none" src="img/logo_s.svg" alt="帳票保管・閲覧クラウド hayabusa BanD">

        <h2 class="text-center">
          サインイン
        </h2>

        <form @submit.prevent="doSignin" ref="formContainer">
          <dl>
            <dt>
              <label for="accountId">アカウントID(メールアドレス)</label>
            </dt>
            <dd>
              <input v-model="signin.data.accountId" id="accountId" type="email" autocomplete="username" autofocus required>
            </dd>
            <dt>
              <label for="password">パスワード</label>
            </dt>
            <dd>
              <input v-model="signin.data.password" id="password" type="password" autocomplete="current-password" required>
              <label>パスワードをお忘れの場合は<router-link to="/forgot_password">こちらへ</router-link></label>
            </dd>
          </dl>

          <div id="button" class="buttons-row">
            <button type="submit" class="button-navy">サインイン</button>
          </div>

          <p class="m-3 text-center">
            <router-link to="/signup">初めての方はこちら</router-link>
          </p>
          <p class="m-3 text-center">
            <router-link to="/add-other-tenants?redirect=/settings&pane=assign">既にご利用中のお客様で他社発行請求書の受信設定はこちら</router-link>
          </p>

        </form>

        <div class="info-mobile d-sm-none">
          <NoticeList :displayArea="0" />
        </div>

        <div class="support d-sm-none">
          <router-link to="/support/index" title="サポートページに遷移します">
            <p>
              <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-question-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
              </svg>
              サポート
            </p>
          </router-link>
        </div>

      </section>
    </article>
  </main>
</template>

<style scoped>
html,
body {
  height: 100vh;
}

body {
  margin: 0 auto;
  width: 100%;
}

/* Bootstrap 打消し */
*, ::after, ::before {
  box-sizing: unset;
  font-family: var(--font-family) !important;
}

/* 画像*/
img {
  image-rendering: -webkit-optimize-contrast;
}

/*スマホ版 画像*/
.img-mobile {
  width: 200px;
}

/** 共通コンポーネント **/

/* ボタン */
button {
  margin: 0 10px;
  padding: 0.4rem 0.6rem;
  min-width: 120px;
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

button.button-navy {
  color: var(--color-white);
  background-color: var(--color-navy);
}

button.button-navy:hover {
  background-color: var(--color-navy-lighten);
}

button.button-navy:active {
  background-color: var(--color-navy-darken);
}

/* 入力フォーム */
form input,
form select,
form textarea {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus,
form select:focus,
form textarea:focus,
input.indicate-focus:focus {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

form .validate input:invalid,
form .validate select:invalid,
form .validate textarea:invalid {
  color: var(--color-red);
  border: 1px solid var(--color-red);
  background-color: var(--background-error);
  box-shadow: 0 0 8px -4px var(--color-red);
  outline: none;
}

/* フォーム */
form dl dt {
  padding: 8px 0 0;
}

form dl dd {
  padding: 0 0 10px;
  margin-left: 0;
}

form input:not([type="radio"]):not([type="checkbox"]):not([type="date"]) {
  width: 99%;
  line-height: 1.25rem;
  background-color: #ffe6e6;
}

form select {
  width: 100%;
  height: 30px;
}

form label {
  font-weight: normal;
}

/** メインコンテンツ **/
main {
  display: flex;
  height: calc(100vh - 86px);
  margin: 0;
  padding: 0;
  color: var(--text-primary);
  flex: auto;
}

#bandLogo {
  width: 8%;
  max-width: 100px;
  top: 10px;
  left: 10px;
  position: absolute;
}

article {
  flex-grow: 1;
}

#logo {
  width: 60%;
  position: relative;
}

#logo .image {
  position: relative;
  height: 66%;
}

#logo .image img {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  width: 70%;
  overflow: hidden;
}
#logo .info {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 34%;
}

#signin {
  width: 40%;
  padding: 10% 10%;
  background-color: #1e72b1;
  position: relative;
}

#signinLayer {
  margin-right: -50%;
  padding: 2rem 2rem 4rem;
  width: 70%;
  height: calc(100% - 220px);
  top: 50%;
  left: 50%;
  border: 20px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.9);
  box-sizing: content-box;
  position: absolute;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

h2 {
  margin: 30px 0;
  font-size: 18pt;
  font-weight: bold;
}

/* ボタン列*/
.buttons-row {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

button {
  margin: 0;
  min-width: 90%;
}

/** スマホ版 サポート */
div.support {
  color: var(--text-primary);
  font-size: 13px;
  right: 0;
  top: 15px;
  position: fixed;
  border: 1px solid var(--border-gray);
  border-radius: 4px 0 0 4px;
  background-color: #fefefe;
  transition: var(--anim-speed);
}
div.support:hover {
  color: #fff;
  background-color: var(--theme-primary);
  box-shadow: 0 2px 4px var(--shadow);
  transition: var(--anim-speed);
}
div.support a {
  color: var(--text-primary);
  text-decoration: none;
}
div.support a:hover {
  color: #fff;
}
div.support p {
  margin: 0;
  padding: 7px 7px 7px;
  display: inline-block;
  transition: var(--anim-speed);
}
div.support p:hover {
  padding: 10px 16px 10px;
  transition: var(--anim-speed);
}
div.support svg {
  vertical-align: baseline;
}

/*スマホ版 お知らせ*/
div.info-mobile {
  width: 100%
}
</style>

<script>
import Loading from "vue-loading-overlay";
import NoticeList from "@/components/NoticeList.vue"
import useSignin from "@/composable/signin";

export default {
  components: {
    Loading,
    NoticeList
  },
  setup() {
    // signin.jsの関数を読み込み
    const { signin, status, doSignin, checkAccessReason } = useSignin();

    const validate = () => {
      // ブラウザによる入力有無チェックのみ実施
      signin.isConfirmed = true;
      // メッセージ初期化
      status.message = '';
    }

    // アクセス時のメッセージを表示
    checkAccessReason();

    return {
      signin,           // サインインデータ
      status,           // ステータス
      doSignin,         // サインイン処理
      validate,         // 入力チェック処理
    }
  },
  mounted() {
    // reCAPTCHAバッジ表示
    setTimeout(() => {
      this.$recaptchaInstance.value.showBadge();
    }, 1000);
  },
  unmounted() {
    // reCAPTCHAバッジ非表示
    this.$recaptchaInstance.value.hideBadge();
  },
};
</script>
