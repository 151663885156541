import store from "@/store/index";

class Utilities {

    /**
     * 日付フォーマット処理
     * @param {Date} date Date型の日付
     * @param {String} format フォーマット形式
     * @returns {String} formatで指定した形式にフォーマットしたdateの文字列
     */
    format = (date, format) => {
        const  dayString = ["日", "月", "火", "水", "木", "金", "土",
                            "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        return format.replace(/YYYY/g, date.getFullYear())
                     .replace(/YY/g, date.getFullYear().toString().slice(-2))
                     .replace(/Y/g, date.getFullYear().toString().slice(-2))
                     .replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
                     .replace(/M/g, (date.getMonth() + 1).toString().slice(-2))
                     .replace(/DD/g, ('0' + date.getDate()).slice(-2))
                     .replace(/D/g, date.getDate().toString().slice(-2))
                     .replace(/hh/g, ('0' + date.getHours()).slice(-2))
                     .replace(/h/g, date.getHours().toString().slice(-2))
                     .replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
                     .replace(/m/g, date.getMinutes().toString().slice(-2))
                     .replace(/sss/g, ('00' + date.getMilliseconds()).slice(-3))
                     .replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
                     .replace(/s/g, date.getSeconds().toString().slice(-2))
                     .replace(/aaa/g, dayString[date.getDay()] + "曜日")
                     .replace(/aa/g, dayString[date.getDay()])
                     .replace(/a/g, dayString[date.getDay()+7]);
    }

    /**
     * バックエンドから取得した日付を指定の形式に整形する処理
     * @description YYYYMMDD => YYYY/MM/DD等
     * @param {string} date 8桁日付（YYYYMMDD）
     * @param {string} format フォーマット形式（デフォルト：YYYY年MM月DD日）
     * @returns {string} formatで指定した形式に変換されたvalue
     */
    formatToDateString = (value, format = "YYYY年MM月DD日") => {
        // 文字数チェック
        if (value.length !== 8) {
            throw new TypeError("桁数は8桁でなければなりません。");
        }

        // Date型へ変換
        const date = new Date(value.substring(0, 4) + "/" + value.substring(4, 6) + "/" + value.substring(6, 8));

        // 変換して返す
        return this.format(date, format);
    };

    /**
     * バックエンドから取得した日時を指定の形式に整形する処理
     * @description YYYYMMDDhhmmss => YYYY/MM/DD hh:mm:ss等
     * @param {string} date 14桁日時（YYYYMMDDhhmmss）
     * @param {string} format フォーマット形式（デフォルト：YYYY年MM月DD日 hh:mm:ss）
     * @returns {string} formatで指定した形式に変換されたvalue
     */
    formatToDateTimeString = (value, format = "YYYY年MM月DD日 hh:mm:ss") => {
        // 文字数チェック
        if (value.length !== 14) {
            throw new TypeError("桁数は14桁でなければなりません。");
        }

        // Date型へ変換
        const date = new Date(value.substring(0, 4) + "/" + value.substring(4, 6) + "/" + value.substring(6, 8)
                             + " " + value.substring(8, 10) + ":" + value.substring(10, 12) + ":" +value.substring(12, 14));

        // 変換して返す
        return this.format(date, format);
    };

    /**
     * バックエンドから取得した日時をdatetime-local形式に変換する処理
     * @description YYYY/MM/DD hh:mm:ss => YYYY-MM-DDThh:mm
     * @param {String} value 19桁日時（YYYY/MM/DD hh:mm:ss）
     * @returns {String} datetime-local（YYYY-MM-DDThh:mm）
     */
    convertToDateTime = (value) => {
        // 文字数チェック
        if (value.length !== 19) {
            throw new TypeError("桁数は19桁でなければなりません。");
        }

        // 形式チェック
        const regex = /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\s([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
        if (!regex.test(value)) {
            throw new TypeError("形式はYYYY/MM/DD hh:mm:ssでなければなりません。");
        }

        // Date型に変換
        const date = new Date(value);

        // 変換して返す
        return this.format(date, "YYYY-MM-DDThh:mm");
    }

    /**
     * datetime-local形式をバックエンド用の日時に変換する処理
     * @description YYYY-MM-DDThh:mm => YYYY/MM/DD hh:mm:ss
     * @param {String} value datetime-local（YYYY-MM-DDThh:mm）
     * @returns {String} 19桁日時（YYYY/MM/DD hh:mm:ss）
     */
    convertToDateTimeString = (value) => {
        // 文字数チェック
        if (value.length !== 16) {
            throw new TypeError("桁数は16桁でなければなりません。");
        }

        // 形式チェック
        const regex = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])T([01][0-9]|2[0-3]):[0-5][0-9]$/;
        if (!regex.test(value)) {
            throw new TypeError("形式はYYYY-MM-DDThh:mmでなければなりません。");
        }

        // Date型に変換
        const date = new Date(value);

        // 変換して返す
        return this.format(date, "YYYY/MM/DD hh:mm:00");
    }

    /**
     * 現在日時取得処理
     * @param {String} format フォーマット形式（デフォルト：YYYY年MM月DD日）
     * @returns {String} formatで指定した形式にフォーマットした現在日時
     */
    getToday = (format = "YYYY年MM月DD日") => {
        return this.format(new Date(), format);
    };

    /**
     * 2つの日付の差（日数）を求める処理
     * @param {String} date1 YYYYMMDD形式の日付（引かれる方）
     * @param {String} date2 YYYYMMDD形式の日付（引く方）
     * @returns {Number} date1とdate2の差
     */
    calcDays = (date1, date2 = null) => {
        const d1 = new Date(this.formatToDateString(date1, "YYYY/MM/DD"));
        const d2 = new Date(this.formatToDateString(date2, "YYYY/MM/DD"));
        return Math.floor((d1.getTime() - d2.getTime()) / (1000 * 60 * 60 * 24));
    }

    /**
     * 帳票の削除日を求める処理
     * @description 基準日（取引日）から指定した年数を加算した日付を返します。
     * @param {String} referenceDate
     * @param {Number} retentionYears
     * @param {String} format フォーマット形式（オプション、指定なし時はDate型で返す）
     * @returns {*} 帳票が削除される日付（format指定時は文字列型、無指定時はDate型のまま）
     */
    calcDeletionDate = (referenceDate, retentionYears, format = null) => {
        const date = new Date(this.formatToDateString(referenceDate, "YYYY/MM/DD"));
        date.setFullYear(date.getFullYear() + retentionYears);
        return format ? this.format(date, format) : date;
    }

    /**
     * オブジェクト配列をソートする処理
     * @param {*} a 比較対象１
     * @param {*} b 比較対象２
     * @param {Number} direction 比較方法（1=昇順, -1=降順）
     * @param {Number} nullsFirst データ無の場合の動作
     * @param {String} key ソート基準にするキー
     * @returns {Array<Object>} ソート後の配列データ
     */
    objectSort = (a, b, key, direction = 1, nullsFirst = 1) => {
        if (a[key] == undefined && b[key] == undefined) {
            return 0;
        }
        if (a[key] == undefined) return nullsFirst * 1;
        if (b[key] == undefined) return nullsFirst * -1;
        if (a[key] === '') return nullsFirst * 1;
        if (b[key] === '') return nullsFirst * -1;
        if (a[key] > b[key]) return direction * 1;
        if (a[key] < b[key]) return direction * -1;
        return 0;
    }

    /**
     * オブジェクト配列を複数キーでソートする処理
     * @param {Array<Object>} data 配列データ
     * @param {Array<String>} keys ソート基準にするキーの配列
     * @param {Number} direction 比較方法（1=昇順, -1=降順）
     * @param {Number} nullsFirst データ無の場合の動作
     * @returns {Array<Object>} ソート後の配列データ
     */
    objectMultiSort = (data, keys, direction = 1, nullsFirst = 1) => {
        const _data = data.slice();
        _data.sort((a, b) => {
          let order = 0;
          keys.some(key => {
            order = this.objectSort(a, b, key, direction, nullsFirst);
            return !!order;
          });
          return order;
        });
        return _data;
    }

    /**
     * クリップボードに文字列をコピーする処理
     * @param {String} text コピーする文字列
     */
    copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
        .then(() => {
            // コピー成功
            store.dispatch("addNotification", {
              id: "copyToClipboard",
              message: "クリップボードにコピーしました",
              type: "success",
              autoClose: true
            });
        })
        .catch(error => {
            console.error(error);
            // コピー失敗
            store.dispatch("addNotification", {
              id: "copyToClipboard",
              message: "クリップボードにコピーできませんでした",
              type: "danger",
            });
        })
    }

    /**
     * ファイル形式（拡張子）チェック処理
     * @param {File} file チェックするファイルオブジェクト
     * @param {String} type 許可する拡張子（デフォルト：pdf）
     * @returns {Boolean} チェック通過の場合はTrue、それ以外の場合はFalseを返す
     */
    validateFileType = (file, type = "pdf") => {
        try {
            if (file.name.toUpperCase().endsWith("." + type.toUpperCase())) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            throw new TypeError(error.message);
        }
    }

    /**
     * 3桁区切りカンマ挿入処理
     * @param {Number} value カンマを挿入する前の数値
     * @returns {String} カンマを挿入した後の文字列
     */
    insertComma = (value) => {
        return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    /**
     * 3桁区切りカンマ挿入処理
     * （blurイベント用）
     * @param {Event} event カンマを挿入する値が入力されているInputから発生するDOMイベント
     * @returns {String} カンマを挿入した後の文字列
     */
    insertCommaEvent = (event) => {
        if (event.target.value === "") {
            return;
        }
        if (event.type !== "blur") {
            return;
        }
        return this.insertComma(parseInt(event.target.value, 10));
    }

    /**
     * 3桁区切りカンマ除去処理
     * @param {String} value カンマを除去する前の文字列
     * @returns {Number} カンマを除去した後の数値
     */
    removeComma = (value) => {
        return parseInt(value.replace(/,/g, ""), 10);
    }

    /**
     * 3桁区切りカンマ除去処理
     * （focusイベント用）
     * @param {Event} event カンマを除去する値が入力されているInputから発生するDOMイベント
     * @returns {Number} カンマを除去した後の数値
     */
    removeCommaEvent = (event) => {
        if (event.target.value === "") {
            return;
        }
        if (event.type !== "focus") {
            return;
        }
        return this.removeComma(event.target.value);
    }

    /**
     * ハイフン除去処理
     * @param {String} 半角ハイフン(-)が入った文字列
     * @returns {String} 半角ハイフンが取り除かれた文字列
     */
    removeHyphen = (value) => {
        return value.replace(/-/g, "");
    }

    /**
     * ファイルサイズを読みやすい単位に変換して表示する処理
     * @param {Number} value バイト単位の容量
     * @param {Number} decimals 小数点以下の桁数
     * @param {Boolean} withUnit 単位を結合する場合はTrue、それ以外の場合はFalse(デフォルト：True)
     * @returns {String} 適切な単位に変換されたファイルサイズ
     */
    formatToByteString(value, decimals = 2) {
        if (value == null) {
            return "";
        }

        if (value === 0) {
            return "0バイト";
        }

        const k = 1024;
        const digits = decimals < 0 ? 0 : decimals;
        const suffix = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const size = Math.floor(Math.log(value) / Math.log(k));

        const result = parseFloat((value / Math.pow(k, size)).toFixed(digits)) + suffix[size];

        return result?.toString();
    }

    /**
     * 指定したミリ秒分待機する処理
     * @param {Number} time 待機時間（ミリ秒）
     * @returns Promiseオブジェクト
     */
    sleep(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    /**
     * Input要素の入力値を選択済みにする処理
     * @param {Element} element Input要素
     * @param {Number} delay 待機時間（ミリ秒、デフォルト=100）
     */
    async selectInputWithDelay(element, delay = 100) {
        await this.sleep(delay);
        element.select();
    }

    /**
     * FileObjectをBase64形式でエンコードする処理
     * @param {FileObject} fileObject ファイル
     * @returns {Promise<String>} Promiseオブジェクト（成功時：Base64形式でエンコードされた文字列）
     */
    encodeFileToBase64String(fileObject) {
        return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                const base64String = reader.result.split(',')[1];
                resolve(base64String);
              };
              reader.onerror = () => {
                reject(new Error("ファイルの変換に失敗しました"));
              };
              reader.readAsDataURL(fileObject);
        });
    }

    /**
     * 文字列を指定されたアルゴリズムによってハッシュ化する処理
     * @param {String} text 文字列
     * @param {String} algorithm ハッシュ化アルゴリズム（デフォルト：SHA-256）
     * @returns {String} 指定されたアルゴリズムでハッシュ化された文字列
     */
    async generateHashString(text, algorithm = 'SHA-256') {
        return Array.from(new Uint8Array(await crypto.subtle.digest(algorithm, new TextEncoder().encode(text))))
                    .map(value => value.toString(16).padStart(2, '0'))
                    .join('');
    }

    /**
     * 数値を指定された範囲内にとどめる処理
     * @param {Number} value 数値
     * @param {Number} min 最小値
     * @param {Number} max 最大値
     * @returns {Number} 指定された範囲内の数値
     */
    clamp(value, min, max) {
        if (value < min) {
            return min;
        } else if (value > max) {
            return max;
        } else {
            return value;
        }
    }

    /**
     * 文字列からカラーコードを生成する処理
     * @param {String} value 文字列
     * @param {Number} saturation 鮮やかさ（デフォルト：80）
     * @param {Number} lightness 明るさ（デフォルト：60）
     * @param {Number} step 増減値（デフォルト：20）
     * @returns {Object} 生成されたカラーコード
     */
    getColorFromCharacters(value, saturation = 80, lightness = 60, step = 20) {
        const num = Array.from(value).map(i => i.charCodeAt(0)).reduce((a,b) => a + b);
        const hue = (num * num) % 360;
        return {
          color: hue,
          primary: `hsl(${hue}, ${saturation}%, ${lightness}%)`,            // 60
          secondary: `hsl(${hue}, ${saturation}%, ${lightness - step}%)`,   // 40
          light: `hsl(${hue}, ${saturation}%, ${lightness + step}%)`,       // 80
          dark: `hsl(${hue}, ${saturation}%, ${lightness - (step * 2)}%)`,  // 20
        };
    }

    scrollToTop() {
        // 画面をページの先頭にスクロールする
        window.scrollTo({ top: 0 });
    }

    async getImageSize(file) {
        return new Promise((resolve, reject) => {
          const img = new Image();
       
          img.onload = () => {
            const size = {
              width: img.naturalWidth,
              height: img.naturalHeight,
            };
       
            URL.revokeObjectURL(img.src);
            resolve(size);
          };
       
          img.onerror = (error) => {
            reject(error);
          };
       
          img.src = URL.createObjectURL(file);
        });
      }
}

export default new Utilities();