<template>
  <div class="container-xl mx-auto">
    <!-- ローディングスピナー -->
    <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />
    <!-- フォーム -->
    <form @submit.prevent="validate" v-if="!status.success" class="mb-3" ref="formContainer">
      <div v-if="!signup.isConfirmed"><!-- 入力Div -->
        <h2>新規ご利用登録</h2>
        <p class="my-3">下記の項目を入力し、個人情報の取り扱いおよび利用規約についてをよくお読みいただいたうえでご利用登録を行ってください。<br>
           ご入力いただいたメールアドレスに仮登録メールをお送りします。<br>
           登録したメールアドレスが、アカウントIDとなります。</p>
        <p class="mb-1 small">※<span class="mx-1 badge bg-danger">必須</span>は必須項目です。必ず入力してください。</p>

        <!-- エラーメッセージ -->
        <div v-if="status.message" class="alert alert-danger" role="alert" v-html="status.message">
        </div>

        <table class="mb-3">
          <tr>
            <th>アカウントID（メールアドレス）<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <input type="email" class="wide" v-model="signup.data.accountId" maxlength="100" autocomplete="username" required>
              <div class="form-text">例：sample@xxxxx.jp（半角英数字でご入力ください）</div>
            </td>
          </tr>
          <tr>
            <th>氏名（漢字）<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <input type="text" v-model="signup.data.name" autocomplete="name" required>
              <div class="form-text">例：山田&nbsp;太郎</div>
            </td>
          </tr>
          <tr>
            <th>氏名（カナ）<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <input type="text" v-model="signup.data.nameKana" pattern="(?=.*?[\u30A1-\u30FC\uFF0E\uFF08\uFF09])[\u30A1-\u30FC\uFF0E\uFF08\uFF09\s]*" required>
              <div class="form-text">例：ヤマダ&nbsp;タロウ（全角でご入力ください）</div>
            </td>
          </tr>
          <tr>
            <th>お客様番号<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <input type="tel" class="wide" title="半角でご入力ください" pattern="^[a-zA-Z0-9]+$" v-model="signup.data.customerCode" required>
              <div class="form-text">半角でご入力ください</div>
            </td>
          </tr>
          <tr>
            <th>ユーザー登録時認証コード<span class="mx-1 badge bg-danger">必須</span></th>
            <td>
              <input type="text" class="wide" title="半角でご入力ください" pattern="[\x20-\x7E]*" v-model="signup.data.authCode" required>
              <div class="form-text">半角でご入力ください</div>
            </td>
          </tr>
        </table>
        <p class="mb-1"><strong>【個人情報の取り扱いについて】</strong></p>
        <ul>
          <li>帳票保管・閲覧クラウドhayabusaBanDをご利用される方は<a href="https://www.mbcnet.co.jp/pms/privacy.html" target="_blank" rel="noopener noreferrer">個人情報保護方針</a>および<a href="https://www.mbcnet.co.jp/isms/index.html" target="_blank" rel="noopener noreferrer">情報セキュリティ方針</a>をご確認のうえ、ご同意をお願いいたします。</li>
          <li>本フォームへのご入力は任意ですが、ご入力が無い場合は当サービスをご利用いただけません。</li>
          <li>本フォームにご入力いただきましたメールアドレス・氏名は、帳票保管・閲覧クラウドの提供（請求書発行のご連絡およびお問い合わせ対応）のために利用いたします。</li>
          <li>本フォームにより取得した個人情報は、個人情報保護方針および情報セキュリティ方針に沿って厳重に管理し、利用目的達成のための範囲を超えた利用は一切いたしません。</li>
          <li>ご本人様の自己の情報についての利用目的の通知・開示・追加・訂正・削除・利用の停止・消去に関するお問い合わせ先は、個人情報保護方針をご参照ください。</li>
        </ul>
        <p class="mb-1"><strong>【利用規約について】</strong></p>
        <ul>
          <li>帳票保管・閲覧クラウドhayabusaBanDをご利用される方は<a href="/tos" target="_blank" rel="noopener noreferrer">利用規約</a>をご確認のうえ、ご同意をお願いいたします。</li>
        </ul>
        <div class="text-center">
          <input type="checkbox" class="me-2" v-model="signup.isAgreement" id="agreement">
          <label for="agreement">個人情報の取り扱いおよび利用規約に同意する</label>
        </div>
        <button type="submit" class="button-navy" :disabled="!signup.isAgreement">入力内容を確認する</button>
        <router-link to="/signin" class="button">戻る</router-link>
      </div>
      <div v-else><!-- 確認Div -->
        <h2>新規ご利用登録確認</h2>
        <p class="my-3">入力項目をご確認ください。<br>
          正しければ「この内容で登録する」ボタンを押してください。
        </p>
        <table class="mb-3">
          <tr>
            <th>アカウントID（メールアドレス）</th>
            <td>{{ signup.data.accountId }}</td>
          </tr>
          <tr>
            <th>氏名（漢字）</th>
            <td>{{ signup.data.name }}</td>
          </tr>
          <tr>
            <th>氏名（カナ）</th>
            <td>{{ signup.data.nameKana }}</td>
          </tr>
          <tr>
            <th>お客様番号</th>
            <td>{{signup.data.customerCode }}</td>
          </tr>
          <tr>
            <th>ユーザー登録時認証コード</th>
            <td>{{ signup.data.authCode }}</td>
          </tr>
          <tr>
            <th>個人情報の取り扱いおよび利用規約の同意</th>
            <td>{{ signup.isAgreement ? "同意する" : "同意しない" }}</td>
          </tr>
        </table>

        <button type="button" class="button-navy" @click.prevent="doSignup" :disabled="!signup.isConfirmed || status.inprogress">この内容で登録する</button>
        <button type="button" class="button" @click="signup.isConfirmed = false">修正する</button>
      </div>
    </form>
    <div v-else><!-- 完了画面 -->
      <h2>仮登録完了</h2>
      <p>ご入力いただいたメールアドレスに仮登録メールを送信しました。<br>
        メールに記載されているURLを開き、本登録を完了してください。</p>
      <p style="font-weight:bold;">※本画面は閉じていただき、メールに記載されているURLより本登録を行ってください。</p>
    </div>
  </div>
</template>

<style scoped>
h2 {
  padding-bottom: 6px;
  color: #1e72b1;
  font-size: 20pt;
  border-bottom: 4px solid #1e72b1;
}

/* 入力フォーム */
form input:not([type="radio"]):not([type="checkbox"]):not([type="date"]) {
  padding: 0.25rem;
  background-color: #ffe6e6;
}

form input,
form select,
form textarea {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus,
form select:focus,
form textarea:focus,
input.indicate-focus:focus {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

form .validate input:invalid,
form .validate select:invalid,
form .validate textarea:invalid {
  color: var(--color-red);
  border: 1px solid var(--color-red);
  background-color: var(--background-error);
  box-shadow: 0 0 8px -4px var(--color-red);
  outline: none;
}

input.wide {
  width: 90%;
}

/* テーブル */
table {
  width: 100%;
  border: 1px solid #ccc;
}
table th {
  padding: 0.75rem 1.25rem;
  width: 30%;
  font-weight: normal;
  border-bottom: 1px solid #ccc;
  background-color: #eee;
}
table td {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #ccc;
}

/* 個人情報の取り扱いについて */
ul {
  padding: 0.75rem 1.75rem;
  border: 1px solid #ccc;
}

/* ボタン */
button,
.button {
  margin: 10px auto;
  padding: 0.4rem 0.6rem;
  width: 50%;
  color: var(--text-primary);
  text-align: center;
  text-decoration: none;
  border: none;
  background-color: var(--color-gray);
  box-shadow: 0 0 4px -3px rgba(0, 0, 0, 0.6);
  display: block;
  cursor: pointer;
}
button:hover,
.button:hover {
  background-color: var(--color-gray-lighten);
}
button:active,
.button:active {
  background-color: var(--color-gray-darken);
}
button:disabled,
.button:disabled {
  color: var(--text-secondary) !important;
  background-color: var(--color-gray) !important;
  cursor: not-allowed;
}
button.button-navy {
  color: var(--color-white);
  background-color: var(--color-navy);
}
button.button-navy:hover {
  background-color: var(--color-navy-lighten);
}
button.button-navy:active {
  background-color: var(--color-navy-darken);
}
</style>

<script>
import { defineComponent } from "vue";
import useSignup from "@/composable/signup";
import utilities from "@/services/utilities.js"
import Loading from 'vue-loading-overlay';

export default defineComponent({
  name: "Signup",
  components: {
    Loading
  },
  setup() {
    // 共通ユーティリティ読み込み
    const util = utilities;

    // signup.jsの関数を読み込み
    const { signup, status, doSignup } = useSignup();

    const validate = () => {
      // ブラウザによる入力有無チェックのみ実施
      signup.isConfirmed = true;
      // メッセージ初期化
      status.message = '';
    }

    return {
      util,             // ユーティリティ
      signup,           // 新規登録データ
      status,           // ステータス
      doSignup,         // 新規登録処理
      validate,         // 入力チェック処理
    };
  },
  mounted() {
    // reCAPTCHAバッジ表示
    setTimeout(() => {
      this.$recaptchaInstance.value.showBadge();
    }, 1000);
  },
  unmounted() {
    // reCAPTCHAバッジ非表示
    this.$recaptchaInstance.value.hideBadge();
  },
});
</script>
