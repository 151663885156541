<template>
  <!-- ローディングスピナー -->
  <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />
  <!-- ローディングスピナー ここまで -->
  <div class="m-3">
    <form @submit.prevent="updateMailAddress" class="align-items-center">
      <a href="#" class="arrow mobile-back d-sm-none mb-3" @click="$router.back()">戻る</a>
      <h2>
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16">
          <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z"/>
          <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
        </svg>
        連絡先メールアドレス変更
      </h2>
      <!-- エラーメッセージ -->
      <div v-if="status.message" v-bind:class="[status.isError ? 'alert-danger' : 'alert-success']" class="alert" role="alert">
        <span v-html="status.message"></span>
      </div>
      <!-- エラーメッセージ ここまで -->
      <div class="container">
        <p>
          請求書発行お知らせメールや、サービスからのお知らせメールの配信先メールアドレスを変更します。<br>
          新しいメールアドレスへの変更には、<strong>メールアドレス認証</strong>が必要です。
        </p>

        <div class="mx-2 mb-2 accordion" id="ChangeMailAddressFlow">
          <div class="accordion-item">
            <p class="accordion-header">
              <button type="button" class="m-0 p-2 accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#CollapsItem" aria-expanded="false" aria-controls="CollapsItem">
                変更手順詳細
              </button>
            </p>
            <div id="CollapsItem" class="accordion-collapse collapse" data-bs-parent="#ChangeMailAddressFlow">
              <div class="m-0 p-1 accordion-body">
                <ol class="m-1">
                  <li class="pb-1">
                    変更後の新しいメールアドレスを入力し、「メールアドレスの認証を行う」を押下してください。<br>
                    入力されたメールアドレスへ認証用URLを記載したメールを送信します。
                  </li>
                  <li class="pb-1">
                    受信したメールに記載されている認証用URLをクリック、またはウェブブラウザに入力し、アクセスします。
                  </li>
                  <li class="pb-1">
                    メールアドレスの認証が完了します。<br>
                    ※認証ステータスが「認証済み」に変化しない場合は、画面を再読み込みしてください。
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-6 col-sm-3">
            <label class="col-form-label">現在のメールアドレス</label>
          </div>
          <div class="col-12 col-sm-9">
            <input type="text" name="email" class="w-100" style="vertical-align: sub;" :value="$store.getters.getOwnInfo.mailAddress" autocomplete="username" tabindex="-1" readonly>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 col-sm-3">
            <label class="col-form-label">認証ステータス</label>
          </div>
          <div class="col-12 col-sm-9">
            <div v-if="verifyStatus == null" class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
            <p v-else-if="verifyStatus === ''" class="m-0 alert alert-success">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-1 bi bi-check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </svg>
              <strong>認証済み</strong> - メールアドレスの認証が完了しました。
            </p>
            <p v-else class="m-0 alert" :class="verifyStatus?.expiration > util.getToday('YYYY/MM/DD hh:mm:ss') ? 'alert-warning' : 'alert-danger'">
              <svg v-if="verifyStatus?.expiration > util.getToday('YYYY/MM/DD hh:mm:ss')" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="me-1 bi bi-hourglass-split" viewBox="0 0 16 16">
                <path d="M2.5 15a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1 0-1h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11zm2-13v1c0 .537.12 1.045.337 1.5h6.326c.216-.455.337-.963.337-1.5V2h-7zm3 6.35c0 .701-.478 1.236-1.011 1.492A3.5 3.5 0 0 0 4.5 13s.866-1.299 3-1.48V8.35zm1 0v3.17c2.134.181 3 1.48 3 1.48a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351z" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle" viewBox="0 0 16 16">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
              <span v-if="verifyStatus?.expiration > util.getToday('YYYY/MM/DD hh:mm:ss')">
                <strong>認証待ち</strong> - メールに記載のURLにアクセスし、認証を完了して下さい。
              </span>
              <span v-else>
                <strong>認証期限切れ</strong> - メールアドレス認証の有効期限が切れました。<br>
                再度、変更後のメールアドレスを入力し、「メールアドレスの認証を行う」を押下してください。
              </span>
              <br>
              <span>認証中メールアドレス：{{ verifyStatus.mailAddress }}</span>
            </p>
            <p v-if="verifyStatus" class="my-1">
              認証期限：{{ verifyStatus?.expiration }}
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 col-sm-3">
            <label class="col-form-label">新しいメールアドレス</label>
          </div>
          <div class="col-12 col-sm-6">
            <input type="email" class="form-control" name="new-email" v-model="mailAddress" maxlength="100" autocomplete="email" required>
          </div>
          <div class="d-none d-sm-block col-sm-3">
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-6 col-sm-3">
            <label class="col-form-label">新しいメールアドレス<span class="text-nowrap">（確認）</span></label>
          </div>
          <div class="col-12 col-sm-6">
            <input type="email" class="form-control" name="new-email-conf" v-model="mailAddressConfirm" maxlength="100" autocomplete="email" required>
          </div>
          <div class="d-none d-sm-block col-sm-3">
          </div>
        </div>
      </div><!-- container -->
      <div class="text-end">
        <button type="submit" class="btn btn-primary">メールアドレスの認証を行う</button>
      </div>
    </form>
  </div>
</template>

<style scoped>
/* 画面サイズ575px以下の時 */
@media (max-width: 575px) {
  /* 戻るボタン */
  .arrow{
	position: relative;
	display: inline-block;
	padding: 0 0 0 16px;
	color: #000;
	vertical-align: middle;
	text-decoration: none;
	font-size: 17px;
  border-bottom: 1px solid #696969;
}
.arrow::before,
.arrow::after{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	vertical-align: middle;
}
.mobile-back::before{
	box-sizing: border-box;
	width: 14px;
	height: 14px;
	border: 1px solid #696969;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.mobile-back::after{
	left: 5px;
	width: 6px;
	height: 6px;
	border-top: 1px solid #696969;
	border-right: 1px solid #696969;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
}
h2 {
  padding-bottom: 8px;
  color: var(--text-primary);
  font-size: 1.25rem;
  text-indent: 2px;
  border-bottom: 2px solid var(--theme-primary);
}

h2 svg {
  vertical-align: sub;
}

button.btn {
  padding: 2px 8px;
}

/* 入力フォーム */
form input:not(:read-only),
form select:not(:read-only),
form textarea:not(:read-only) {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus:not(:read-only),
form select:focus:not(:read-only),
form textarea:focus:not(:read-only),
input.indicate-focus:focus:not(:read-only) {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

form input:read-only {
  border: none;
  background-color: transparent !important;
  outline: none;
}
</style>

<script>
import { defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import Loading from "vue-loading-overlay";
import utilities from "@/services/utilities"
import backend from "@/services/backendApi";

export default defineComponent({
  name: "ChangeMailAddress",
  components: {
    Loading
  },
  setup() {
    /** vuex::store */
    const store = useStore();

    /** 共通ユーティリティ読み込み */
    const util = utilities;

    /** 処理ステータス */
    const status = reactive({
      inprogress: false,  // 処理状況
      message: "",        // 結果メッセージ
      isError: false,     // エラーか否か
      executed: false,    // 実行済みか否か
    });

    /** メールアドレス変更対象アカウントの取得 */
    const mailAddress = ref("");          // 新しいメールアドレス
    const mailAddressConfirm = ref("");   // 確認用

    /** メールアドレス認証ステータス */
    const verifyStatus = ref();

    /** メールアドレス変更処理 */
    const updateMailAddress = () => {
      // ステータスを初期化し、処理中に変更
      status.inprogress = true;
      status.message = "";
      status.isError = false;

      // メールアドレス入力一致チェック
      if (mailAddress.value !== mailAddressConfirm.value) {
        // 不一致
        status.inprogress = false;
        status.message = "新しいメールアドレスと確認用メールアドレスが一致しません";
        status.isError = true;
        return;
      }

      // 新旧メールアドレス一致チェック
      if (mailAddress.value === store.getters.getOwnInfo.mailAddress) {
        // 一致
        status.inprogress = false;
        status.message = "現在のメールアドレスと同一のメールアドレスへは変更できません";
        status.isError = true;
        return;
      }

      // API呼び出し
      backend.requestChangeMailAddress(mailAddress.value)
        .then(async () => {
          // 成功
          // 実行済に変更
          status.executed = true;

          // 更新成功 メッセージ表示
          store.dispatch("addNotification", {
            id: "updateMailAddress",
            title: "メールアドレス変更",
            message: "メールアドレス変更の要求を行いました。引き続き、メールアドレス認証を行ってください。",
            type: "success",
            autoClose: true
          });

          initialize();

          // 入力値クリア
          mailAddress.value = "";
          mailAddressConfirm.value = "";
        })
        .catch(error => {
          // 失敗
          status.message = error.message;
          status.isError = true;

          store.dispatch("addNotification", {
            id: "updateMailAddress",
            title: "メールアドレス変更",
            message: "メールアドレス変更時にエラーが発生しました。",
            type: "danger"
          });

        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
        });
    }

    /** 初期化処理 */
    const initialize = async () => {
      status.inprogress = true;

      // 既存の通知を削除
      store.dispatch("removeNotification", "getMailAddressStatus");

      // メールアドレス認証状況取得
      await backend.getMailAddressStatus().then((response) => {
        if (response.status === 204) {
          verifyStatus.value = "";
        } else {
          verifyStatus.value = response.data.content;
        }
      }).catch(error => {
        // エラー通知
        store.dispatch("addNotification", {
          id: "getMailAddressStatus",
          title: "メールアドレス認証状況取得 失敗",
          message: error.message,
          type: "danger"
        });
      });

      status.inprogress = false;
    }

    // 初期化実行
    initialize();

    return {
      util,                                         // ユーティリティ
      status,                                       // ステータス
      verifyStatus,                                 // メールアドレス認証状況
      mailAddress,                                  // 新しいメールアドレス
      mailAddressConfirm,                           // 新しいメールアドレス（確認）
      updateMailAddress,                            // メールアドレス変更処理関数
    };
  }
});
</script>
