<template>
  <!-- ローディングスピナー -->
  <Loading v-model:active="status.inprogress" :can-cancel="false" color="var(--color-blue)" />
  <!-- ローディングスピナー ここまで -->
  <div class="m-3">
    <a href="#" class="arrow mobile-back d-sm-none mb-3" @click="$router.back()">戻る</a>
    <h2>
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-buildings" viewBox="0 0 16 16">
        <path d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022ZM6 8.694 1 10.36V15h5V8.694ZM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15Z"/>
        <path d="M2 11h1v1H2v-1Zm2 0h1v1H4v-1Zm-2 2h1v1H2v-1Zm2 0h1v1H4v-1Zm4-4h1v1H8V9Zm2 0h1v1h-1V9Zm-2 2h1v1H8v-1Zm2 0h1v1h-1v-1Zm2-2h1v1h-1V9Zm0 2h1v1h-1v-1ZM8 7h1v1H8V7Zm2 0h1v1h-1V7Zm2 0h1v1h-1V7ZM8 5h1v1H8V5Zm2 0h1v1h-1V5Zm2 0h1v1h-1V5Zm0-2h1v1h-1V3Z"/>
      </svg>
      閲覧先追加・削除
    </h2>
    <!-- エラーメッセージ -->
    <div v-if="status.message" v-bind:class="[status.isError ? 'alert-danger' : 'alert-success']" class="alert text-center" role="alert">
      {{ status.message }}
    </div>
    <!-- エラーメッセージ ここまで -->
    <div class="container">
      <h3>追加済み閲覧先({{ assignedCustomers.length ?? 0 }}件)</h3>
      <div class="border">
        <div class="p-2">
          <p class="mt-2">追加する閲覧先情報を入力してください。</p>
        <form @submit.prevent="searchSignupMethod" class="align-items-center">
          <div class="row mb-2">
            <div class="col-sm-3">
              <label class="col-form-label">発行元テナントコード</label>
            </div>
            <div class="col-sm-5">
              <input type="text" class="form-control" v-model="tenantCode" readonly>
            </div>
            <div class="col-sm-4">
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-3">
              <label class="col-form-label">追加するサービスコード</label>
            </div>
            <div class="col-sm-5">
              <input type="text" class="form-control" v-model="serviceCode" required>
            </div>
            <div class="col-sm-4">
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-sm-3">
              <label class="col-form-label">サービス区分</label>
            </div>
            <div class="col-sm-5">
              <input type="number" class="form-control w-25" min="1" max="1" v-model="serviceType" readonly>
            </div>
            <div class="col-sm-4">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 text-end">
              <button type="submit" class="btn btn-primary">登 録</button>
            </div>
          </div>
        </form>
        </div>
        
      </div>
      <div class="accordion accordion-flush border mt-3" id="AssignedCustomer">
        <div class="accordion-item">
          <h4 class="accordion-header" id="AddNew">
            <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#AddNewForm" aria-expanded="false" aria-controls="AddNewForm" @click="searchAssignCustomer">
              <span class="fw-bold">請求書受領済テナント情報</span>
            </button>
          </h4>
          <div id="AddNewForm" class="accordion-collapse collapse" aria-labelledby="AddNew" data-bs-parent="#AssignedCustomer">
            <div class="accordion-body">
              <div class="table-responsive table-frame border-top">
                <table id="customerTable" class="table table-striped table-sticky table-hover text-nowrap">
                  <thead>
                    <tr>
                      <th class="text-center">No</th>
                      <th></th>
                      <th colspan="2" class="text-center">発行元テナント</th>
                      <th colspan="2" class="text-center">送付先テナント</th>
                      <th class="text-center">サービス区分</th>
                      <th class="text-center">サービスコード</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in userAssignInfo" :key="index">
                      <!-- No -->
                      <td class="text-end pe-1">
                        {{ index + 1 }}
                      </td>
                      <td class="text-center">
                        <span v-if="item.customerCode === $store.getters.getOwnInfo.tenantCode" class="ms-2 badge bg-white text-dark border">
                          メイン
                        </span>
                      </td>
                      <td>{{ item?.tenantCode }}</td>
                      <td>{{ item?.tenantName }}</td>
                      <td>{{ item?.customerCode }}</td>
                      <td>{{ item?.customerName }}</td>
                      <td>{{ item?.serviceType }}</td>
                      <td>{{ item?.serviceCode }}</td>
                      <td class="text-center">
                        <button
                          class="ms-auto me-2 btn"
                          v-bind:class="[item.customerCode === $store.getters.getOwnInfo.tenantCode ? 'btn-secondary' : 'btn-danger']"
                          type="button"
                          :disabled="item.customerCode === $store.getters.getOwnInfo.tenantCode"
                          :title="[item.customerCode === $store.getters.getOwnInfo.tenantCode ? 'メインの閲覧先は削除できません' : '']"
                          @click="confirmUnAssign(item.tenantCode, item.customerCode, item.customerName, item.serviceType, item.serviceCode)"
                        >
                          削除
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div><!-- accordion-body -->
          </div>
        </div><!-- accordion-item -->
      </div>
    </div><!--container -->
  </div>

  <!-- 閲覧先を削除する確認ダイアログ -->
  <ConfirmDialog id="UnAssignConfirmDialog"
    title="閲覧先削除"
    :content="unAssignConfirmMessage"
    buttons="YesNo"
    :positiveAction="doUnAssignCustomer"
    :canCancelable="true"
    :isDangerousOperation="true"
  />

  <!-- ユーザー登録時認証コード入力ダイアログ -->
  <MultiDialog
    ref="inputDialog"
    id="InputAuthCodeDialog"
    title="認証コード確認"
    buttons="OKCancel"
    positiveString="送信"
    cancelString="戻る"
    :canCancelable="true"
  >
    <p>お客様番号発行通知書に記載の<span class="fw-bold text-success">『ユーザー登録時認証コード』</span>を入力してください。</p>
    <label class="col-form-label">ユーザー登録時認証コード</label>
    <input type="text" class="form-control" v-model="authCode" required>
  </MultiDialog>

  <!-- ユーザー承認方式の際に表示するダイアログ -->
  <MultiDialog
    ref="confirmDialog"
    id="UserApprovalConfirmDialog"
    title="閲覧先追加申請"
    buttons="OKCancel"
    positiveString="閲覧先追加の申請"
    cancelString="戻る"
    :canCancelable="true"
  >
    <p>以下の項目を入力し、<span class="fw-bold text-success">「閲覧先追加の申請」</span>ボタンを押下してください。<br>
    申請完了後、承認作業が行われます。<br>
    ご入力いただ内容は、発行元テナントによる承認作業時のお客様情報確認の際に使用されます。</p>
    <label class="fw-bold">発行元テナント名：{{parentTenantSignUpMode?.tenantName}}</label>
    <label class="col-form-label">請求書記載のお客様会社名（法人の場合）または氏名（個人の場合）</label>
    <input type="text" class="form-control" v-model="userTenantName" required>
    <label class="col-form-label">ご利用者氏名</label>
    <input type="text" class="form-control" v-model="userName" required>
  </MultiDialog>
</template>

<style scoped>
/* 画面サイズ576px以下の時 */
@media (max-width: 576px) {
  /* 戻るボタン */
  .arrow{
	position: relative;
	display: inline-block;
	padding: 0 0 0 16px;
	color: #000;
	vertical-align: middle;
	text-decoration: none;
	font-size: 17px;
  border-bottom: 1px solid #696969;
}
.arrow::before,
.arrow::after{
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	content: "";
	vertical-align: middle;
}
.mobile-back::before{
	box-sizing: border-box;
	width: 14px;
	height: 14px;
	border: 1px solid #696969;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}
.mobile-back::after{
	left: 5px;
	width: 6px;
	height: 6px;
	border-top: 1px solid #696969;
	border-right: 1px solid #696969;
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}
}
h2 {
  padding-bottom: 8px;
  color: var(--text-primary);
  font-size: 1.25rem;
  text-indent: 2px;
  border-bottom: 2px solid var(--theme-primary);
}

h2 svg {
  vertical-align: sub;
}

h3 {
  font-size: 1rem;
}

button.btn {
  padding: 2px 8px;
}

/** 閲覧先一覧 */
ul {
  margin: 0;
  padding: 0;
  border-style: solid;
  border-width: 0 1px 0 1px;
  border-color: var(--border-gray);
}
li {
  padding: 10px;
  align-items: center;
  border-top: 1px solid var(--border-gray);
  display: flex;
}

/* アコーディオンボディー */
div.accordion-body {
  padding: 10px 20px;
}

/* 入力フォーム */
form input,
form select,
form textarea {
  color: var(--text-primary);
  font-family: var(--font-family) !important;
  font-size: 100%;
  border: 1px solid var(--border-gray);
  background-color: var(--background-primary);
}

form select option {
  color: var(--text-primary);
  background-color: var(--background-primary);
}

form input:focus,
form select:focus,
form textarea:focus,
input.indicate-focus:focus {
  border: 1px solid var(--color-blue);
  background-color: var(--hover) !important;
  box-shadow: 0 0 8px -4px var(--color-blue);
  outline: none;
}

/* 不活性ボタンのスタイル */
button.btn[disabled] {
    pointer-events: unset;
    cursor: not-allowed;
}

</style>

<script>
import { computed, defineComponent, reactive, ref } from "vue";
import { useStore } from "vuex";
import { Modal } from "bootstrap/dist/js/bootstrap.min.js";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import MultiDialog from "@/components/MultiDialog.vue";
import Loading from "vue-loading-overlay";
import useCustomer from "@/composable/customer";
import useUser from "@/composable/user";
import useSignupApprovalData from "@/composable/signupApprovalData";
import utilities from "@/services/utilities";
import backend from "@/services/backendApi";
export default defineComponent({
  name: "AssignCustomer",
  components: {
    Loading,
    ConfirmDialog,
    MultiDialog,
  },
  setup() {
    // vuex::store
    const store = useStore();
    
    // 共通ユーティリティ読み込み
    const util = utilities;

    // customer.jsの関数を読み込み
    const { callerPages, getSignupMethod } = useCustomer();

    // user.jsの関数を読み込み
    const { listAccessibleTenant } = useUser();

    /** signupApprovalData.jsの関数を読み込み */
    const { signupApprovalDataObject, requestSignupApproval } = useSignupApprovalData();

    // 処理ステータス
    const status = reactive({
      inprogress: false,  // 処理状況
      message: "",        // 結果メッセージ
      isError: false,     // エラーか否か
      executed: false,    // 実行済みか否か
    });

    // 得意先追加対象アカウントの取得
    const tenantCode = ref("0760100000000");      // テナントコード(発行元)
    const customerCode = ref("");                 // 得意先コード
    const serviceCode = ref("");                  // サービス連携コード
    const authCode = ref("");                     // ユーザー登録時認証コード
    const serviceType = ref("1");                   // 外部サービス区分

    // ユーザー承認方式時入力項目
    const userName = ref(store.getters.getOwnInfo.name)                 // お客様名
    const userTenantName = ref(store.getters.getOwnInfo.tenantName)     // お客様が所属する会社名

    const parentTenantSignUpMode = ref();  // 親テナントのサインアップ方式

    const userAssignInfo = ref();          // ユーザーの閲覧先情報

    /** 確認ダイアログオブジェクト */
    const confirmDialog = ref();

    /** 入力ダイアログオブジェクト */
    const inputDialog = ref();

    /** 追加済み閲覧先一覧 */
    const assignedCustomers = computed(() => {
      const own = store.getters.getAssignedCustomers?.find(
        i => i.customerCode === store.getters.getOwnInfo.tenantCode
      ) ?? [];

      const other = store.getters.getAssignedCustomers?.filter(
        i => i.customerCode !== store.getters.getOwnInfo.tenantCode
      ) ?? [];

      return [].concat(own, other);
    });

    // （認証コード方式時）ロールアクセスコントロール追加処理
    const assignNewCustomer = () => {
      // ステータスを処理中に変更
      status.inprogress = true;
      
      // 通知クリア
      store.dispatch("removeNotification", "assignNewCustomer");

      // API呼び出し
      backend.assignNewCustomer(store.getters.getOwnInfo.accountId, tenantCode.value, serviceCode.value, authCode.value, serviceType.value)
        .then(async response => {
          // 成功
          // 実行済に変更
          status.executed = true;

          // store更新
          store.dispatch('saveAssignedCustomers', response.data.contents);

          // 更新成功 メッセージ表示
          store.dispatch("addNotification", {
            id: "assignNewCustomer",
            title: "閲覧先追加成功",
            message: response.data.message,
            type: "success",
            autoClose: true
          });

          // 入力値クリア
          tenantCode.value = "";
          serviceCode.value = "";
          authCode.value = "";
          serviceType.value = "1";
        })
        .catch(error => {
          // 失敗
          store.dispatch("addNotification", {
            id: "assignNewCustomer",
            title: "閲覧先追加失敗",
            message: error.message,
            type: "danger"
          });

        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
        });
    }

    // （ユーザー承認時）ロールアクセスコントロール追加処理
    const requestNewCustomerApproval = async () => {
      // ステータスを処理中に変更
      status.inprogress = true;

      signupApprovalDataObject.tenantCode = tenantCode.value;
      signupApprovalDataObject.serviceType = serviceType.value;
      signupApprovalDataObject.serviceCode = serviceCode.value;
      signupApprovalDataObject.accountId = store.getters.getOwnInfo.accountId;
      signupApprovalDataObject.name = userName.value;
      signupApprovalDataObject.nameKana = store.getters.getOwnInfo.nameKana;
      signupApprovalDataObject.companyName = userTenantName.value;
      signupApprovalDataObject.callerPage = callerPages.閲覧先管理画面;
      
      // 通知クリア
      store.dispatch("removeNotification", "requestSignupApproval");

      // API呼び出し
      await requestSignupApproval(signupApprovalDataObject)
      .then(response => {
          // 削除成功 メッセージ表示
          store.dispatch("addNotification", {
            id: "requestSignupApproval",
            title: `閲覧先追加承認要求を受け付けました。`,
            message: response.data.message,
            type: "success",
            autoClose: true
          });

          // 入力値クリア
          tenantCode.value = "";
          serviceCode.value = "";
          authCode.value = "";
          serviceType.value = "1";
          userName.value = store.getters.getOwnInfo.name;
          userTenantName.value = store.getters.getOwnInfo.tenantName;

        }).catch(error => {
          // エラー通知
          store.dispatch("addNotification", {
            id: "requestSignupApproval",
            title: `閲覧先追加承認要求が失敗しました。`,
            message: error.message,
            type: "danger"
          });
        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
        });
    }

    const unTenantCode = ref("");     // 削除用テナントコード（発行元）
    const unCustomerCode = ref("");   // 削除用得意先コード
    const unServiceType = ref(0);      // 削除用サービス区分
    const unServiceCode = ref("");    // 削除用サービスコード

    /** 閲覧先を削除する 確認ダイアログ表示処理 */
    const confirmUnAssign = (unTenCd, unCustCd, unCustName, unSerTp, unSerCd) => {
      unTenantCode.value = unTenCd;
      unCustomerCode.value = unCustCd;
      unServiceType.value = unSerTp;
      unServiceCode.value = unSerCd;

      // 確認メッセージ整形
      const msg = `閲覧先を削除すると、請求書の閲覧ができなくなります。\n以下の閲覧先を削除してもよろしいですか？\n\n`
                + `お客様番号：${unCustCd}\nお客様名称：${unCustName}`;
      unAssignConfirmMessage.value = msg;
      // 確認ダイアログを表示
      new Modal(document.getElementById("UnAssignConfirmDialog")).show();
    }

    /** 閲覧先を削除する 確認ダイアログメッセージ */
    const unAssignConfirmMessage = ref("閲覧先削除");

    // ロールアクセスコントロール削除処理
    const doUnAssignCustomer = () => {
      // ステータスを処理中に変更
      status.inprogress = true;

      // API呼び出し
      backend.unassigncustomer(store.getters.getOwnInfo.accountId, unTenantCode.value, unCustomerCode.value, unServiceType.value, unServiceCode.value)
        .then(async response => {
          // 成功
          // 実行済に変更
          status.executed = true;

          // store更新(洗い替え)
          store.dispatch('saveAssignedCustomers', response.data.contents);

          // 更新成功 メッセージ表示
          store.dispatch("addNotification", {
            id: "unassigncustomer",
            title: "閲覧先削除成功",
            message: response.data.message,
            type: "success",
            autoClose: true
          });

          // 閲覧先一覧を取得
          await listAccessibleTenant().then((response) => {
            // データをセット
            userAssignInfo.value = response.data.contents;

          }).catch((error) => {
            console.error(error);

            // エラー通知を表示する
            store.dispatch("addNotification", {
              id: "assignCustomerList",
              title: "閲覧先一覧取得失敗",
              message: "閲覧先一覧を正しく取得出来ないため処理を続行できません。"
                        + "画面を再読み込みしてください。（"
                        + error.message + "）",
              type: "danger"
            });
          });

        }).catch(error => {
          // 失敗
          store.dispatch("addNotification", {
            id: "unassigncustomer",
            title: "閲覧先削除失敗",
            message: error.message,
            type: "danger"
          });
        }).finally(() => {
          // 処理中状態OFF
          status.inprogress = false;
          unCustomerCode.value = "";
          unTenantCode.value = "";
          unServiceType.value = 0;
          unServiceCode.value = "";
        });
    }

    // 親テナントのサインアップ方式を取得する
    const searchSignupMethod = async () => {
      // 「発行元テナントコード」が未入力の場合、何もせずに終了
      if (tenantCode.value === null) {
        return;
      }
      // 親テナントのサインアップ方式を取得
      await getSignupMethod(tenantCode.value, serviceType.value, true, false).then((response) => {
        // データをセット
        parentTenantSignUpMode.value = response.data;

      }).catch((error) => {
        console.error(error);

        // エラー通知を表示する
        store.dispatch("addNotification", {
          id: "getSignupMethod",
          title: "サインアップ方式取得失敗",
          message: "テナントのサインアップ方式を正しく取得出来ないため処理を続行できません。"
                    + "画面を再読み込みしてください。（"
                    + error.message + "）",
          type: "danger"
        });
      });

      // サインアップ方式（1:認証コード方式、2:ユーザー承認方式）
      if (parentTenantSignUpMode.value.contents === 1) {

        // 入力ダイアログを表示し、戻り値を取得する
        const confirmResult = await inputDialog.value.getResult(true);

        // 戻り値がYes以外の場合は処理を終了する
        if (confirmResult !== 1) {
          return;

        } else {
          // 戻り値がYesの場合はロールアクセスコントロール追加処理を行う
          assignNewCustomer();
        }
      } else if (parentTenantSignUpMode.value.contents === 2) {

        // ユーザー承認方式の際に表示するダイアログを表示し、戻り値を取得する
        const confirmResult = await confirmDialog.value.getResult(true);

        // 戻り値がYes以外の場合は処理を終了する
        if (confirmResult !== 1) {
          return;

        } else {
          requestNewCustomerApproval();
        }
      }

      signupApprovalDataObject.tenantCode = "",             // テナントコード（発行元）
      signupApprovalDataObject.serviceType = "1",             // サービス区分
      signupApprovalDataObject.serviceCode = "",            // サービス連携コード
      signupApprovalDataObject.accountId = "",              // アカウントID
      signupApprovalDataObject.name = "",                   // お客様名
      signupApprovalDataObject.nameKana = "",               // お客様名カナ
      signupApprovalDataObject.companyName = ""             // お客様会社名
      signupApprovalDataObject.recaptchaToken = "";         // reCAPTCHAトークン

    }

    // 閲覧先一覧取得
    const searchAssignCustomer = async () => {
      // 閲覧先一覧を取得
      await listAccessibleTenant().then((response) => {
        // データをセット
        userAssignInfo.value = response.data.contents;

      }).catch((error) => {
        console.error(error);

        // エラー通知を表示する
        store.dispatch("addNotification", {
          id: "assignCustomerList",
          title: "閲覧先一覧取得失敗",
          message: "閲覧先一覧を正しく取得出来ないため処理を続行できません。"
                    + "画面を再読み込みしてください。（"
                    + error.message + "）",
          type: "danger"
        });
      });
    }

    return {
      util,                     // ユーティリティ
      status,                   // ステータス
      tenantCode,               // テナントコード（発行元）
      serviceCode,              // サービス連携コード（お客様入力コード）
      customerCode,             // ロールアクセスコントロール追加対象得意先コード
      authCode,                 // 登録時認証コード
      serviceType,              // サービス区分
      userName,                 // お客様名
      userTenantName,           // お客様が所属する会社名
      assignedCustomers,        // 追加済み閲覧先一覧
      unTenantCode,             // ロールアクセスコントロール削除対象テナントコード（発行元）
      unCustomerCode,           // ロールアクセスコントロール削除対象得意先コード
      unServiceType,            // ロールアクセスコントロール削除対象サービス区分
      unServiceCode,            // ロールアクセスコントロール削除対象サービスコード
      unAssignConfirmMessage,   // 閲覧先を削除する表示メッセージ
      parentTenantSignUpMode,   // 親テナントのサインアップ方式
      userAssignInfo,           // ユーザーの閲覧先一覧情報
      confirmDialog,            // 確認ダイアログ
      inputDialog,              // 入力ダイアログ
      assignNewCustomer,        // ロールアクセスコントロール得意先追加処理関数
      doUnAssignCustomer,       // 閲覧先を削除する処理
      confirmUnAssign,          // 閲覧先を削除する確認処理
      searchSignupMethod,       // サインアップ方式取得処理
      requestNewCustomerApproval,
      searchAssignCustomer,     // 閲覧先一覧取得処理
    };
  }
});
</script>
