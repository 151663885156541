import { computed, reactive } from 'vue';
import backend from '@/services/backendApi';
import util from '@/services/utilities';
import store from '@/store/index';

/**
 * サインアップ承認データ関連
 */
const useSignupApprovalData = () => {
    const signupApprovalData = reactive({
        data: [],               // サインアップ承認データ配列(生データ)
        length: computed(() => signupApprovalData.data.length),
        status: {
            get: {                          // 取得状況
                loaded: false,              // 取得済か否か
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            add: {                          // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            update: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
            delete: {                       // 追加状況
                inprogress: false,          // 処理状況
                success: false,             // 処理結果
                message: '',                // メッセージ
            },
        }
    });

    /** 新規追加用オブジェクト */
    const signupApprovalDataObject = reactive({
        tenantCode: "",             // テナントコード（発行元）
        serviceType: 0,             // サービス区分
        serviceCode: "",            // サービス連携コード
        accountId: "",              // アカウントID
        name: "",                   // お客様名
        nameKana: "",               // お客様名カナ
        companyName: "",            // お客様会社名
        password: "",               // パスワード
        tagIds: [],                 // 発行タグ
        comment: "",                // コメント
        callerPage: 0,              // 呼び出し元画面（サインアップ／閲覧先追加）
    });

    /**
     * サインアップ承認データ取得
     * @param {String} tenantCode テナントコード
     * @param {Number} approvalState 承認状況
     * @returns {Promise} Promiseオブジェクト
     */
    const getSignupApprovalData = async (tenantCode, approvalState = 0) => {
        // メッセージ初期化、処理中フラグON
        signupApprovalData.status.get.message = "";
        signupApprovalData.status.get.inprogress = true;

        // データクリア
        clearSignupApprovalDataList();

        return await backend.getSignupApprovalData(tenantCode, approvalState)
        .then(response => {
            // 処理成功
            if (response.status !== 204) {
                signupApprovalData.data = response.data.contents;

                // 承認状況、申請日時の降順にソート
                signupApprovalData.data = util.objectMultiSort(response.data.contents, ["approvalState", "applicationDateTime"], 1);

                signupApprovalData.status.get.success = true;
            }

            return Promise.resolve(response);
        }).catch(error => {
            // 処理失敗
            console.error(error);

            signupApprovalData.status.get.success = false;
            signupApprovalData.status.get.message = error.message;

            return Promise.reject(error);
        }).finally(() => {
            // 処理終了
            signupApprovalData.status.get.loaded = true;
            signupApprovalData.status.get.inprogress = false;
        });
    }

    /**
     * サインアップ承認データ追加処理
     * @param {Object} signupApprovalDataObject サインアップ承認データオブジェクト
     * @returns {Promise} Promiseオブジェクト
     */
    const requestSignupApproval = async (signupApprovalDataObject) => {
        // メッセージ初期化・処理フラグON
        signupApprovalData.status.add.message = "";
        signupApprovalData.status.add.inprogress = true;

        return await backend.requestSignupApproval(signupApprovalDataObject)
            .then(response => {
                // 処理成功
                signupApprovalData.status.add.success = true;
                signupApprovalData.status.add.message = response.data.message;

                // キャッシュクリア
                store.dispatch('clearCache', "signupApprovalData");

                return Promise.resolve(response);
            }).catch(error => {
                console.error(error);
                // 処理失敗
                signupApprovalData.status.add.success = false;
                signupApprovalData.status.add.message = error.message;

                return Promise.reject(error);
            }).finally(() => {
                // 処理終了
                signupApprovalData.status.add.inprogress = false;
            });
    }

    /**
     * サインアップ承認・否認処理
     * @param {Object} signupApprovalDataObject サインアップ承認データオブジェクト
     * @param {Number} action アクション（2=承認、9=否認）
     * @returns {Promise} Promiseオブジェクト
     */
    const approveSignupUser = async (signupApprovalDataObject, action) => {
        return backend.approveSignupUser(signupApprovalDataObject, action)
            .then(response => {
                return Promise.resolve(response);
            }).catch(error => {
                return Promise.reject(error);
            });
    }

    /**
     * サインアップ承認データ配列クリア処理
     */
    const clearSignupApprovalDataList = async () => {
        signupApprovalData.data = [];
        signupApprovalData.status.get.loaded = false;
        signupApprovalData.status.get.success = false;
    }

    return {
        signupApprovalData,
        signupApprovalDataObject,            // サインアップ承認データオブジェクト
        getSignupApprovalData,               // サインアップ承認データ取得処理
        requestSignupApproval,               // サインアップ承認データ追加処理
        approveSignupUser,                   // サインアップ承認・否認処理
        clearSignupApprovalDataList,         // サインアップ承認データ配列クリア処理
    };
};

export default useSignupApprovalData;