import apiClient from "@/axios-common";
import util from "@/services/utilities"

class BackendApi {

  /** 呼び出し元画面ID */
  page = {
    evidence: 1,
    agent: 4
  }

  /**
   * サインイン処理
   * @param {String} accountId アカウントID
   * @param {String} password パスワード
   * @param {String} recaptchaToken reCAPTCHAトークン
   * @returns {Object} レスポンスオブジェクト
   */
  doAuthenticate(accountId, password, recaptchaToken) {
    return apiClient.post("authentication", {
      accountId,
      password,
      recaptchaToken
    });
  }

  /**
   * サインアウト処理
   * @returns {Object} レスポンスオブジェクト
   */
  doSignout() {
    return apiClient.get("cleartoken");
  }

  /**
   * 新規ご利用登録
   * @param {Object} user 新規登録に必要なユーザー情報
   * @returns {Object} レスポンスオブジェクト
   */
  doSignup(user) {
    return apiClient.post("signup", user);
  }

  /**
   * メールアドレス認証処理
   * @param {String} accountId アカウントID（メールアドレス）
   * @param {String} hash ハッシュ値
   * @param {String} recaptchaToken reCAPTCHAトークン
   * @returns {Object} レスポンスオブジェクト
   */
  doVerifyMailAddress(accountId, hash, recaptchaToken) {
    return apiClient.post("verifymailaddress", {
      accountId,
      hash,
      recaptchaToken
    });
  }

  /**
   * パスワード要件取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  getPasswordRule() {
    return apiClient.get("getpasswordrule");
  }

  /**
   * パスワード変更要求処理
   * @param {String} accountId アカウントID
   * @param {String} recaptchaToken reCAPTCHAトークン
   * @returns {Object} レスポンスオブジェクト
   */
  requestChangePassword(accountId, recaptchaToken) {
    return apiClient.post("changepasswordrequest", {
      accountId,
      recaptchaToken
    });
  }

  /**
   * パスワード変更（パスワード再設定）処理
   * @param {String} reason リセット理由(regist=新規登録, forgot=パスワード忘却)
   * @param {String} accountId アカウントID
   * @param {String} resetPassword 新しいパスワード
   * @param {String} urlHash ハッシュ文字列
   * @param {String} codeOrPassword セキュリティコード、または古いパスワード
   * @param {String} recaptchaToken reCAPTCHAトークン
   * @param {String} serviceType サービス区分（新規登録時のみ指定）
   * @returns {Object} レスポンスオブジェクト
   */
  changePassword(reason, accountId, resetPassword, urlHash, codeOrPassword, recaptchaToken, serviceType = null) {
    // リクエストパラメータをオブジェクト化
    const payload = {
      action: reason,
      accountId,
      resetPassword,
      recaptchaToken
    };

    // リセット理由で必要なパラメータを判別
    switch (reason) {
      case "regist":
        if (!codeOrPassword) {
          throw new Error("仮パスワードが必要です");
        }

        if (serviceType == null) {
          throw new Error("URLが正しくありません（不正なサービス区分）");
        }

        payload.urlHash = urlHash;
        payload.temporaryPassword = codeOrPassword;
        payload.serviceType = serviceType;
        break;

      case "forgot":
        if (!codeOrPassword) {
          throw new Error("セキュリティコードが必要です");
        }
        payload.urlHash = urlHash;
        payload.securityCode = codeOrPassword;
        break;

      case "required":
        if (!codeOrPassword) {
          throw new Error("現在のパスワードが必要です");
        }
        payload.currentPassword = codeOrPassword;
        break;
    }

    return apiClient.post("changepassword", payload);
  }

  /**
   * パスワード変更処理
   * @param {String} accountId アカウントID
   * @param {String} password 新しいパスワード
   * @returns {Object} レスポンスオブジェクト
   */
  updatePassword(accountId, password) {
    // リクエストパラメータをオブジェクト化
    const payload = {
      accountId,
      password,
    };

    return apiClient.post("updatepassword", payload);
  }

  /**
   * パスワードリセット処理（管理者メンテナンス画面のみ）
   * @param {String} accountId リセットするユーザーのアカウントID
   * @returns {Object} レスポンスオブジェクト
   */
  resetPassword(accountId) {
    return apiClient.post("resetpassword", { accountId });
  }

  /**
   * メールアドレス認証状況取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  getMailAddressStatus() {
    return apiClient.get("getmailaddressstatus");
  }

  /**
   * 連絡先メールアドレス変更要求処理
   * @param {String} mailAddress メールアドレス
   * @returns {Object} レスポンスオブジェクト
   */
  requestChangeMailAddress(mailAddress) {
    return apiClient.post("changemailaddressrequest", {
      mailAddress
    });
  }

  /**
   * 連絡先メールアドレス変更処理（メールアドレス認証処理）
   * @param {String} accountId アカウントID
   * @param {String} urlHash ハッシュ文字列
   * @param {String} recaptchaToken reCAPTCHAトークン
   * @returns {Object} レスポンスオブジェクト
   */
  changeMailAddress(accountId, urlHash, recaptchaToken) {
    return apiClient.post("changemailaddress", {
      accountId,
      hash: urlHash,
      recaptchaToken
    });
  }

  /**
   * OTP認証要求処理
   * @param {String} source 要求ページID
   * @return {Object} レスポンスオブジェクト
   */
  requestOtp(source) {
    return apiClient.post("requestotp", { source });
  }

  /**
   * サポートリクエスト送信処理
   * @param {Object} content 問い合わせ内容
   * @returns {Object} レスポンスオブジェクト
   */
  sendSupportRequest(content) {
    return apiClient.post("sendsupportrequest", content);
  }

  /**
   * サポートリクエスト添付ファイルアップロード処理
   * @param {String} requestDateTime サポートリクエスト送信日時
   * @param {File} file 帳票ファイル
   * @returns {Object} レスポンスオブジェクト
   */
  uploadAttachmentFile(requestDateTime, file) {
    const fileName = encodeURIComponent(file.name);
    let queries = `?requestDateTime=${requestDateTime}&fileName=${fileName}`;

    return apiClient.post("uploadattachmentfile" + queries, file);
  }

  /**
   * サインアップ承認データ取得処理
   * @param {String} tenantCode テナントコード
   * @param {Number} approvalState 承認状況
   * @returns {Object} レスポンスオブジェクト
   */
  getSignupApprovalData(tenantCode = null, approvalState = 0) {
    return apiClient.get("getsignupapprovaldata", { params: {
      tenantCode,
      approvalState,
    }});
  }

  /**
   * サインアップ承認データ追加処理
   * @param {Object} signupApprovalDataObject サインアップ承認データオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  requestSignupApproval(signupApprovalDataObject) {
    return apiClient.post("requestsignupapproval", {
      ...signupApprovalDataObject,
    });
  }

  /**
   * サインアップ承認・否認処理
   * @param {Object} signupApprovalDataObject サインアップ承認データオブジェクト
   * @param {Number} action アクション（2=承認、9=否認）
   * @returns {Object} レスポンスオブジェクト
   */
  approveSignupUser(signupApprovalDataObject, action) {
    return apiClient.post("approvesignupuser", {
      tenantCode: signupApprovalDataObject.tenantCode,
      ulid: signupApprovalDataObject.ulid,
      comment: signupApprovalDataObject.comment,
      contractStartDate: signupApprovalDataObject.contractStartDate,
      deliveryTags: signupApprovalDataObject.tagIds || [],
      action
    });
  }

  /**
   * ユーザーマスター追加処理（管理者）
   * @param {Object} user 新規登録に必要なユーザー情報
   * @returns {Object} レスポンスオブジェクト
   */
  addUser(user) {
    return apiClient.post("adduser", user);
  }

  /**
   * ユーザーマスター取得処理
   * @param {String} accountId ユーザのアカウントID（任意）
   * @param {String} tenantCode テナントコード（任意）
   * @param {String} customerCode 所属する得意先コード（任意）
   * @param {Boolean} excludesDeleted 削除済データ除外有無（デフォルト：true）
   * @returns {Object} レスポンスオブジェクト
   */
  getUser(accountId = "", tenantCode = "", customerCode = "", excludesDeleted = true) {
    return apiClient.get("getuser", { params: {
      accountId,
      tenantCode,
      customerCode,
      excludesDeleted
    }});
  }

  /**
   * ユーザーマスター更新処理
   * @param {Object} user 更新登録対象のユーザー情報
   * @param {Boolean} maintenanceMode メンテナンスモード（デフォルト：false）
   * @returns {Object} レスポンスオブジェクト
   */
  updateUser(user, maintenanceMode = false) {
    // マスター保守ユーザーによるユーザー更新かサービス利用者による自データ更新か
    return apiClient.post("updateuser", user, { params: {
      action : maintenanceMode ? 1 : 0,
    }});
  }

  /**
   * ユーザーマスター削除処理
   * @param {String} accountId アカウントID
   * @param {Boolean} isErase 削除モード（true：物理削除、false（デフォルト）：論理削除）
   * @returns {Object} レスポンスオブジェクト
   */
  deleteUser(accountId, isErase = false) {
      return apiClient.post("deleteuser", { accountId, isErase });
  }

  /**
   * ユーザーマスターアカウントロック処理
   * @param {String} accountId アカウントID
   * @param {Number} lockedOut ロックアウトフラグ（0:非ロック、9:強制ロック）
   * @returns {Object} レスポンスオブジェクト
   */
  lockedOutUser(accountId, lockedOut) {
    return apiClient.post("lockedOutUser", { accountId, lockedOut });
  }

  /**
   * ユーザー権限更新処理
   * @param {String} tenantCode テナントコード
   * @param {String} customerCode 得意先コード
   * @param {String} accountId アカウントID
   * @param {Array<Number>} roles ロールIDの配列
   * @param {Boolean} grantsTenantAdmin テナント管理者（デフォルト：false）
   * @returns {Object} レスポンスオブジェクト
   */
  updateUserRole(tenantCode, customerCode, accountId, roles, grantsTenantAdmin) {
    return apiClient.post("updateuserrole", {
      tenantCode,
      customerCode,
      accountId,
      roles,
      grantsTenantAdmin
    });
  }

  /**
   * テナント所属ユーザー一覧取得処理
   * @param {String} tenantCode テナントコード
   * @param {String} otpSource OTP発行元ページID
   * @param {String} otpCode OTPコード
   * @returns {Object} レスポンスオブジェクト
   */
  listMyUsers(tenantCode, otpSource, otpCode) {
    return apiClient.get("listmyusers", { params: {
      tenantCode,
      source: otpSource,
      securityCode: otpCode
    }});
  }

  /**
   * テナント管理者設定状況取得処理
   * @param {String} accountId アカウントID
   * @returns {Object} レスポンスオブジェクト
   */
  getMyAdminTenant(accountId) {
    return apiClient.get("getMyAdminTenant", { params: {
      accountId
    }});
  }
  

  /**
   * 更新ボタンモード更新処理
   * @param {Number} value 設定値（0:更新して閉じる, 1:更新のみ）
   * @returns {Object} レスポンスオブジェクト
   */
  updateUpdateButtonMode(value) {
    return apiClient.post("updatebuttonmode", {
      updateButtonMode: value
    });
  }

  /**
   * デフォルトアップロードモード更新処理
   * @param {Number} value 設定値（0:自動判別, 1:電子取引, 2:スキャナ保存）
   * @returns {Object} レスポンスオブジェクト
   */
  updateDefaultUploadMode(value) {
    return apiClient.post("updatedefaultuploadmode", {
      defaultUploadMode: value
    });
  }

  /**
   * 閲覧先一覧取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  listAccessibleTenant() {
    return apiClient.get("listaccessibletenant", {param: {}});
  }

  /**
   * ユーザー属性情報取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  getAttributes() {
    return apiClient.get("getattributes");
  }

  /**
   * 新規得意先アクセス権追加処理
   * @param {String} accountId アカウントID
   * @param {String} tenantCode テナントコード
   * @param {String} serviceCode サービス連携コード
   * @param {String} authCode ユーザー登録時認証コード
   * @param {String} serviceType 外部サービス区分
   * @param {Boolean} setsTenantContact 連絡先設定有無（デフォルト：false）
   * @returns {Object} レスポンスオブジェクト
   */
  assignNewCustomer(accountId, tenantCode, serviceCode, authCode, serviceType, setsTenantContact) {
    // リクエストパラメータをオブジェクト化
    const payload = {
      accountId,
      tenantCode,
      serviceCode,
      authCode,
      serviceType,
      setsTenantContact,
    };

    return apiClient.post("assignnewcustomer", payload);
  }

  /**
   * 閲覧得意先アクセス権削除処理
   * @param {String} accountId アカウントID
   * @param {String} tenantCode テナントコード
   * @param {String} customerCode 得意先コード
   * @param {Number} serviceType サービス区分
   * @param {String} serviceCode サービスコード
   * @returns {Object} レスポンスオブジェクト
   */
  unassigncustomer(accountId, tenantCode, customerCode, serviceType, serviceCode) {
    // リクエストパラメータをオブジェクト化
    const payload = {
      accountId,
      tenantCode,
      customerCode,
      serviceType,
      serviceCode
    };

    return apiClient.post("unassigncustomer", payload);
  }

  /**
   * 請求書一覧取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  getInvoiceData(mode, count, offset, dateOfIssueFrom, dateOfIssueTo, deadlineFrom, deadlineTo,
                 totalAmountMin, totalAmountMax, invoiceNo, customerName,
                 tags, excludesTagged, includesUntagged, state, visibility, confirmation) {
    return apiClient.get("getinvoicedata", {params: {
      mode,
      count,
      offset,
      dateOfIssueFrom,
      dateOfIssueTo,
      deadlineFrom,
      deadlineTo,
      totalAmountMin,
      totalAmountMax,
      invoiceNo,
      customerName,
      tags: tags.toString(),
      excludesTagged,
      includesUntagged,
      state,
      visibility,
      confirmation
    }});
  }

  /**
   * 請求書PDFダウンロードSASリンク取得処理
   * @param {Object} invoice 請求書オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  getInvoicePDFLink(invoice) {
    return apiClient.get("downloadinvoice", { params: {
      tenantCode: invoice.tenantCode,
      dateOfIssue: invoice.dateOfIssue,
      customerCode: invoice.customer.code,
      serviceType: invoice.serviceSetting.serviceType,
      serviceCode: invoice.externalService.serviceCode,
      issueNumber: invoice.issueNumber,
      publisher: invoice.publisher,
      formType: invoice.formType,
      branchNumber: invoice.branchNumber
    }});
  }

  /**
   * Blobファイルダウンロード処理
   * @param {String} url URL
   * @param {Object} params パラメータ（オプション）
   * @returns {Object} レスポンスオブジェクト
   */
  downloadBlob(url, params = {}) {
    return apiClient.get(url,{
      responseType: "blob",
      params: params,
    });
  }

  /**
   * 納品書アップロード処理
   * @param {Object} uploadFormObject アップロード帳票オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  uploadInvoicePDF(uploadFormObject) {
    const queries = "?dateOfIssue=" + uploadFormObject.dateOfIssue
                  + "&issueNumber=" + uploadFormObject.issueNumber
                  + "&customerCode=" + uploadFormObject.customerCode
                  + "&serviceType=" + uploadFormObject.serviceType
                  + "&serviceCode=" + uploadFormObject.serviceCode
                  + "&formType=" + uploadFormObject.formType
                  + "&publisher=" + uploadFormObject.publisher
                  + "&parentFormType=" + uploadFormObject.parentFormType
                  + "&parentPublisher=" + uploadFormObject.parentPublisher
                  + "&uploadFileName=" + uploadFormObject.uploadFileName;
    return apiClient.post("uploadinvoice" + queries, uploadFormObject.file);
  }

  /**
   * 汎用帳票削除処理
   * @param {Object} deleteInvoiceFormObject 削除帳票オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  deleteInvoicePDF(deleteInvoiceFormObject) {
    return apiClient.post("deleteinvoice", {
      dateOfIssue: deleteInvoiceFormObject.dateOfIssue,
      issueNumber: deleteInvoiceFormObject.issueNumber,
      customerCode: deleteInvoiceFormObject.customerCode,
      serviceType: deleteInvoiceFormObject.serviceType,
      serviceCode: deleteInvoiceFormObject.serviceCode,
      publisher: deleteInvoiceFormObject.publisher,
      formType: deleteInvoiceFormObject.formType,
      branchNumber: deleteInvoiceFormObject.branchNumber,
      deadline: deleteInvoiceFormObject.deadline
    });
  }

  /**
   * 請求書データ承認処理
   * @param {Object} payload 承認する請求書のプライマリーキー
   * @returns {Object} レスポンスオブジェクト
   */
  approveInvoice(payload) {
    return apiClient.post("updateapprovalinvoice", payload);
  }

  /**
   * 請求書発行状況取得処理
   * @param {String} dateOfIssue 発行日
   * @param {Number} issueNumber 発行番号（任意）
   * @param {String} publisher 発行者（任意）
   * @param {Number} formType 帳票区分（任意）
   * @param {String} deadline 締日（任意）
   * @returns {Object} レスポンスオブジェクト
   */
  getIssueControlData(dateOfIssue, issueNumber = null, publisher = null, formType = null, deadline = null) {
    return apiClient.get("getissuecontrol", { params: {
      dateOfIssue,
      issueNumber,
      publisher,
      formType,
      deadline
    }});
  }

 /**
  * 発行管理テーブルの発行日取得処理
  * @returns {Object} レスポンスオブジェクト
  */
  listIssueDate() {
    return apiClient.get("listissuedate");
  }

  /**
   * 請求書発行処理
   * @param {String} payload 発行対象データ
   * @returns {Object} レスポンスオブジェクト
   */
  issueInvoice(target) {
    return apiClient.post("issueInvoiceData", { target });
  }

  /**
   * 請求取消処理
   * @param {String} dateOfIssue 発行日
   * @param {Number} issueNumber 発行番号
   * @param {String} customerCode 得意先コード
   * @param {Number} serviceType サービス区分
   * @param {String} serviceCode サービスコード
   * @param {String} publisher 発行元区分
   * @param {Number} formType 帳票区分
   * @param {Number} branchNumber 枝番
   * @returns {Object} レスポンスオブジェクト
   */
  cancelInvoice(dateOfIssue, issueNumber, customerCode, serviceType, serviceCode, publisher, formType, branchNumber) {
    return apiClient.post("cancelinvoice", {
      dateOfIssue,
      issueNumber,
      customerCode,
      serviceType,
      serviceCode,
      publisher,
      formType,
      branchNumber
    });
  }

  /**
   * 帳票保管連携処理
   * @param {String} tenantCode テナントコード
   * @param {String} dateOfIssue 発行日
   * @param {Number} issueNumber 発行番号
   * @param {String} customerCode 得意先コード
   * @param {Number} serviceType サービス区分
   * @param {String} serviceCode サービスコード
   * @param {String} publisher 発行元区分
   * @param {Number} formType 請求書データ帳票区分
   * @param {Number} branchNumber 枝番
   * @param {Number} evidenceType 帳票データ帳票区分
   * @param {Number} inputDateType 取引日取込区分
   * @param {Array} groupIds グループIDの配列
   * @param {String} partnerCode 取引先コード
   * @param {String} partnerName 取引先名
   * @returns {Object} レスポンスオブジェクト
   */
  exportInvoice(tenantCode, dateOfIssue, issueNumber, customerCode, serviceType, serviceCode, publisher, formType,
                branchNumber, evidenceType, inputDateType, groupIds, partnerCode, partnerName) {
    return apiClient.post("exportinvoicedata", {
      tenantCode,
      dateOfIssue,
      issueNumber,
      customerCode,
      serviceType,
      serviceCode,
      publisher,
      formType,
      branchNumber,
      evidenceType,
      inputDateType,
      groupIds: groupIds.toString(),
      partnerCode,
      partnerName,
    });
  }

  /**
   * 帳票種類変換マスター取得処理
   * @param {Boolean} excludesDeleted 削除レコード除外有無（デフォルト：true）
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  listFormTypeConversions(excludesDeleted = true, customerCode) {
    return apiClient.get("listformtypeconvs", { params: {
      excludesDeleted,
      customerCode,
    }});
  }

  /**
   * 帳票種類変換マスター更新処理
   * @param {Object} formTypeConversionsObject 帳票種類変換オブジェクト
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  updateFormTypeConversions(formTypeConversionsObject, customerCode) {
    return apiClient.post("updateformtypeconvs", {
      ...formTypeConversionsObject,
      customerCode,
    });
  }

  /**
   * 帳票種類変換マスター削除処理
   * @param {String} destinationTenantCode 送付先テナントコード
   * @param {String} formType 帳票区分
   * @param {String} customerCode 得意先コード
   * @param {Number} serviceType サービス区分
   * @param {String} serviceCode サービス連携コード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteFormTypeConversions(destinationTenantCode, formType, customerCode, serviceType, serviceCode) {
    return apiClient.post("deleteformtypeconvs", {
      destinationTenantCode,
      formType,
      customerCode,
      serviceType,
      serviceCode
    });
}

  /**
   * お知らせデータ取得処理
   * @param {String} dateOfDelivery 配信開始日
   * @param {String} history 履歴番号（デフォルト："0"）
   * @param {Boolean} excludesDeleted 削除済データ除外有無（デフォルト：true）
   * @param {Boolean} maintenanceMode メンテナンスモード（デフォルト：false）
   * @returns {Object} レスポンスオブジェクト
   */
  getNoticeData(dateOfDelivery, history = "0", excludesDeleted = true, maintenanceMode = false) {
    return apiClient.get("getnotice", { params: {
      dateOfDelivery,
      history,
      excludesDeleted,
      maintenanceMode,
    }});
  }

  /**
   * お知らせデータ追加処理
   * @param {Object} noticeObject お知らせオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  async addNoticeData(noticeObject) {
    return apiClient.post("addnotice", {
      displayArea: noticeObject.displayArea,
      indication: noticeObject.indication,
      importance: noticeObject.importance,
      title: noticeObject.title,
      contents: noticeObject.contents,
      startDateTime: noticeObject.startDateTime,
      endDateTime: noticeObject.endDateTime,
      destinations: noticeObject.destinations.toString(),
      notification: noticeObject.notification,
      visibility: noticeObject.visibility,
      approval: noticeObject.approval,
      uploadFileName: noticeObject.uploadFileName,
      file: noticeObject.file ? await util.encodeFileToBase64String(noticeObject.file) : ""
    });
  }

  /**
   * お知らせデータ更新処理
   * @param {Object} noticeObject お知らせオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  async updateNotice(noticeObject) {
    return apiClient.post("updatenotice", {
      creationDate: noticeObject.creationDate,
      history: noticeObject.history,
      displayArea: noticeObject.displayArea,
      indication: noticeObject.indication,
      importance: noticeObject.importance,
      title: noticeObject.title,
      contents: noticeObject.contents,
      startDateTime: noticeObject.startDateTime,
      endDateTime: noticeObject.endDateTime,
      destinations: noticeObject.destinations.toString(),
      notification: noticeObject.notification,
      visibility: noticeObject.visibility,
      approval: noticeObject.approval,
      uploadFileName: noticeObject.uploadFileName,
      file: noticeObject.file ? await util.encodeFileToBase64String(noticeObject.file) : ""
    });
  }

  /**
   * お知らせデータ削除処理
   * @param {String} creationDate 登録年月日
   * @param {Number} history 登録履歴番号
   * @returns {Object} レスポンスオブジェクト
   */
  deleteNoticeData(creationDate,history) {
    const queries = "?creationDate=" + creationDate
                  + "&history=" + history;
    return apiClient.post("deletenotice" + queries,"");
  }

  /**
   * お知らせデータ承認処理
   * @param {String} creationDate 登録年月日
   * @param {Number} history 登録履歴番号
   * @returns {Object} レスポンスオブジェクト
   */
  approveNoticeData(creationDate,history) {
    return apiClient.post("updateapprovalnotice", { creationDate, history });
  }

  /**
   * お知らせ既読更新処理
   * @param {String} accountId アカウントID
   * @param {String} creationDate 登録年月日
   * @param {Number} history 登録履歴番号
   * @returns {Object} レスポンスオブジェクト
   */
  updateReadState(accountId,creationDate,history) {
    return apiClient.post("updatereadstate", { accountId,creationDate, history });
  }

  /**
   * お知らせ取得（サインイン画面用）
   * @returns {Object} レスポンスオブジェクト
   */
  getAnnounce() {
    return apiClient.get("getannounce");
  }

  /**
   * お知らせPDFダウンロードSASリンク取得処理
   * @param {Object} notice お知らせオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  getnoticePDFLink(notice) {
    return apiClient.get("downloadnotice", { params: {
      creationDate: notice.creationDate,
      history: notice.history,
      title: notice.title
    }});
  }

  /**
   * サインアップ方式取得処理
   * @param {String} tenantCode 発行元コード
   * @param {Number} serviceType サービス区分（初期値: 0）
   * @param {String} serviceCode サービスコード（初期値:null）
   * @param {Boolean} excludesDeleted 削除済データ除外有無（デフォルト：true）
   * @param {Boolean} isContract 契約中有無（デフォルト：false）
   * @returns {Object} レスポンスオブジェクト
   */
  getSignupMethod(tenantCode, serviceType = 0, serviceCode = null, excludesDeleted = true, isContract = false) {
    return apiClient.get("getSignupMethod", { params: {
      tenantCode,
      serviceType,
      serviceCode,
      excludesDeleted,
      isContract
    }});
  }

  /**
   * 得意先マスター取得処理
   * @param {String} tenantCode 発行元コード
   * @param {String} customerCode 得意先コード
   * @param {Boolean} excludesDeleted 削除済データ除外有無（デフォルト：true）
   * @param {Boolean} isContract 契約中有無（デフォルト：FALSE）
   * @param {Number} callerPage 呼び出し画面区分
   * @returns {Object} レスポンスオブジェクト
   */
  getCustomer(tenantCode, customerCode, excludesDeleted = true, isContract = false, callerPage = 1) {
    return apiClient.get("getcustomer", { params: {
      tenantCode,
      customerCode,
      excludesDeleted,
      isContract,
      callerPage
    }});
  }

  /**
   * 得意先マスター追加処理
   * @param {Object} customerObject 得意先オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  addCustomer(customerObject) {
    return apiClient.post("addcustomer", customerObject);
  }

  /**
   * 得意先マスター更新処理
   * @param {Object} customerObject 得意先オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateCustomer(customerObject) {
    return apiClient.post("updatecustomer", customerObject);
  }

  /**
   * 得意先マスター削除処理
   * @param {String} tenantCode テナントコード
   * @param {String} code 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteCustomer(tenantCode, code) {
    return apiClient.post("deletecustomer", {
      tenantCode,
      code,
    });
  }

  /**
   * テナントコード自動採番処理
   * @param {String} parentTenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  autoNumberingTenantCode(parentTenantCode) {
    return apiClient.get("getautonumberingtenantcode", { params: {
      tenantCode: parentTenantCode,
  }});
  }

  /**
   * 得意先マスター一覧CSVダウンロード処理
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  downloadCustomerListCsv(tenantCode) {
    return apiClient.get("downloadtenantlist", { params: {
      tenantCode,
    }});
  }

  /**
   * 帳票承認設定更新処理
   * @param {String} customerObject 帳票承認設定オブジェクト
   * @returns {Object} レスポンスオブジェクト
   * @summary 得意先オブジェクトのうち、EvidenceApprovalOptionのみ更新する
   */
  updateEvidenceApprovalOption(customerObject) {
    return apiClient.post("updateevidenceapprovaloption", customerObject);
  }

  /**
   * タグ動作設定更新処理
   * @param {String} customerObject 帳票承認設定オブジェクト
   * @returns {Object} レスポンスオブジェクト
   * @summary 得意先オブジェクトのうち、IssueTagOptionのみ更新する
   */
  updateIssueTagOption(customerObject) {
    return apiClient.post("updateissuetagoption", customerObject);
  }

  /***
   * お客様番号発行通知書生成処理
   * @param {String} tenantCode テナントコード
   * @param {String} destinationTenantCode 送付先テナントコード
   * @param {Number} serviceType サービス区分
   * @param {Number} generateType 生成区分
   * @returns {Object} レスポンスオブジェクト
   */
  generateCustomerNotice(tenantCode, destinationTenantCode, serviceType, generateType) {
    return apiClient.get("downloadregistrationsheet", { params: {
      tenantCode,
      destinationTenantCode,
      serviceType,
      action: generateType
    }});
  }

  /***
   * サービス連携設定マスター取得処理
   * @param {String} tenantCode テナントコード
   * @param {Number} serviceType サービス区分
   * @returns {Object} レスポンスオブジェクト
   */
  getExternalServiceSettings(tenantCode, serviceType = null) {
    return apiClient.get("listexternalservicesettings", { params: {
      tenantCode,
      serviceType,
    }});
  }

  /**
   * サービス連携設定マスター追加処理
   * @param {Object} サービス連携設定マスターオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  addExternalServiceSettings(externalServiceSettings) {
    return apiClient.post("addexternalservicesettings", {
      ... externalServiceSettings
    });
  }

  /**
   * サービス連携設定マスター更新処理
   * @param {Object} サービス連携設定マスターオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateExternalServiceSettings(externalServiceSettings) {
    return apiClient.post("updateexternalservicesettings", {
      ... externalServiceSettings
    });
  }

  /**
   * サービス連携設定マスター削除処理
   * @param {String} tenantCode テナントコード
   * @param {Number} serviceType サービス区分
   * @returns {Object} レスポンスオブジェクト
   */
  deleteExternalServiceSettings(tenantCode, serviceType = null) {
    return apiClient.post("deleteexternalservicesettings", {
      tenantCode,
      serviceType,
    });
  }

  /***
   * サービス連携コード変換マスター取得処理
   * @param {String} tenantCode テナントコード
   * @param {String} customerCode 得意先コード
   * @param {Number} serviceType サービス区分
   * @returns {Object} レスポンスオブジェクト
   */
  getExternalServiceConversions(tenantCode, customerCode, serviceType = null) {
    return apiClient.get("getexternalserviceconversions", { params: {
      tenantCode,
      customerCode,
      serviceType,
    }});
  }

  /**
   * サービス連携コード変換マスター追加処理
   * @param {Object} サービス連携コード変換マスターオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  addExternalServiceConversions(externalServiceConversions) {
    return apiClient.post("addexternalserviceconversions", {
      ... externalServiceConversions
    });
  }

  /**
   * サービス連携コード変換マスター更新処理
   * @param {Object} サービス連携コード変換マスターオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateExternalServiceConversions(externalServiceConversions) {
    return apiClient.post("updateexternalserviceconversions", {
      ... externalServiceConversions
    });
  }

  /**
   * サービス連携コード変換マスター削除処理
   * @param {String} tenantCode テナントコード
   * @param {String} customerCode 得意先コード
   * @param {Number} serviceType サービス区分
   * @returns {Object} レスポンスオブジェクト
   */
  deleteExternalServiceConversions(tenantCode, customerCode, serviceType = null) {
    return apiClient.post("deleteexternalserviceconversions", {
      tenantCode,
      customerCode,
      serviceType,
    });
  }

  /**
   * 切替可能テナント取得処理
   * @param {String} tenantCode 発行元コード
   * @param {String} customerCode 得意先コード
   * @param {Boolean} excludesDeleted 削除済データ除外有無（デフォルト：true）
   * @param {Boolean} isContract 契約中有無（デフォルト：FALSE）
   * @param {Number} callerPage 呼び出し画面区分
   * @returns {Object} レスポンスオブジェクト
   */
  getSwitchableTenant(tenantCode, customerCode, excludesDeleted = true, isContract = false, callerPage = 0) {
    return apiClient.get("getSwitchableTenant", { params: {
      tenantCode,
      customerCode,
      excludesDeleted,
      isContract,
      callerPage
    }});
  }

  /**
   * 取引先マスター取得処理
   * @param {String} partnerName 取引先名称
   * @param {Boolean} excludesDeleted 削除済データ除外有無（デフォルト：true）
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  getPartner(partnerName, excludesDeleted = true, customerCode = null) {
    return apiClient.get("getpartner", { params: {
        partnerName,
        excludesDeleted,
        customerCode,
      }});
  }

  /**
   * 取引先マスター追加処理
   * @param {Object} partnerObject 取引先オブジェクト
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  addPartner(partnerObject, customerCode = null) {
    return apiClient.post("addpartner", {
      ...partnerObject,
      customerCode,
    });
  }

  /**
   * 取引先マスター更新処理
   * @param {Object} partnerObject 取引先オブジェクト
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  updatePartner(partnerObject, customerCode = null) {
    return apiClient.post("updatepartner", {
      ...partnerObject,
      customerCode,
    });
  }

  /**
   * 取引先マスター削除処理
   * @param {String} code 取引先コード
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  deletePartner(code, customerCode = null) {
    return apiClient.post("deletepartner", {
      code,
      customerCode,
    });
  }

  /**
   * タグマスター取得処理
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  getTags(tenantCode) {
    return apiClient.get("listTags", {
      params: {
        tenantCode
      }
    })
  }
  
  /**
   * タグマスター追加処理
   * @param {Object} tagObject タグオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  addTag(tagObject, tenantCode) {
    return apiClient.post("addtag", {
      ...tagObject,
      tenantCode,
    });
  }

  /**
   * タグマスター更新処理
   * @param {Object} tagObject タグオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  updateTag(tagObject, tenantCode) {
    return apiClient.post("updatetag", {
      ...tagObject,
      tenantCode,
    });
  }

  /**
   * タグマスター削除処理
   * @param {String} id タグID
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteTag(id, tenantCode) {
    return apiClient.post("deletetag", {
      id,
      tenantCode,
    });
  }

  /**
   * 認証コード再生成処理
   * @param {String} tenantCode テナントコード
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  regenerateAuthCode(tenantCode, customerCode) {
    return apiClient.post("regeneratecustomerauthcode", {
      tenantCode,
      customerCode
    });
  }

  /**
   * Qandaマスター取得処理
   * @param {Boolean} maintenanceMode メンテナンスモード
   * @returns {Object} レスポンスオブジェクト
   */
  getQandA(maintenanceMode) {
    return apiClient.get("getqanda", {
      params: {
        maintenanceMode,
      }
    });
  }

  /**
   * Qandaマスター追加処理
   * @param {Object} qandaObject Qandaオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  addQandA(qandaObject) {
    return apiClient.post("addqanda", qandaObject);
  }

  /**
   * Qandaマスター更新処理
   * @param {Object} qandaObject  Qandaマスターオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateQandA(qandaObject) {
    return apiClient.post("updateqanda", qandaObject);
  }

  /**
   * Qandaマスター削除処理
   * @param {Number} id ID
   * @returns {Object} レスポンスオブジェクト
   */
  deleteQandA(id) {
    return apiClient.post("deleteqanda", { id });
  }

  /**
   * システムコンフィグ取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  getSystemConfig() {
    return apiClient.get("getsystemconfig");
  }

  /**
   * システムコンフィグ更新処理
   * @param {Object} systemConfigObject  システムコンフィグオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateSystemConfig(systemConfigObject) {
    return apiClient.post("updatesystemconfig", systemConfigObject);
  }

  /**
   * 帳票一覧取得処理
   * @param {String} dateOfIssueFrom 開始取引日
   * @param {String} dateOfIssueTo 終了取引日
   * @param {String} totalAmountMin 下限取引金額
   * @param {String} totalAmountMax 上限取引金額
   * @param {String} partnerName 取引先名称
   * @param {String} subjectRemarks 件名・摘要／メモ
   * @param {Boolean} subjectRemarksConditions 件名・摘要／メモ条件
   * @param {Array<Number>} tags 帳票タグ
   * @param {Boolean} isTagsAndConditions タグ検索ANDOR条件
   * @param {Array<Number>} groups 帳票グループ
   * @param {String} mapCode マップコード
   * @param {Number} count 取得件数
   * @param {String} confirmation 確認状況
   * @param {Array<Number>} approvalStates 承認状況
   * @param {String} id 帳票ID
   * @param {Boolean} isConfirmMode エントリー／承認モードフラグ
   * @param {String} lastKey 最終行キー
   * @param {String} sortKey ソートキー
   * @param {Boolean} excludesDeleted 削除済除外フラグ
   * @param {String} customerCode 得意先コード
   * @param {Boolean} isTenantChanged テナント切り替え有無
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceData(dateOfIssueFrom, dateOfIssueTo, totalAmountMin, totalAmountMax,
                  partnerName, subjectRemarks, subjectRemarksConditions, tags,
                  isTagsAndConditions, groups, mapCode, count, confirmation,
                  approvalStates, id, isConfirmMode, lastKey, sortKey,
                  excludesDeleted, customerCode, isTenantChanged) {
    return apiClient.get("getevidencedata", { params: {
      dateOfIssueFrom,
      dateOfIssueTo,
      totalAmountMin,
      totalAmountMax,
      partnerName,
      subjectRemarks,
      subjectRemarksConditions,
      tags: tags.toString(),
      isTagsAndConditions,
      groups: groups.toString(),
      mapCode,
      count,
      confirmation,
      approvalStates: approvalStates.toString(),
      id,
      isConfirmMode,
      lastKey,
      sortKey,
      excludesDeleted,
      customerCode,
      isTenantChanged
    }});
  }

  /**
   * 帳票データ取得処理（帳票キー情報を指定する）
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceDataByKey(tenantCode, registrationDate, registrationNo) {
    return apiClient.get("getevidencedatabykey", { params: {
      tenantCode,
      registrationDate,
      registrationNo,
    }});
  }

  /**
   * 帳票データ更新処理
   * @param {Object} evidenceData 帳票データオブジェクト
   * @param {Boolean} shouldReplaceTags 帳票デフォルトタグ入れ替え有無
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  updateEvidenceData(evidenceData, shouldReplaceTags, customerCode = null) {
    if (!evidenceData.registrationDate || !evidenceData.registrationNo) {
      return;
    }

    return apiClient.post("updateevidence", {
      registrationDate: evidenceData.registrationDate,
      registrationNo: evidenceData.registrationNo,
      historyNo: evidenceData.historyNo,
      evidenceType: evidenceData.evidenceType,
      registerType: evidenceData.registerType,
      partnerCode: evidenceData.partnerCode,
      partnerName: evidenceData.partnerName,
      dateOfIssue: evidenceData.dateOfIssue,
      dateOfReceipt: evidenceData.dateOfReceipt,
      subject: evidenceData.subject,
      totalAmount: evidenceData.totalAmount,
      mapCode: evidenceData.mapCode,
      reason: evidenceData.reason,
      remarks: evidenceData.remarks,
      shouldReplaceTags,
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    });
  }

  /**
   * 帳票データ削除処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @param {String} reason 事由
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  deleteEvidenceData(registrationDate, registrationNo, historyNo, reason, customerCode = null) {
    if (!registrationDate || !registrationNo) {
      return;
    }

    return apiClient.post("deleteevidence", {
      registrationDate,
      registrationNo,
      historyNo,
      reason: reason?.trim(),
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    });
  }

  /**
   * 帳票データ復元処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @param {String} reason 事由
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  restoreEvidenceData(registrationDate, registrationNo, historyNo, reason, customerCode = null) {
    if (!registrationDate || !registrationNo) {
      return;
    }

    return apiClient.post("restoreevidence", {
      registrationDate,
      registrationNo,
      historyNo,
      reason: reason?.trim(),
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    });
  }

  /**
   * 帳票データ保留・保留解除処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @param {String} customerCode 得意先コード
   * @param {Number} pendingAction 処理フラグ（1：保留、0：保留解除）
   * @returns {Object} レスポンスオブジェクト
   */
  updateEvidencePendingState(registrationDate, registrationNo, historyNo, customerCode, pendingAction) {
    return apiClient.post("updateevidencependingstate", {
      registrationDate,
      registrationNo,
      historyNo,
      customerCode,
      pendingAction,
    });
  }

  /**
   * 帳票データロック状況更新処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {Number} action 処理区分（1:ロック、2:解除）
   * @param {Boolean} isEnforce 強制実行フラグ（True:強制する、False:強制しない）
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のセット）
   * @returns {Object} レスポンスオブジェクト
   */
  updateEvidenceLockState(registrationDate, registrationNo, action, isEnforce, customerCode = null) {
    return apiClient.post("updateevidencelockstate", {
      registrationDate,
      registrationNo,
      action,
      isEnforce,
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    });
  }

  /**
   * 帳票データ更新履歴取得処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @param {Number} callerPage 呼び出し画面区分
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceHistories(registrationDate, registrationNo, customerCode = null, callerPage) {
    return apiClient.get("getevidencehistories", { params: {
      registrationDate,
      registrationNo,
      customerCode,
      callerPage,
    }});
  }

  /**
   * 帳票データ承認（差戻）処理
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @param {Number} action 処理区分（1：承認（デフォルト）、2：差戻）
   * @param {Number} target 差戻先（0：無指定（デフォルト）、1：アップロード者、2：エントリー者、3：一次承認者）
   * @param {String} reason 差戻事由（承認時は不要）
   * @returns {Object} レスポンスオブジェクト
   */
  approveEvidenceData(registrationDate, registrationNo, customerCode = null, action = 1, target = 0, reason = null) {
    if (!registrationDate || !registrationNo) {
      return;
    }

    return apiClient.post("updateapprovalevidence" , {
      registrationDate,
      registrationNo,
      action,
      target,
      reason: reason?.trim(),
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    });
  }

  /**
   * 帳票データ差戻処理
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @param {Number} target 差戻先（0：無指定（デフォルト）、1：アップロード者、2：エントリー者、3：一次承認者）
   * @param {String} reason 差戻事由
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  remandEvidenceData(registrationDate, registrationNo, target, reason, customerCode = null) {
    return this.approveEvidenceData(registrationDate, registrationNo, customerCode, 2, target, reason);
  }

  /**
   * 帳票データ承認履歴取得処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @param {Number} callerPage 呼び出し画面区分
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceApprovalHistories(registrationDate, registrationNo, customerCode = null, callerPage) {
    return apiClient.get("getevidenceapprovalhistories", { params: {
      registrationDate,
      registrationNo,
      customerCode,
      callerPage,
    }});
  }

  /**
   * 帳票データ検索キー入力候補取得処理
   * @param {Number} callerPage 呼び出し元画面
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceSearchKeys(callerPage) {
    return apiClient.get("getevidencesearchkeys", { params: {
      callerPage,
    }});
  }

  /**
   * 帳票未確定・未承認件数取得処理
   * @returns {Object} レスポンスオブジェクト
   * @param {String} customerCode 得意先コード（オプション：サポート権限の場合のみセット）
   */
  getEvidenceSummary(customerCode) {
    return apiClient.get("getevidencesummary", { params: {
      customerCode,
    }});
  }

  /**
   * 帳票保管状況取得処理
   * @returns {Object} レスポンスオブジェクト
   * @param {String} customerCode 得意先コード（オプション：サポート権限の場合のみセット）
   */
  getEvidenceStatistics(customerCode) {
    return apiClient.get("getevidencestatistics", { params: {
      customerCode,
    }});
  }

  /**
   * 代行入力状況取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  getEntryAgentSummary() {
    return apiClient.get("getentryagentsummary");
  }

  /**
   * OCR実行件数取得処理
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  getOcrCount(tenantCode) {
    return apiClient.get("getocrcount", { params: {
      tenantCode
    }});
  }

  /**
   * 帳票データエクスポート回数取得処理
   * @returns {Object} レスポンスオブジェクト
   */
  getExportEvidenceCount() {
    return apiClient.get("getexportcount");
  }

  /**
   * 帳票データエクスポート処理
   * @param {Number} action アクション(検索・件数取得: 1、ダウンロード実行: 2)
   * @param {String} tenantCode テナントコード
   * @param {Number} formType 帳票種類ID
   * @param {String} dateOfIssueFrom 開始取引日
   * @param {String} dateOfIssueTo 終了取引日
   * @param {Number} totalAmountMin 下限取引金額
   * @param {Number} totalAmountMax 上限取引金額
   * @param {String} partnerName 取引先名
   * @param {Boolean} excludesDeleted 削除レコードを除外するか否か
   * @param {Boolean} exportsTags タグを出力に含めるか否か
   * @param {Boolean} exportsSubject 件名・摘要を出力に含めるか否か
   * @param {Boolean} exportsRemarks メモを出力に含めるか否か
   * @param {Boolean} exportsMapCode マップコードを出力に含めるか否か
   * @returns {Object} レスポンスオブジェクト
   */
  exportEvidence(action, tenantCode, formType, dateOfIssueFrom, dateOfIssueTo, totalAmountMin, totalAmountMax, partnerName,
                 excludesDeleted, exportsTags, exportsSubject, exportsRemarks, exportsMapCode) {
    return apiClient.get("exportevidencedata", { params: {
      action,
      tenantCode,
      evidenceSettingId: formType < 1 ? null : formType,
      dateOfIssueFrom,
      dateOfIssueTo,
      totalAmountMin,
      totalAmountMax,
      partnerName,
      excludesDeleted,
      exportsTags,
      exportsSubject,
      exportsRemarks,
      exportsMapCode
    }});
  }

  /**
   * 帳票タグリスト更新処理
   * @param {String} action 処理区分(追加:add, 削除:del)
   * @param {Object} evidenceData 帳票データオブジェクト
   * @param {Array} tagIds 帳票タグID配列
   * @returns {Object} レスポンスオブジェクト
   */
  updateEvidenceTagList(action, evidenceData, tagIds) {
    // 必要なデータのみ送る
    const payload = {
      action: action,
      registrationDate: evidenceData.registrationDate,
      registrationNo: evidenceData.registrationNo
    };

    // actionによってタグIDをセットする
    switch(action) {
      case "add":
      case "del":
        payload.tagId = tagIds[0];
        break;
      case "replace":
        payload.tagIds = tagIds;
        break;
    }

    return apiClient.post("updateevidencetaglist", payload);
  }

  /**
   * 帳票デフォルトタグ入れ替え処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @param {Number} before
   * @param {Number} after
   * @returns {Object} レスポンスオブジェクト
   */
  swapEvidenceDefaultTags(tenantCode, registrationDate, registrationNo, before, after) {
    return apiClient.post("changedoctype", {
      tenantCode,
      registrationDate,
      registrationNo,
      beforeDocType: before,
      afterDocType: after,
    });
  }

  /**
   * 帳票グループ変更処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @param {Array} groupIds グループIDの配列
   * @returns {Object} レスポンスオブジェクト
   */
  changeEvidenceGroups(tenantCode, registrationDate, registrationNo, historyNo, groupIds) {
    return apiClient.post("updateevidencegroupaccesslist", {
      tenantCode,
      registrationDate,
      registrationNo,
      historyNo,
      groupIds: groupIds.toString(),
    });
  }

  /**
   * 帳票マップ管理取得処理
   * @param {String} tenantCode テナントコード
   * @param {String} mapCode マップコード
   * @returns {Object} レスポンスオブジェクト
   */
  getMapControl(tenantCode, mapCode) {
    return apiClient.get("getmapcontrol", { params: {
      tenantCode,
      mapCode,
    }});
  }

  /**
   * 帳票マップ管理更新処理
   * @param {String} tenantCode テナントコード
   * @param {String} mapCode マップコード
   * @param {Number} retentionYears 保管年数
   * @param {String} comment コメント
   * @returns {Object} レスポンスオブジェクト
   */
  updateMapControl(tenantCode, mapCode, retentionYears, comment) {
    return apiClient.post("updatemapcontrol", {
      tenantCode,
      mapCode,
      retentionYears,
      comment
    });
  }

  /**
   * 帳票マップリスト更新処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @param {Number} displayOrder 表示順
   * @param {String} parentRegistrationDate 親帳票登録日
   * @param {Number} parentRegistrationNo 親帳票登録番号
   * @param {Boolean} keepRelationship 親子関係を維持するか否か
   * @returns {Object} レスポンスオブジェクト
   */
  updateMapList(tenantCode, registrationDate, registrationNo, displayOrder, parentRegistrationDate, parentRegistrationNo, keepRelationship) {
    return apiClient.post("updatemaplist", {
      tenantCode,
      registrationDate,
      registrationNo,
      displayOrder,
      parentRegistrationDate,
      parentRegistrationNo,
      keepRelationship,
    });
  }

  /**
   * 帳票マップコメント取得処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @returns {Object} レスポンスオブジェクト
   */
  getMapComments(tenantCode, registrationDate, registrationNo, lastCommentNo = null) {
    return apiClient.get("getevidencemapcomment", { params: {
      tenantCode,
      registrationDate,
      registrationNo,
      lastCommentNo
    }});
  }

  /**
   * 帳票マップコメント追加処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @param {String} comment コメント
   * @returns {Object} レスポンスオブジェクト
   */
  addMapComment(tenantCode, registrationDate, registrationNo, comment) {
    return apiClient.post("addevidencemapcomment", {
      tenantCode,
      registrationDate,
      registrationNo,
      comment
    });
  }

  /**
   * 帳票マップコメント削除処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {Number} registrationNo 登録番号
   * @param {Number} commentNo コメント番号
   * @returns {Object} レスポンスオブジェクト
   */
  deleteMapComment(tenantCode, registrationDate, registrationNo, commentNo) {
    return apiClient.post("deleteevidencemapcomment", {
      tenantCode,
      registrationDate,
      registrationNo,
      commentNo
    });
  }

  /**
   * 帳票マップコード検索処理
   * @param {String} tenantCode テナントコード
   * @param {String} mapCode マップコード
   * @param {String} partnerName 取引先名
   * @param {Number} dateOfIssueFrom 取引日開始値
   * @param {Number} dateOfIssueTo 取引日終了値
   * @param {Number} totalAmountMin 取引金額最小値
   * @param {Number} totalAmountMax 取引金額最大値
   * @returns {Object} レスポンスオブジェクト
   */
  searchMapCode(tenantCode, mapCode, partnerName, dateOfIssueFrom, dateOfIssueTo, totalAmountMin, totalAmountMax) {
    return apiClient.get("searchmapcode", { params: {
      tenantCode,
      mapCode,
      partnerName,
      dateOfIssueFrom,
      dateOfIssueTo,
      totalAmountMin,
      totalAmountMax
    }});
  }

  /**
   * 帳票マップコード置換処理
   * @param {String} tenantCode テナントコード
   * @param {String} oldMapCode 現在のマップコード
   * @param {String} newMapCode 新しいマップコード
   * @returns {Object} レスポンスオブジェクト
   */
  replaceMapCode(tenantCode, oldMapCode, newMapCode) {
    return apiClient.post("replacemapcode", {
      tenantCode,
      oldMapCode,
      newMapCode,
    });
  }

  /**
   * 帳票ファイルダウンロードSASリンク取得処理
   * @param {Object} filePath 帳票オブジェクト
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceFileLink(filePath, customerCode = null) {
    return apiClient.get("downloadevidence", { params: {
      path: filePath,
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    }});
  }

  /**
   * 代行入力用帳票ファイルダウンロードSASリンク取得処理
   * @param {Object} filePath 帳票オブジェクト
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceFileForAgentLink(filePath, customerCode) {
    return apiClient.get("downloadevidenceforentryagent", { params: {
      path: filePath,
      customerCode,
    }});
  }

  /**
   * 帳票ファイルアップロード処理
   * @param {File} file 帳票ファイル
   * @param {Array} groupIds アップロード先グループ
   * @param {String} mapCode マップコード設定
   * @param {Boolean} omitsOcr OCR実行を省略するか否か（OCR未契約時は常にFalse）
   * @param {Number} uploadMode アップロードモード（0:自動、1:電子取引、2:スキャナ保存）
   * @param {Object} evidenceObject 帳票データオブジェクト
   * @param {String} reason 差替え事由
   * @returns {Object} レスポンスオブジェクト
   */
  uploadEvidenceFile(file, groupIds, mapCode, omitsOcr, uploadMode, evidenceObject = null, reason) {
    const params = {
      fileName: file.name,
      groupIds: groupIds.toString(),
      mapCode,
      omitsOcr,
      uploadMode
    }

    if (evidenceObject !== null) {
      // 差替えの場合
      params.registrationDate = evidenceObject.registrationDate;
      params.registrationNo = evidenceObject.registrationNo;
      params.historyNo = evidenceObject.historyNo;
      params.reason = reason;
    }

    return apiClient.post("uploadevidence", file, { params });
  }

  /**
   * 帳票タグマスター取得処理
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceTags(tenantCode) {
    return apiClient.get("listevidencetags", { params: { tenantCode } });
  }

  /**
   * 帳票タグマスター追加処理
   * @param {Object} evidenceTagObject evidenceTagオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  addEvidenceTag(evidenceTagObject, tenantCode) {
    return apiClient.post("addevidencetag", {
      ...evidenceTagObject,
      tenantCode,
    });
  }

  /**
   * 帳票タグマスター更新処理
   * @param {Object} evidenceTagObject 帳票データオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  updateEvidenceTag(evidenceTagObject, tenantCode) {
    return apiClient.post("updateevidencetag", {
      ...evidenceTagObject,
      tenantCode,
    });
  }

  /**
   * 帳票タグマスター削除処理
   * @param {String} id ID
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteEvidenceTag(id, tenantCode) {
    return apiClient.post("deleteevidencetag", {
      id,
      tenantCode,
    });
  }

  /**
   * 帳票定義マスター取得処理
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceSettings(customerCode = null) {
    return apiClient.get("listevidencesettings", { params: {
      customerCode,
    }});
  }

  /**
   * 帳票定義マスター追加処理
   * @param {Object} evidenceSettingsObject evidenceSettingsオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  addEvidenceSettings(evidenceSettingsObject, tenantCode) {
    return apiClient.post("addevidencesettings", {
      ...evidenceSettingsObject,
      tenantCode,
    });
  }

  /**
   * 帳票定義マスター更新処理
   * @param {Object} evidenceSettingsObject 帳票データオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  updateEvidenceSettings(evidenceSettingsObject, tenantCode) {
    return apiClient.post("updateevidencesettings", {
      ...evidenceSettingsObject,
      tenantCode,
    });
  }

  /**
   * 帳票定義マスター削除処理
   * @param {String} id ID
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteEvidenceSettings(id, tenantCode) {
    return apiClient.post("deleteevidencesettings", {
      id,
      tenantCode,
    });
  }

  /**
   * OCRテスト実行処理
   * @param {String} customerCode テナントコード
   * @param {File} file ファイルオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  testExcuteOcr(customerCode, file) {
    return apiClient.post("testexecuteocr?customerCode=" + customerCode, file);
  }

  /**
   * 帳票認識リソース情報取得処理
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  getFormRecogInfo(customerCode) {
    return apiClient.get("getformrecoginfo", { params: {
      customerCode
    }});
  }

  /**
   * 帳票認識リソース情報追加処理
   * @param {Object} formRecogInfoObject 帳票認識リソース情報オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  addFormRecogInfo(formRecogInfoObject) {
    return apiClient.post("addformrecoginfo", formRecogInfoObject);
  }

  /**
   * 帳票認識リソース情報更新処理
   * @param {Object} formRecogInfoObject 帳票認識リソース情報オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateFormRecogInfo(formRecogInfoObject) {
    return apiClient.post("updateformrecoginfo", formRecogInfoObject);
  }

  /**
   * 帳票認識リソース情報削除処理
   * @param {String} customerCode 得意先コード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteFormRecogInfo(customerCode) {
    return apiClient.post("deleteformrecoginfo", { customerCode });
  }

  /**
   * アクティビティ履歴取得処理
   * @param {Number} count 取得件数
   * @param {String} timeStamp 取得済みの最終行のタイムスタンプ
   * @param {String} registrationDate 帳票登録日
   * @param {Number} registrationNo 帳票登録番号
   * @param {Number} historyNo 帳票履歴番号
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  getActivityHistory(count, timeStamp, registrationDate, registrationNo, historyNo, customerCode = null) {
    return apiClient.get("getactivityhistory", { params: {
      count,
      timeStamp,
      registrationDate,
      registrationNo,
      historyNo,
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    }});
  }

  /**
   * 帳票ファイル管理帳票重複データ取得処理
   * @param {String} hash ハッシュ値
   * @param {String} path ファイルパス
   * @param {String} customerCode 得意先コード（オプション：代行入力の場合のみセット）
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceControlDuplicate(hash, path, customerCode = null) {
    return apiClient.get("getevidencecontrolduplicate", { params: {
      hash,
      path,
      customerCode,
      callerPage: customerCode == null ? this.page.evidence : this.page.agent,
    }});
  }

  /**
   * スキャナ要件警告解除処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @param {String} action 警告解除区分
   * @returns {Promise} promiseオブジェクト
   */
  ignoreScanWarning(registrationDate, registrationNo, historyNo, action) {
    return apiClient.post("ignorescanwarning", {
      registrationDate,
      registrationNo,
      historyNo,
      action,
    })
  }

  /**
   * 電帳法区分更新処理
   * @param {String} registrationDate 登録年月日
   * @param {Number} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @param {String} isScannedPdf 電帳法区分
   * @returns {Promise} promiseオブジェクト
   */
  updateDenchohoType(registrationDate, registrationNo, historyNo, isScannedPdf) {
    return apiClient.post("updatedenchohotype", {
      registrationDate,
      registrationNo,
      historyNo,
      isScannedPdf,
    })
  }

  /**
   * テナント切り替え処理（通知のみ）
   * @param {String} tenantCode 登録年月日
   * @returns {Promise} promiseオブジェクト
   */
  changeOperationTenant(tenantCode) {
    return apiClient.post("changeoperationtenant", {
      tenantCode
    })
  }

  /**
   * 代理入力帳票一覧取得処理
   * @param {String} dateOfIssueFrom 開始取引日
   * @param {String} dateOfIssueTo 終了取引日
   * @param {String} totalAmountMin 下限取引金額
   * @param {String} totalAmountMax 上限取引金額
   * @param {String} partnerName 取引先名称
   * @param {String} name1 得意先名称
   * @param {String} inputUserName 入力ユーザー名
   * @param {String} firstApprovalUserName 承認ユーザー名
   * @param {Number} count 取得件数
   * @param {String} ocrState OCRステータス
   * @param {String} confirmation 確認状況
   * @param {Boolean} pendingOnly 保留中フラグ
   * @param {Boolean} excludesDeleted 削除済除外フラグ
   * @returns {Object} レスポンスオブジェクト
   */
  getEvidenceDataForEntryAgent(dateOfIssueFrom, dateOfIssueTo, totalAmountMin,
                              totalAmountMax, partnerName, name1, inputUserName, firstApprovalUserName, count, ocrState, confirmation,
                              approvalStates, entryAgentState, pendingOnly, isConfirmMode, lastKey, excludesDeleted) {
    return apiClient.get("getEvidenceDataForEntryAgent", { params: {
      dateOfIssueFrom,
      dateOfIssueTo,
      totalAmountMin,
      totalAmountMax,
      partnerName,
      name1,
      inputUserName,
      firstApprovalUserName,
      count,
      ocrState,
      confirmation,
      approvalStates: approvalStates.toString(),
      entryAgentState,
      pendingOnly,
      isConfirmMode,
      lastKey,
      excludesDeleted,
    }});
  }

  /**
   * 保留状況取得処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {String} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @returns {Object} レスポンスオブジェクト
   */
  getPengindInfo(tenantCode, registrationDate, registrationNo, historyNo) {
    return apiClient.get("getpendinginfo", { params: {
      tenantCode,
      registrationDate,
      registrationNo,
      historyNo,
    }});
  }

  /**
   * 保留コメント投稿処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 登録日
   * @param {String} registrationNo 登録番号
   * @param {Number} historyNo 履歴番号
   * @param {Boolean} isPending 保留状況
   * @param {String} comment コメント
   * @returns {Object} レスポンスオブジェクト
   */
  postPengindComment(tenantCode, registrationDate, registrationNo, historyNo, isPending, comment) {
    return apiClient.post("postpendingcomment", {
      tenantCode,
      registrationDate,
      registrationNo,
      historyNo,
      isPending,
      comment,
    });
  }

  /**
   * チャット開始認証情報取得処理
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 帳票登録日
   * @param {Number} registrationNo 帳票登録番号
   * @returns {Object} レスポンスオブジェクト
   */
  startChat(tenantCode, registrationDate, registrationNo) {
    return apiClient.post("startchat", { tenantCode, registrationDate, registrationNo });
  }

  /**
   * チャットメッセージ投稿処理
   * @description バックエンド経由
   * @param {String} tenantCode テナントコード
   * @param {String} registrationDate 帳票登録日
   * @param {Number} registrationNo 帳票登録番号
   * @param {String} message メッセージ本文
   * @param {String} senderType 送信者区分
   * @returns {Object} レスポンスオブジェクト
   */
  postChatMessage(tenantCode, registrationDate, registrationNo, message, senderType) {
    return apiClient.post("postchatmessage", {
      tenantCode,
      registrationDate,
      registrationNo,
      message,
      senderType,
    });
  }

  /**
   * 未読チャット一覧取得処理
   * @description スレッドIDを指定しない場合は全件を取得します
   * @param {Number} callerPage 呼び出し画面区分
   * @param {String} threadId スレッドID（オプション）
   * @returns {Object} レスポンスオブジェクト
   */
  getUnreadChats(callerPage, threadId = null) {
    return apiClient.get("getunreadchats", { params: {
      callerPage,
      threadId,
    }});
  }

  /**
   * 未読状況更新処理
   * @param {*} tenantCode テナントコード
   * @param {*} threadId スレッドID
   * @param {*} sequence 最終既読シーケンス番号
   * @returns {Object} レスポンスオブジェクト
   */
  updateChatReadState(tenantCode, threadId, sequence) {
    return apiClient.post("updatechatreadstate", {
      tenantCode,
      threadId,
      sequence,
    });
  }

  /**
   * 代行入力サポート情報URL取得処理
   * @param {String} tenantCode 代行入力先テナントコード
   * @param {String} partnerCode 取引先コード
   * @param {Number} docId 帳票ID
   * @returns {Object} レスポンスオブジェクト
   */
   getEntryAgentSupportUrl(tenantCode, partnerCode, docId) {
    return apiClient.get("getentryagentsupporturl", { params: {
      tenantCode,
      partnerCode,
      docId,
    }});
  }

  /**
   * 代行入力サポートExcelシート情報取得処理
   * @param {String} tenantCode 代行入力先テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  getEntryAgentExcelSheet(tenantCode) {
    return apiClient.get("getEntryAgentExcelSheet", { params: {
      tenantCode,
    }});
  }

  /**
   * 代行入力サポートExcelシート情報追加処理
   * @param {Object} entryAgentExcelSheetObject entryAgentExcelSheetオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  addEntryAgentExcelSheet(entryAgentExcelSheetObject) {
    return apiClient.post("addentryagentexcelsheet", {
      ...entryAgentExcelSheetObject,
    });
  }

  /**
   * 代行入力サポートExcelシート情報更新処理
   * @param {Object} entryAgentExcelSheetObject entryAgentExcelSheetObjectオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateEntryAgentExcelSheet(entryAgentExcelSheetObject) {
    return apiClient.post("updateentryagentexcelsheet", {
      ...entryAgentExcelSheetObject,
    });
  }

  /**
   * 代行入力サポートExcelシート情報削除処理
   * @param {String} tenantCode 代行入力先テナントコード
   * @param {String} partnerCode 取引先コード
   * @param {Number} docId 帳票ID
   * @param {Number} formatNo フォーマットNo
   * @returns {Object} レスポンスオブジェクト
   */
  deleteEntryAgentExcelSheet(tenantCode, partnerCode, docId, formatNo) {
    return apiClient.post("deleteentryagentexcelsheet", {
      tenantCode,
      partnerCode,
      docId,
      formatNo,
    });
  }

  /**
   * 代行入力サポートExcelブック情報取得処理
   * @param {String} tenantCode 代行入力先テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  getEntryAgentExcelBook(tenantCode) {
    return apiClient.get("getentryAgentExcelBook", { params: {
      tenantCode,
    }});
  }

  /**
   * 代行入力サポートExcelブック情報追加処理
   * @param {Object} entryAgentExcelBookObject entryAgentExcelBookオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  addEntryAgentExcelBook(entryAgentExcelBookObject) {
    return apiClient.post("addentryAgentExcelBook", {
      ...entryAgentExcelBookObject,
    });
  }

  /**
   * 代行入力サポートExcelブック情報更新処理
   * @param {Object} entryAgentExcelBookObject entryAgentExcelBookオブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateEntryAgentExcelBook(entryAgentExcelBookObject) {
    return apiClient.post("updateentryAgentExcelBook", {
      ...entryAgentExcelBookObject,
    });
  }

  /**
   * 代行入力サポートExcelブック情報削除処理
   * @param {String} tenantCode 代行入力先テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteEntryAgentExcelBook(tenantCode) {
    return apiClient.post("deleteentryAgentExcelBook", {
      tenantCode,
    });
  }

  /**
   * 課金情報テーブル作成処理
   * @param {String} targetYearMonth 課金対象年月
   * @param {String} tenantCode テナントコード
   * @param {Boolean} isEnforce 仮確定データ強制作成時はTrue、速報作成時はFalse
   * @returns {Object} レスポンスオブジェクト
   */
  createBillingInfo(targetYearMonth, tenantCode, isEnforce = false) {
    const payload = {};
    if (isEnforce) {
      payload["isClosing"] = true;
    } else {
      payload["targetYearMonth"] = targetYearMonth;
      payload["tenantCode"] = tenantCode;
    }
    return apiClient.post("createbillinginfo", payload);
  }

  /**
   * 課金情報取得処理
   * @param {String} targetYearMonth 課金対象年月
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  getBillingInfo(targetYearMonth, tenantCode) {
    return apiClient.get("getbillinginfo", { params: {
      targetYearMonth,
      tenantCode
    }});
  }

  /**
   * 課金情報更新処理
   * @param {Object} billingInfoObject 課金情報オブジェクト
   * @returns {Object} レスポンスオブジェクト
   */
  updateBillingInfo(billingInfoObject) {
    return apiClient.post("updatebillinginfo", billingInfoObject);
  }

  /**
   * 請求金額確定取消処理
   * @param {String} action 締処理区分（一括締処理=1, 個別締処理=2, 個別締取消処理=8, 一括締取消処理=9）
   * @param {String} targetYearMonth 課金対象年月
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  closeBillingInfo(action, targetYearMonth, tenantCode) {
    return apiClient.post("closebillinginfo", { action, targetYearMonth, tenantCode });
  }

  /**
   * グループマスター取得処理
   * @param {Number} filterCondition フィルター条件
   * @param {String} tenantCode テナントコード
   * @param {Boolean} excludesDeleted 削除レコード除外有無（デフォルト：true）
   * @returns {Object} レスポンスオブジェクト
   */
  getGroup(filterCondition, tenantCode, excludesDeleted) {
    return apiClient.get("getgroup", { params: {
      filterCondition,
      tenantCode,
      excludesDeleted
    }});
  }

  /**
   * グループマスター追加処理
   * @param {Object} groupObject グループオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  addGroup(groupObject, tenantCode = null) {
    return apiClient.post("addgroup", {
      ...groupObject,
      tenantCode,
    });
  }

  /**
   * グループマスター更新処理
   * @param {Object} groupObject グループオブジェクト
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  updateGroup(groupObject, tenantCode) {
    return apiClient.post("updategroup", {
      ...groupObject,
      tenantCode,
    });
  }

  /**
   * グループマスター削除処理
   * @param {String} id ID
   * @param {String} tenantCode テナントコード
   * @returns {Object} レスポンスオブジェクト
   */
  deleteGroup(id, tenantCode) {
    return apiClient.post("deletegroup", {
      id,
      tenantCode,
    });
}

  /**
   * グループ所属ユーザー取得処理
   * @param {String} tenantCode テナントコード
   * @param {String} accountId アカウントID
   * @param {Number} groupId グループID
   * @param {Boolean} excludesDeleted 削除レコード除外有無
   * @returns {Object} レスポンスオブジェクト
   */
  getGroupUser(tenantCode, accountId, groupId, excludesDeleted) {
    return apiClient.get("getgroupuser", { params: {
      tenantCode,
      accountId,
      groupId,
      excludesDeleted,
    }});
  }

  /**
   * グループ所属ユーザー追加処理
   * @param {String} tenantCode テナントコード
   * @param {Number} groupId グループID
   * @param {String} accountId アカウントID
   * @returns {Object} レスポンスオブジェクト
   */
  addGroupUser(tenantCode, groupId, accountId) {
    return apiClient.post("addgroupuser", {
      tenantCode,
      groupId,
      accountId,
    });
  }

  /**
   * グループ所属ユーザー除外処理
   * @param {String} tenantCode テナントコード
   * @param {Number} groupId グループID
   * @param {String} accountId アカウントID
   * @returns {Object} レスポンスオブジェクト
   */
  deleteGroupUser(tenantCode, groupId, accountId) {
    return apiClient.post("deletegroupuser", {
      tenantCode,
      groupId,
      accountId,
    });
  }

}

export default new BackendApi();