import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate"
import backend from "@/services/backendApi";
import utilities from "@/services/utilities";
import cache from "@/store/cache";

export default createStore({
  modules: {
    cache,
  },
  plugins: [createPersistedState({
    key: 'app',
    storage: window.sessionStorage, // セッション方式で保存
    paths: ['apiKey'],              // APIキーのみ保存
  })],
  state: {
    user: {},                       // サインインしているユーザオブジェクト
    assignedCustomers: [],          // 閲覧可能得意先一覧
    isHQ: false,                    // 本社ロケーションからのアクセスかどうか
    ownerTenant: "",                // オーナーテナントコード
    systemDate: "",                 // サーバーマシン日付
    permissions: {                  // ユーザーの権限
      /** サービス利用者 */
      isServiceUser: false,
      /** 請求書閲覧者 */
      isInvoiceViewer: false,
      /** 帳票保管サービス利用者 */
      isEvidenceServiceUser: false,
      /** 請求書発行サービス利用者 */
      isInvoiceDeliveryServiceUser: false,
      /** 請求書データ提供者 */
      isDataProvider: false,
      /** サービス運営者 */
      isServiceManager: false,
      /** データ承認者 */
      isDataApprover: false,
      /** データ承認責任者 */
      isDataApprovalChief: false,
      /** データ編集者 */
      isDataEditor: false,
      /** マスター管理者 */
      isMasterMaintainer: false,
      /** マスター閲覧者 */
      isMasterViewer: false,
      /** ユーザー管理者 */
      isUserAdministrator: false,
      /** テナントユーザー管理者 */
      isTenantUserMaintainer: false,
      /** テナントマスター管理者 */
      isTenantMasterMaintainer: false,

      /** 帳票データ提供者（アップロード者） */
      isEvidenceDataProvider: false,
      /** 帳票閲覧者 */
      isEvidenceViewer: false,
      /** 帳票データ自己入力者 */
      isEvidenceSelfEnterer: false,
      /** 帳票データ代理入力者 */
      isEvidenceProxyEnterer: false,
      /** 帳票データ一次承認者 */
      isEvidenceFirstApprover: false,
      /** 帳票データ二次承認者 */
      isEvidenceSecondApprover: false,
      /** 帳票データ編集者 */
      isEvidenceDataEditor: false,
      /** 帳票データ編集責任者 */
      isEvidenceDataEditChief: false,

      /** 発行機能請求書閲覧者（サービス連携1） */
      isInvoiceDeliveryViewer1: false,
      /** 発行機能請求書承認者（サービス連携1） */
      isInvoiceDeliveryApprover1: false,
      /** 発行機能請求書発行者（サービス連携1） */
      isInvoiceDeliveryIssuer1: false,
      /** 発行機能請求書発行取消者（サービス連携1） */
      isInvoiceDeliveryCanceller1: false,


      /** 課金情報管理者 */
      isBillingManager: false,
      /** 課金情報閲覧者 */
      isBillingViewer: false,
      /** OCR設定管理者 */
      isOcrConfigManager: false,
      /** 代行入力者 */
      isEntryAgent: false,
      /** 代行入力承認者 */
      isEntryAgentApprover: false,
      /** 代行入力管理者 */
      isEntryAgentAdministrator: false,
      /** サポートオペレーター */
      isSupportOperator: false,
      /** サポート責任者 */
      isSupportAdministrator: false,
      /** サービス管理者 */
      isServiceAdministrator: false,

      /** グローバル管理者 */
      isAdministrator: false,

      /** 請求書ダッシュボードアクセス権限 */
      canAccessReceiveInvoices: false,
      /** 管理者メニューアクセス権限 */
      canAccessAdminMenu: false,
      /** お知らせ配信データ保守権限(照会) */
      canManageNoticeData: false,
      /** お知らせ配信データ登録権限 */
      canCreateNoticeData: false,
      /** お知らせ配信データ承認権限 */
      canApproveNoticeData: false,
      /** マスター閲覧権限 */
      canViewingMaster: false,
      /** ユーザーマスター保守権限 */
      canManageUsers: false,
      /** 得意先マスター保守権限 */
      canManageCustomers: false,
      /** グループマスター保守権限 */
      canManageGroups: false,
      /** 帳票保管取引先マスター保守権限 */
      canManagePartners: false,
      /** 帳票保管タグマスター保守権限 */
      canManageEvidenceTag: false,
      /** 帳票保管帳票定義マスター保守権限 */
      canManageEvidenceSettings: false,
      /** 帳票種類変換マスター保守権限 */
      canManageFormTypeConversions: false,
      /** QandAマスター保守権限 */
      canManageQandA: false,
      /** 代行入力サポート情報マスター保守権限 */
      canManageEntryAgentSupport: false,
      /** 自請求書データアクセス権限 */
      canReadReceiveInvoices: false,
      /** 全請求書データアクセス権限 */
      canReadDeliveryInvoices: false,
      /** 請求書承認作業権限 */
      canApproveInvoices: false,
      /** 請求書発行作業権限 */
      canIssueInvoices: false,
      /** 汎用帳票保守権限 **/
      canManageReports: false,
      /** 課金情報閲覧権限 */
      canViewingBillingInfo: false,
      /** 課金情報保守権限 */
      canManageBillingInfo: false,
      /** システム設定保守権限 */
      canManageSystemConfig: false,
      /** 全テナント権限管理権限 */
      canManageFullRoleAccessControl: false,
      /** 代行入力権限管理権限 */
      canManageEntryAgentPermission: false,
      /** 顧客データアクセス管理権限 */
      canSupportPermission: false,
      /** 自テナント権限管理権限 */
      canManageRoleAccessControl: false,
      /** データ編集権限 */
      canDataEdit: false,

      /** 保管ダッシュボードアクセス権限 */
      canAccessEvidences: false,
      /** 帳票データ参照権限 */
      canReadEvidence: false,
      /* 顧客帳票データ参照権限 */
      canReadCustomerEvidence: false,
      /** 帳票データアップロード権限 */
      canUploadEvidence: false,
      /** 帳票データ入力権限 */
      canEntryEvidence: false,
      /** 帳票データ編集権限 */
      canEditEvidence: false,
      /** 帳票データ承認権限 */
      canApproveEvidence: false,
      /** 帳票データ一次承認権限 */
      canFirstApproveEvidence: false,
      /** 帳票データ二次承認権限 */
      canSecondApproveEvidence: false,
      /** 帳票ロック強制解除権限 */
      canForciblyUnlockEvidence: false,
      /** 帳票保留権限 */
      canPendEvidence: false,
      /** 帳票認識リソース情報管理権限 */
      canManageFormRecogInfo: false,
      /** 帳票承認設定保守権限 */
      canManageEvidenceApprovalOption: false,
      /** OCRテスト実行権限 */
      canTestExecuteOcr: false,

      /** 発行請求書一覧ダッシュボード表示権限 */
      canAccessDeliveryInvoices: false,
      /** サービス連携設定マスター保守権限 */
      canManageServiceSettings: false,
      /** 帳票発行設定保守権限 */
      canManageDeliverySettings: false,
      /** 帳票発行設定・サインアップ承認データ保守権限 */
      canManageSignupApprovalData: false,

      /** 操作対象テナント変更権限 */
      canChangeTargetTenant: false,

      /** 代行入力一覧アクセス権限 */
      canAccessEntryAgentMenu: false,

      /** 自帳票の閲覧のみに制限されているかどうか */
      limitedOwnEvidence: false,
    },
    csrfToken: '',                  // CSRFトークン
    apiKey: '',                     // APIキー(Functionsキー)
    fetched: false,                 // ユーザー情報読込状態フラグ
    notifications: [],              // 通知データ配列
  },
  mutations: {
    /** トークン・APIキー保存 */
    saveAuthInfo(state, payload) {
      state.csrfToken = payload.csrfToken;
      state.apiKey = payload.apiKey;
    },

    /** サインインユーザオブジェクト保存 */
    saveOwnInfo(state, userObject) {
      if ( !state.apiKey ) {
        // 前回サインアウト実施していない場合のため何もしない
        return;
      }

      state.user = {};
      state.permissions = {};

      // ユーザーオブジェクトをセット
      state.user = Object.assign(state.user, userObject);

      // ユーザーの権限をチェック
      try {
        // サービス利用者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(1)) {
          state.permissions.isServiceUser = true;
        }

        // 請求書データ提供者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(2)) {
          state.permissions.isDataProvider = true;
        }

        // サービス運営者（発行請求書閲覧者）
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(3)) {
          state.permissions.isServiceManager = true;
        }

        // マスター管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(4)) {
          state.permissions.isMasterMaintainer = true;
        }

        // マスター閲覧者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(5)) {
          state.permissions.isMasterViewer = true;
        }

        // ユーザー管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(6)) {
          state.permissions.isUserAdministrator = true;
        }

        // データ承認者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(9)) {
          state.permissions.isDataApprover = true;
        }

        // データ承認責任者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(10)) {
          state.permissions.isDataApprovalChief = true;
        }

        // データ編集者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(11)) {
          state.permissions.isDataEditor = true;
        }

        // -------------- //
        // テナントユーザー管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(53)) {
          state.permissions.isTenantUserMaintainer = true;
        }

        // テナントマスター管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(54)) {
          state.permissions.isTenantMasterMaintainer = true;
        }
        // -------------- //

        // 請求書閲覧者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(20)
            || userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(21)) {
          state.permissions.isInvoiceViewer = true;
        } else {
          for (const key in Object.keys(userObject.roles)) {
            if (userObject.roles[key]?.includes(21)) {
              state.permissions.isInvoiceViewer = true;
            }
          }
        }

        // -------------- //

        // 保管サービス利用者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(50)) {
          state.permissions.isEvidenceServiceUser = true;
        }

        // 帳票データ閲覧者（自テナント全件）
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(51)) {
          state.permissions.isEvidenceViewer = true;
        }

        // 帳票アップロード者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(52)) {
          state.permissions.isEvidenceDataProvider = true;
        }

        // 帳票データ自己入力者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(56)) {
          state.permissions.isEvidenceSelfEnterer = true;
        }

        // 帳票データ代理入力者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(57)) {
          state.permissions.isEvidenceProxyEnterer = true;
        }

        // 帳票データ一次承認者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(58)) {
          state.permissions.isEvidenceFirstApprover = true;
        }

        // 帳票データ二次承認者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(59)) {
          state.permissions.isEvidenceSecondApprover = true;
        }

        // 帳票データ編集者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(61)) {
          state.permissions.isEvidenceDataEditor = true;
        }

        // 帳票データ編集責任者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(62)) {
          state.permissions.isEvidenceDataEditChief = true;
        }
        
        // -------------- //

        // 請求書発行サービス利用者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(700)) {
          state.permissions.isInvoiceDeliveryServiceUser = true;
        }

        // 発行機能請求書閲覧者（サービス連携1）
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(711)) {
          state.permissions.isInvoiceDeliveryViewer1 = true;
        }

        // 発行機能請求書承認者（サービス連携1）
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(712)) {
          state.permissions.isInvoiceDeliveryApprover1 = true;
        }

        // 発行機能請求書発行者（サービス連携1）
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(713)) {
          state.permissions.isInvoiceDeliveryIssuer1 = true;
        }

        // 発行機能請求書発行取消者（サービス連携1）
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(714)) {
          state.permissions.isInvoiceDeliveryCanceller1 = true;
        }


        // -------------- //

        // 課金情報管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(80)) {
          state.permissions.isBillingManager = true;
        }

        // 課金情報閲覧者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(81)) {
          state.permissions.isBillingViewer = true;
        }

        // OCR設定管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(82)) {
          state.permissions.isOcrConfigManager = true;
        }

        // 代行入力者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(85)) {
          state.permissions.isEntryAgent = true;
        }

        // 代行入力承認者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(86)) {
          state.permissions.isEntryAgentApprover = true;
        }

        // 代行入力管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(87)) {
          state.permissions.isEntryAgentAdministrator = true;
        }

        // サポートオペレーター
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(88)) {
          state.permissions.isSupportOperator = true;
        }

        // サポート責任者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(89)) {
          state.permissions.isSupportAdministrator = true;
        }

        // サービス管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(90)) {
          state.permissions.isServiceAdministrator = true;
        }

        // グローバル管理者
        if (userObject.roles[`${userObject.parentTenantCode}/${userObject.tenantCode}`].includes(99)) {
          state.permissions.isAdministrator = true;
        }

        // -------------- //

        // 請求書ダッシュボードアクセス権限
        if (state.permissions.isServiceUser
          && (state.permissions.isInvoiceViewer)
          || state.permissions.isServiceManager
          || state.permissions.isAdministrator) {
          state.permissions.canAccessReceiveInvoices = true;
        }

        // 管理者メニューアクセス権限
        if (state.permissions.isDataProvider
            || state.permissions.isMasterMaintainer
            || state.permissions.isMasterViewer
            || state.permissions.isUserAdministrator
            || state.permissions.isDataApprovalChief
            || state.permissions.isBillingManager
            || state.permissions.isBillingViewer
            || state.permissions.isEntryAgent
            || state.permissions.isEntryAgentApprover
            || state.permissions.isEntryAgentAdministrator
            || state.permissions.isServiceAdministrator
            || state.permissions.isTenantMasterMaintainer
            || state.permissions.isDataEditor
            || state.permissions.isTenantUserMaintainer
            || state.permissions.isSupportOperator
            || state.permissions.isAdministrator) {
          state.permissions.canAccessAdminMenu = true;
        }

        // お知らせ配信データ保守権限(照会)
        if (state.permissions.isDataProvider
            || state.permissions.isDataApprovalChief
            || state.permissions.isDataEditor
            || state.permissions.isAdministrator) {
          state.permissions.canManageNoticeData = true;
        }

        // お知らせ配信データ登録(更新)権限
        if (state.permissions.isDataProvider
            || state.permissions.isDataEditor
            || state.permissions.isAdministrator) {
          state.permissions.canCreateNoticeData = true;
        }

        // お知らせ配信データ承認権限
        if (state.permissions.isDataApprovalChief
            || state.permissions.isDataEditor
            || state.permissions.isAdministrator) {
          state.permissions.canApproveNoticeData = true;
        }

        // マスター閲覧権限(得意先とQ&Aのみ)
        if (state.permissions.isServiceManager
            || state.permissions.isMasterViewer
            || state.permissions.isMasterMaintainer
            || state.permissions.isAdministrator) {
          state.permissions.canViewingMaster = true;
        }

        // ユーザーマスター保守権限
        if (state.permissions.isUserAdministrator
            || state.permissions.isTenantUserMaintainer
            || state.permissions.isAdministrator) {
          state.permissions.canManageUsers = true;
        }

        // 得意先マスター保守権限
        if (state.permissions.isMasterMaintainer
            || state.permissions.isTenantMasterMaintainer
            || state.permissions.isAdministrator) {
          state.permissions.canManageCustomers = true;
        }

        // グループマスター保守権限
        if (state.permissions.isUserAdministrator
            || state.permissions.isTenantUserMaintainer
            || state.permissions.isAdministrator) {
          state.permissions.canManageGroups = true;
        }

        // 帳票保管取引先マスター保守権限
        if (state.permissions.isTenantMasterMaintainer
            || state.permissions.isEntryAgent
            || state.permissions.isEntryAgentApprover
            || state.permissions.isSupportOperator
            || state.permissions.isAdministrator) {
            state.permissions.canManagePartners = true;
        }

        // 帳票保管タグマスター保守権限
        if (state.permissions.isTenantMasterMaintainer
            || state.permissions.isEntryAgent
            || state.permissions.isEntryAgentApprover
            || state.permissions.isSupportOperator
            || state.permissions.isAdministrator) {
            state.permissions.canManageEvidenceTag = true;
        }

        // 帳票保管帳票定義マスター保守権限
        if (state.permissions.isTenantMasterMaintainer
            || state.permissions.isEntryAgent
            || state.permissions.isEntryAgentApprover
            || state.permissions.isSupportOperator
            || state.permissions.isAdministrator) {
            state.permissions.canManageEvidenceSettings = true;
        }

        // 帳票種類変換マスター保守権限
        if (state.permissions.isTenantMasterMaintainer
            || state.permissions.isEntryAgent
            || state.permissions.isEntryAgentApprover
            || state.permissions.isSupportOperator
            || state.permissions.isAdministrator){
            state.permissions.canManageFormTypeConversions = true;
        }

        // サービス連携設定マスター保守権限
        if (state.permissions.isMasterMaintainer
          || state.permissions.isSupportOperator
          || state.permissions.isAdministrator){
          state.permissions.canManageServiceSettings = true;
        }

        // 帳票発行設定（タグ動作設定）保守権限
        if (state.permissions.isMasterMaintainer
          || state.permissions.isTenantMasterMaintainer
          || state.permissions.isAdministrator){
          state.permissions.canManageDeliverySettings = true;
        }

        // サインアップ承認データ保守権限
        if (state.permissions.isTenantUserMaintainer
          || state.permissions.isAdministrator){
          state.permissions.canManageSignupApprovalData = true;
        }

        // QandAマスター保守権限
        if (state.permissions.isMasterMaintainer
            || state.permissions.isAdministrator) {
          state.permissions.canManageQandA = true;
        }

        // 代行入力サポート情報マスター保守権限
        if (state.permissions.isEntryAgent
          || state.permissions.isEntryAgentApprover
          || state.permissions.isAdministrator) {
        state.permissions.canManageEntryAgentSupport = true;
      }

        // 受領請求書データアクセス権
        if (state.permissions.isInvoiceViewer ||
            state.permissions.isAdministrator) {
          state.permissions.canReadReceiveInvoices = true;
        }

        // 発行請求書データアクセス権限
        if (state.permissions.isServiceManager
            || state.permissions.isInvoiceDeliveryViewer1
            || state.permissions.isInvoiceDeliveryApprover1
            || state.permissions.isInvoiceDeliveryIssuer1
            || state.permissions.isInvoiceDeliveryCanceller1
            || state.permissions.isAdministrator) {
          state.permissions.canReadDeliveryInvoices = true;
        }

        // 請求書承認作業権限
        if (state.permissions.isServiceManager
            && state.permissions.isDataApprover
            || state.permissions.isInvoiceDeliveryApprover1
            || state.permissions.isAdministrator) {
          state.permissions.canApproveInvoices = true;
        }

        // 請求書発行作業権限
        if (state.permissions.isDataApprovalChief
            || state.permissions.isInvoiceDeliveryIssuer1
            || state.permissions.isAdministrator) {
          state.permissions.canIssueInvoices = true;
        }

        // 請求書発行取消作業権限
        if (state.permissions.isInvoiceDeliveryCanceller1
            || state.permissions.isAdministrator) {
          state.permissions.canCancelInvoices = true;
        }

        // 汎用帳票保守権限
        if (state.permissions.isDataProvider
            || state.permissions.isDataEditor
            || state.permissions.isAdministrator) {
          state.permissions.canManageReports = true;
        }

        // 課金情報閲覧権限
        if (state.permissions.isBillingViewer
            || state.permissions.isBillingManager
            || state.permissions.isAdministrator) {
          state.permissions.canViewingBillingInfo = true;
        }

        // 課金情報保守権限
        if (state.permissions.isBillingManager
            || state.permissions.isAdministrator) {
          state.permissions.canManageBillingInfo = true;
        }

        // システム設定保守権限
        if (state.permissions.isServiceAdministrator
            || state.permissions.isAdministrator) {
          state.permissions.canManageSystemConfig = true;
        }

        // 全テナントユーザー権限保守権限
        if (state.permissions.isUserAdministrator
            || state.permissions.isAdministrator) {
          state.permissions.canManageFullRoleAccessControl = true;
        }

        // 代行入力権限保守権限
        if (state.permissions.isEntryAgent
            || state.permissions.isEntryAgentApprover) {
          state.permissions.canManageEntryAgentPermission = true;
        }

        // 顧客データアクセス保守権限
        if (state.permissions.isSupportOperator
            || state.permissions.isAdministrator) {
          state.permissions.canSupportPermission = true;
          }

        // ユーザー権限保守権限
        if (state.permissions.isUserAdministrator
            || state.permissions.isTenantUserMaintainer
            || state.permissions.isAdministrator) {
          state.permissions.canManageRoleAccessControl = true;
        }

        // データ編集権限
        if (state.permissions.isDataEditor
            || state.permissions.isAdministrator) {
          state.permissions.canDataEdit = true;
        }

        // 帳票認識リソース情報(OCR設定)管理権限
        if (state.permissions.isOcrConfigManager
             || state.permissions.isAdministrator) {
          state.permissions.canManageFormRecogInfo = true;
        }

        // 保管ダッシュボードアクセス権限
        if (state.permissions.isEvidenceServiceUser
          || state.permissions.isSupportOperator
          || state.permissions.isAdministrator) {
          state.permissions.canAccessEvidences = true;
        }

        // 帳票データ参照権限
        if (state.permissions.isEvidenceServiceUser
          && ( state.permissions.isEvidenceViewer
            || state.permissions.isEvidenceDataProvider
            || state.permissions.isEvidenceSelfEnterer
            || state.permissions.isEvidenceProxyEnterer
            || state.permissions.isEvidenceFirstApprover
            || state.permissions.isEvidenceSecondApprover
            || state.permissions.isEvidenceDataEditor
            || state.permissions.isEvidenceDataEditChief)
          || state.permissions.isAdministrator) {
          state.permissions.canReadEvidence = true;
        }

        // 顧客帳票データ参照権限
        if (state.permissions.isEntryAgent
          || state.permissions.isEntryAgentApprover
          || state.permissions.isSupportOperator
          || state.permissions.isAdministrator) {
          state.permissions.canReadCustomerEvidence = true;
        }

        // 帳票データアップロード権限
        if (state.permissions.isEvidenceServiceUser
            && ( state.permissions.isEvidenceDataProvider)
            || state.permissions.isAdministrator) {
          state.permissions.canUploadEvidence = true;
        }

        // 帳票データ入力権限
        // ※自己入力者の場合は帳票データアップロードユーザーIDとの比較も必要
        if (state.permissions.isEvidenceServiceUser
            && ( state.permissions.isEvidenceSelfEnterer
              || state.permissions.isEvidenceProxyEnterer)
            || state.permissions.isEntryAgent
            || state.permissions.isAdministrator) {
          state.permissions.canEntryEvidence = true;
        }

        // 帳票データ編集権限
        if (state.permissions.isEvidenceServiceUser
            && ( state.permissions.isEvidenceDataEditor
              || state.permissions.isEvidenceDataEditChief) // TODO: データ編集責任者を、確定データ削除者に改めた場合、データ編集者のみTrueにする
            || state.permissions.isAdministrator) {
          state.permissions.canEditEvidence = true;
        }

        // 帳票データ承認権限
        if (state.permissions.isEvidenceServiceUser
            && ( state.permissions.isEvidenceFirstApprover
              || state.permissions.isEvidenceSecondApprover)
            || state.permissions.isEntryAgentApprover
            || state.permissions.isAdministrator) {
          state.permissions.canApproveEvidence = true;
        }

        // 帳票データ一次承認権限
        if (state.permissions.isEvidenceServiceUser
            && ( state.permissions.isEvidenceFirstApprover)
            || state.permissions.isAdministrator) {
          state.permissions.canFirstApproveEvidence = true;
        }

        // 帳票データ二次承認権限
        if (state.permissions.isEvidenceServiceUser
            && ( state.permissions.isEvidenceSecondApprover)
            || state.permissions.isAdministrator) {
          state.permissions.canSecondApproveEvidence = true;
        }

        // 帳票ロック強制解除権限
        if (state.permissions.isEvidenceServiceUser
            && ( state.permissions.isEvidenceDataEditor
              || state.permissions.isEvidenceDataEditChief)
            || state.permissions.isEntryAgentApprover
            || state.permissions.isEntryAgentAdministrator
            || state.permissions.isAdministrator) {
          state.permissions.canForciblyUnlockEvidence = true;
        }

        // 帳票保留権限
        if ( state.permissions.isEntryAgent
          || state.permissions.isEntryAgentApprover
          || state.permissions.isAdministrator) {
          state.permissions.canPendEvidence = true;
        }

        // 帳票承認設定保守権限
        if (state.permissions.isEvidenceServiceUser
          && ( state.permissions.isTenantMasterMaintainer)
          || state.permissions.isAdministrator) {
          state.permissions.canManageEvidenceApprovalOption = true;
        }

        // OCRテスト実行権限
        if (state.permissions.isTenantMasterMaintainer ||
            state.permissions.isOcrConfigManager ||
            state.permissions.isAdministrator) {
          state.permissions.canTestExecuteOcr = true;
        }

        // 操作対象テナント変更権限
        if (state.permissions.isEntryAgent ||
            state.permissions.isEntryAgentApprover ||
            state.permissions.isSupportOperator ||
            state.permissions.isAdministrator) {
          state.permissions.canChangeTargetTenant = true;
        }

        // 代行入力一覧アクセス権限
        if (state.permissions.isEntryAgent
          || state.permissions.isEntryAgentApprover
          || state.permissions.isAdministrator) {
          state.permissions.canAccessEntryAgentMenu = true;
        }

        // 自帳票の閲覧のみに制限されているかどうか
        // ※代理入力者や編集権限等と重複していないかのチェック
        if (state.permissions.isEvidenceDataProvider ||
            state.permissions.isEvidenceSelfEnterer){
          if (!state.permissions.isEvidenceProxyEnterer &&
              !state.permissions.isEvidenceDataEditor &&
              !state.permissions.isEvidenceDataEditChief) {
            // ※アップロード者・自己入力者だが、代理入力者・編集者・編集責任者ではない場合
            state.permissions.limitedOwnEvidence = true;
          }
        }


        // 発行ダッシュボードアクセス権限
        if (state.permissions.isInvoiceDeliveryServiceUser
          || state.permissions.isServiceManager
          || state.permissions.isSupportOperator
          || state.permissions.isAdministrator) {
          state.permissions.canAccessDeliveryInvoices = true;
        }

      } catch (error) {
        console.error("ユーザー権限チェックエラー", error);
        throw new Error("ユーザー権限の取得に失敗しました");
      }

      // ユーザー情報取得済みに変更
      state.fetched = true;
    },

    /** CSRFトークン保存 */
    saveCsrfToken(state, token) {
      state.csrfToken = token;
    },

    /** 保存済みユーザー情報更新 */
    updateStoredOwnInfo(state, values) {
      state.user.name = values.name;                            // 氏名（漢字）
      state.user.nameKana = values.nameKana;                    // 氏名（カナ）
      state.user.mailAddress = values.mailAddress;              // 連絡先メールアドレス
      state.user.startupPage = values.startupPage;              // スタートアップページ
      state.user.tags = values.tags;                            // ユーザーデフォルトタグ
      state.user.fileSizeThreshold = values.fileSizeThreshold;  // アップロード時警告閾値
    },

    /** 保存済みユーザー情報削除 */
    deleteStoredData(state) {
      state.user = {};
      state.assignedCustomers.splice(0, state.assignedCustomers.length);
      state.isHQ = false;
      state.ownerTenant = "";
      state.systemDate = "";
      state.permissions = {};
      state.csrfToken = "";
      state.apiKey = "";
      state.fetched = false;
    },

    /** 更新ボタンモード更新 */
    updateUpdateButtonMode(state, value) {
      // ユーザーオブジェクト内のupdateButtonModeの値を更新する
      state.user.updateButtonMode = value;
    },

    /** デフォルトアップロードモード更新 */
    updateDefaultUploadMode(state, value) {
      // ユーザーオブジェクト内のdefaultUploadModeの値を更新する
      state.user.defaultUploadMode = value;
    },

    /** 追加済閲覧先保存 */
    saveAssignedCustomers(state, values) {
      // 一旦クリア
      state.assignedCustomers.splice(0, state.assignedCustomers.length);

      // 保存
      state.assignedCustomers.push(...values);
    },

    /** 本社判定保存 */
    saveIsHQ(state, value) {
      state.isHQ = value;
    },

    /** 本社判定保存 */
    saveOwnerTenant(state, value) {
      state.ownerTenant = value;
    },

    /** 本社判定保存 */
    saveSystemDate(state, value) {
      state.systemDate = value;
    },

    /** 通知データ追加 */
    addNotification(state, noticeObject) {
      state.notifications.push(noticeObject);
    },

    /** 通知データ削除 */
    removeNotification(state, id) {
      const index = state.notifications.findIndex(item => item.id === id);
      if (index >= 0) {
        state.notifications.splice(index, 1);
      }
    },

  },
  actions: {
    /** 認証実行・保存 */
    async doSignin(context, payload) {
      return await backend.doAuthenticate(payload.accountId, payload.password, payload.recaptchaToken)
        .then(response => {
          // 認証情報保存
          context.commit('saveAuthInfo', response.data);
          return Promise.resolve(response);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    /** CSRFトークン保存 */
    async saveCsrfToken(context, token) {
      // 受け取ったCSRFトークンを単に保存
      context.commit('saveCsrfToken', token);
    },

    /** サインインユーザ情報保存 */
    async saveOwnInfo(context, userObject) {
      context.commit('saveOwnInfo', userObject);
    },

    /** サインインユーザ情報取得・保存 */
    async fetchOwnInfo(context) {
      // バックエンド呼び出し
      return await backend.getAttributes().then(response => {
        // ロケーションが本社かどうかを保存
        context.commit('saveIsHQ', response.data.isHQ);

        // オーナーテナントコードを保存する
        context.commit('saveOwnerTenant', response.data.ownerTenant);

        // サーバーマシン日付を保存
        context.commit('saveSystemDate', response.data.systemDate);

        // ユーザーオブジェクトを保存
        context.commit('saveOwnInfo', response.data.user);

        // 閲覧先を保存
        context.commit('saveAssignedCustomers', response.data.assignedCustomers);

        return Promise.resolve(response);
      }).catch(error => {
        return Promise.reject(error);
      });
    },

    /** 保存済みユーザー情報更新 */
    updateStoredOwnInfo(context, values) {
      context.commit('updateStoredOwnInfo', values);
    },

    /** 保存済みユーザー情報削除 */
    deleteStoredData(context) {
      context.commit('deleteStoredData');

      // キャッシュもクリアする
      cache.actions.clearAllCache(context);
    },

    /** 更新ボタンモード更新 */
    updateUpdateButtonMode(context, value) {
      context.commit('updateUpdateButtonMode', value);
    },

    /** デフォルトアップロードモード更新 */
    updateDefaultUploadMode(context, value) {
      context.commit('updateDefaultUploadMode', value);
    },

    /** 追加済閲覧先保存 */
    saveAssignedCustomers(context, values) {
      context.commit('saveAssignedCustomers', values);
    },

    /** 本社判定保存 */
    saveIsHQ(context, value) {
      context.commit('saveIsHQ', value);
    },

    /** サーバーマシン日付保存 */
    saveSystemDate(context, value) {
      context.commit('saveSystemDate', value);
    },

    /** 通知データ追加 */
    addNotification(context, values) {
      const noticeObject = {};
      noticeObject.id = values.id || utilities.getToday("notify-YYYYMMDDhhmmss");
      noticeObject.title = values.title || "";
      noticeObject.message = values.message || "";
      noticeObject.type = values.type || "primary";
      noticeObject.autoClose = values.autoClose || false;
      noticeObject.closeTime = values.closeTime || 5000;
      noticeObject.show = false;
      noticeObject.accepted = false;
      noticeObject.createdAt = utilities.getToday("YYYY/MM/DD hh:mm:ss");
      context.commit('addNotification', noticeObject);
    },

    /** 通知データ削除 */
    removeNotification(context, id) {
      context.commit('removeNotification', id);
    },

  },
  getters: {
    /** CSRFトークン取得 */
    getCsrfToken(context) {
      return context.csrfToken;
    },

    /** APIキー取得 */
    getApiKey(context) {
      return context.apiKey;
    },

    /** サインインユーザーオブジェクト取得 */
    getOwnInfo(context) {
      return context.user;
    },

    /** サインインユーザー権限オブジェクト取得 */
    getOwnPermissions(context) {
      return context.permissions;
    },

    /** 追加済閲覧先取得 */
    getAssignedCustomers(context) {
      return context.assignedCustomers;
    },

    /** 本社ロケーション判定取得 */
    getIsHQ(context) {
      return context.isHQ;
    },

    /** オーナーテナントコード取得 */
    getOwnerTenant(context) {
      return context.ownerTenant;
    },

    /** サーバーマシン日付取得 */
    getSystemDate(context) {
      return context.systemDate;
    },

    /** デフォルトテナントオブジェクト取得 */
    getDefaultTenant(context) {
      return context.assignedCustomers.find(i => i.tenantCode === context.user.parentTenantCode && i.customerCode === context.user.tenantCode);
    },

    /** 通知データ配列取得 */
    getNotifications(context) {
      return context.notifications;
    },
  }
})
